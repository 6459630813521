import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useEffect, useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi, patchApi } from "../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import VendorComponent from "./Components/VendorComponent"
import { navigate } from "gatsby"
import moment from "moment"
import "moment/locale/nb"
import TerminateApplication from "./Components/TerminateApplication"
import DispensationApplicationContent from "./Components/DispensationApplicationContent"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const DispensationOverviewContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [loadingDone, setLoadingDone] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [selected, setSelected] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [showAddButton, setShowAddButton] = useState(false)
  moment.locale("nb")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "DispensasjonIngress")

  const getMyVendor = useQuery(
    "myVendorData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
        if (data.length > 0) {
          setShowAddButton(true)
        }
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )
  const [activeVendor, setActiveVendor] = useState(getMyVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyVendor?.data?.[0]?.accountId)

  const getMyApplication = useQuery(
    "myApplicationData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Application/me?type=292460000&producer=" +
          activeAccount +
          "&vendor=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: getMyVendor.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLoadingDone(true)
        setRefresh(false)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  useEffect(() => {
    if (refresh === true) {
      getMyApplication.refetch()
      setLoadingDone(false)
    }
  }, [refresh])

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row mt-8 md:mt-0">
            <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
              Min side
            </Link>
            <p className=" font-semibold"> / Dispensasjon og permisjon / Dispensasjonssøknad</p>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Dispensasjonssøknad</h1>
            <p>{infoText?.value}</p>
          </div>
          <div className=" mb-8 flex flex-col">
            <div>
              <p className=" text-lg mb-4 font-semibold ">Dispensasjoner for</p>
            </div>
            <VendorComponent
              getMyVendor={getMyVendor}
              activeVendor={activeVendor}
              setActiveVendor={setActiveVendor}
              setActiveAccount={setActiveAccount}
              refresh={setRefresh}
            />
          </div>
          <div>
            {/* Table header */}
            <div className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 ">
              <div className=" col-span-1 sm:col-span-2 bg-ghGreen-100 h-16"></div>
              <div className=" hidden min-[900px]:flex items-center justify-center bg-ghGreen-100 text-ghGreen-600 h-16">
                Mengde
              </div>
              <div className=" hidden sm:flex col-span-2 pr-8 sm:pr-0 sm:col-span-1 min-[900px]:flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 h-16 ">
                Gyldig fra
              </div>
              <div className=" col-span-3 sm:col-span-1 flex items-center justify-start whitespace-nowrap sm:whitespace-normal pl-0 sm:justify-center bg-ghGreen-100 h-16 ">
                Status søknad
              </div>
              {/* Green bar if screen is bigger than sm */}
              <div className=" hidden sm:flex bg-ghGreen-100 h-16 "></div>
            </div>
            {/* Products content */}
            {getMyApplication?.data?.map((product, key) => (
              <div className=" mb-8 ">
                <div key={key} className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 mt-2 ">
                  <div className=" text-sm min-[900px]:text-base flex items-center pl-2 sm:pl-4 border-b border-grey bg-blue bg-opacity-20 h-16 uppercase font-semibold col-span-4 sm:col-span-5 min-[900px]:col-span-6 ">
                    {product?.planProductName}
                  </div>
                </div>
                {/* Customers content */}
                {product?.dispApplications?.map((dispApplications, key) => (
                  <>
                    {selected === dispApplications.id ? (
                      <TerminateApplication
                        dispApplications={dispApplications}
                        key={key}
                        selectedId={selected}
                        setSelectedId={setSelected}
                        setLoadingDone={setLoadingDone}
                        setRefresh={setRefresh}
                      />
                    ) : (
                      <DispensationApplicationContent
                        dispApplications={dispApplications}
                        key={key}
                        setSelectedId={setSelected}
                      />
                    )}
                  </>
                ))}
              </div>
            ))}
            {/* Add new */}
            {showAddButton ? (
              <div className=" mt-12 sm:mt-10 flex justify-start">
                <button
                  onClick={() => navigate("/minside/ny-soknad-dispensasjon/")}
                  className=" flex flex-row items-center text-xs sm:text-sm px-2 py-2 rounded-xl border border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                  </svg>
                  Start ny søknad
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function DispensationOverview() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <DispensationOverviewContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default DispensationOverview
