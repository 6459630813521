import { Date } from "prismic-reactjs"

export const FormatMobile = (mobilphone) => {
  if (mobilphone === null || mobilphone === undefined) return ""
  return Intl.DateTimeFormat("nb-NO", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(Date(mobilphone))
}
