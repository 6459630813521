import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import SupportInstructions from "../../../pages/veiledning"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"

const VeiledningContent = () => {
  return (
    <>
      <div>
        <MyPageLayout title="Veiledninger">
          <SupportInstructions />
        </MyPageLayout>
      </div>
    </>
  )
}

function Veiledning() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <VeiledningContent />
    </MsalAuthenticationTemplate>
  )
}

export default Veiledning
