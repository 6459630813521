import { Dot } from "../Graphics/Dot"

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
  <div>
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1

      return (
        <a
          key={index}
          href={createURL(page)}
          className={
            currentRefinement === page
              ? "inline-flex items-center px-2.5 py-0.5 rounded-full text-employee21 bg-bgmeny-500 text-black"
              : "border-transparent text-black-500 hover:text-orange-700 hover:border-orange-700 border-t-2 pt-4 px-4 inline-flex items-center text-employee21"
          }
          onClick={(event) => {
            event.preventDefault()
            refine(page)
          }}
        >
          {page}
        </a>
      )
    })}
  </div>
)

export type PaginatorType = {
  maxPage: number
  currentPage: number
  changePage: (page: number) => void
}

export const Paginator = (props: PaginatorType) => {
  const pages: Array<number> = []

  for (let i = 1; i <= props.maxPage; i++) {
    pages.push(i)
  }

  return (
    <nav className="px-4 flex items-center justify-center sm:px-0">
      <div className="-mt-px w-0 flex-1 flex"></div>
      <div className="md:-mt-px flex">
        <button
          onClick={() => props.changePage(Math.max(1, props.currentPage - 1))}
          className="border-t-2 border-transparent h-10 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          <svg
            className="mr-3 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" />
          </svg>
        </button>

        <div className="lg:hidden flex">
          {props.currentPage !== 1 ? (
            <div className="flex">
              <button
                onClick={() => props.changePage(1)}
                className="relative content-center z-10 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 px-4 h-10 inline-flex items-center text-sm font-medium"
              >
                1
              </button>
              <div className="pt-4 px-4 text-gray-500">
                <Dot />
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="relative ">
            <div className="relative content-center z-10 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 px-4 h-10 inline-flex items-center text-sm font-medium">
              {props.currentPage}
            </div>
            <div className="absolute z-0 top-0 left-0 h-10 w-10 rounded-full bg-lysgronn30-300"></div>
          </div>

          {props.currentPage !== props.maxPage ? (
            <div className="flex">
              <div className="pt-4 px-4 text-gray-500">
                <Dot />
              </div>
              <button
                onClick={() => props.changePage(props.maxPage)}
                className="relative content-center z-10 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 px-4 h-10 inline-flex items-center text-sm font-medium"
              >
                {props.maxPage}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="hidden lg:flex">
          {pages.map((page) => {
            return (
              <>
                <div className="relative">
                  <button
                    onClick={() => props.changePage(page)}
                    className="relative content-center z-10 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 px-4 h-10 inline-flex items-center text-sm font-medium"
                  >
                    {page}
                  </button>
                  <div>
                    {page === props.currentPage ? (
                      <div className="absolute z-0 top-0 left-0 h-10 w-10 rounded-full bg-lysgronn30-300"></div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                {page < props.maxPage ? (
                  <div className="pt-4 px-4 text-gray-500">
                    <Dot />
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )
          })}
        </div>

        <button
          onClick={() => props.changePage(Math.min(props.maxPage, props.currentPage + 1))}
          className="border-t-2 border-transparent h-10 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        >
          <svg
            className="ml-3 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" />
          </svg>
        </button>
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end"></div>
    </nav>
  )
}

export default Pagination
