import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { greenSaveButton, redButton } from "../../Styling/ButtonStyles"
import { NumericFormat } from "react-number-format"
import { useMutation, useQuery } from "react-query"
import { fetchApi, postApi } from "../../Common/WebApi-utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../ProductionPlan/Components/ReactQueries"
import { LoadingForValues } from "../../Security/LoadingForValues"
import { Controller, useForm } from "react-hook-form"
import LastSaved from "../ProductionPlan/Components/LastSavedComponent"

export type newCapacity = {
  planProductId: string
  vendorId: string
  capacityMonday: number
  capacityTuesday: number
  capacityWednesday: number
  capacityThursday: number
  capacityFriday: number
  capacitySaturday: number
  capacitySunday: number
}

const CapacityModal = ({ setShowModal, planProductName, vendorId, planProductId }) => {
  const [open, setOpen] = useState(true)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [loadingDone, setLoadingDone] = useState(false)
  const [error, setShowError] = useState(false)
  const [capacity, setCapacity] = useState({})
  const [newValuesSaved, setNewValuesSaved] = useState(false)
  const [allEmptyValues, setAllEmptyValues] = useState(false)
  const weekDays = [
    { weekName: "Mandag", capacityValue: "capacityMonday" },
    { weekName: "Tirsdag", capacityValue: "capacityTuesday" },
    { weekName: "Onsdag", capacityValue: "capacityWednesday" },
    { weekName: "Torsdag", capacityValue: "capacityThursday" },
    { weekName: "Fredag", capacityValue: "capacityFriday" },
    { weekName: "Lørdag", capacityValue: "capacitySaturday" },
    { weekName: "Søndag", capacityValue: "capacitySunday" },
  ]
  const { register, handleSubmit, reset } = useForm({})

  const getCapacity = useQuery(
    "myCapacityData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Bama/Capacity?planProductId=" + planProductId + "&vendorId=" + vendorId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setLoadingDone(true)
        setCapacity(data)
        reset({
          ...weekDays.reduce((acc, day) => {
            return { ...acc, [day.capacityValue]: data[day?.capacityValue] }
          }, {}),
        })
      },
      onError: (_error) => {
        setLoadingDone(true)
        setCapacity({})
      },
    }
  )

  const newCapacity = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/Bama/Capacity", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (res) => {
        setLoadingDone(true)
        setNewValuesSaved(true)
      },
      onError: (_error) => {
        setLoadingDone(true)
        setShowError(true)
      },
    }
  )

  const sendNewCapacity = (values) => {
    const newCapacityObject: newCapacity = {
      planProductId: planProductId,
      vendorId: vendorId,
    }

    let allZeroOrEmpty = true

    weekDays.forEach((day) => {
      const dayValue = values[day.capacityValue]
      newCapacityObject[day.capacityValue] = dayValue === "" ? 0 : parseInt(dayValue)
      // console.log(dayValue !== "" && dayValue !== "0" && dayValue !== 0)
      //Check if all input values are empty or zero
      if (dayValue !== "" && dayValue !== "0" && dayValue !== 0) {
        allZeroOrEmpty = false
      }
    })

    if (allZeroOrEmpty) {
      setAllEmptyValues(true)
      setNewValuesSaved(false)
    } else {
      setLoadingDone(false)
      newCapacity.mutate(newCapacityObject)
      setAllEmptyValues(false)
      console.log(newCapacityObject)
    }
  }

  console.log(getCapacity)
  console.log(capacity)
  console.log(Object.keys(capacity).length === 0)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto p-18 font-sans "
        onClose={() => {
          setOpen(true)
        }}
      >
        {loadingDone ? null : <LoadingForValues />}
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center backdrop-blur sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className=" h-auto inline-block align-bottom bg-white border-ghGreen-400 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  {error ? (
                    <div>
                      <Dialog.Title as="h3" className=" mb-4 text-lg font-medium ">
                        Det har skjedd en feil, prøv igjen senere
                      </Dialog.Title>
                      <div>
                        <button
                          className={redButton + " px-4 md:px-5 lg:px-7 py-2 md:py-3 lg:py-4 "}
                          onClick={() => {
                            setOpen(false)
                            setShowModal(false)
                          }}
                        >
                          Gå tilbake
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Dialog.Title as="h3" className=" text-lg font-medium ">
                        {planProductName}
                      </Dialog.Title>
                      <div>
                        <p>Her setter du standardverdier for produksjonskapasitet</p>
                      </div>
                      <form id="newCapacity" method="post" onSubmit={handleSubmit(sendNewCapacity)}>
                        <div className="flex flex-col items-start m-6 space-y-4">
                          {weekDays.map((day, i) => {
                            return (
                              <div key={i} className="flex flex-row w-full items-center justify-evenly">
                                <p className=" w-16 ">{day.weekName}: </p>
                                <input
                                  type="number"
                                  className=" w-32 border-solid border border-black p-2 "
                                  {...register(day.capacityValue)}
                                ></input>
                              </div>
                            )
                          })}
                        </div>
                        {newValuesSaved ? (
                          <div className=" flex justify-center mb-4 ">
                            <p className=" text-left text-sm ">Nye standardverdier er lagret</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                          </div>
                        ) : null}
                        {allEmptyValues ? (
                          <div className=" flex justify-center mb-4 ">
                            <p className="  text-left text-sm text-tomat-500 ">Du må legge til verdier</p>
                          </div>
                        ) : null}
                        <div className="flex justify-evenly items-center ">
                          <div>
                            <button
                              className={redButton + " px-4 md:px-5 lg:px-7 py-2 md:py-3 lg:py-4 "}
                              onClick={() => {
                                setOpen(false)
                                setShowModal(false)
                              }}
                            >
                              Lukk
                            </button>
                          </div>
                          <div>
                            <button type="submit" className={greenSaveButton}>
                              Send inn
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CapacityModal

/* <Controller
                                  control={control}
                                  defaultValue={getCapacity?.data?.[day?.capacityValue]}
                                  name={day.capacityValue}
                                  render={({ field: { onChange, name, value } }) => (
                                    <NumericFormat
                                      name={name}
                                      value={value}
                                      className=" w-32 "
                                      displayType="input"
                                      thousandSeparator={" "}
                                      onChange={onChange}
                                    />
                                  )}
                                /> */
