import { navigate } from "gatsby"
import moment from "moment"
import { applicationStatus, productUnit } from "../../ProductionPlan/Components/Maps"
import "moment/locale/nb"
import { NumericFormat } from "react-number-format"
import { MagnifyingGlassIcon, XButtonIcon } from "../../../Graphics/IconsMyPage"

const DispensationApplicationContent = ({ dispApplications, key, setSelectedId }) => {
  moment.locale("nb")

  let typeDesign = " bg-none "

  if (applicationStatus.get(dispApplications?.applicationStatus) === "Under behandling") {
    typeDesign = " bg-behandles "
  } else if (applicationStatus.get(dispApplications?.applicationStatus) === "Godkjent") {
    typeDesign = " bg-godkjent "
  } else {
    typeDesign = " bg-avvist "
  }

  return (
    <>
      <div key={key} className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 h-20 sm:h-16 ">
        <div className=" col-span-2  flex flex-col justify-center   min-[900px]:pl-8 border-b border-grey ">
          <div className=" text-sm min-[900px]:text-base  pl-2 uppercase font-semibold mt-2 mb-2 sm:mt-0 sm:mb-0 ">
            <p>{dispApplications?.customer?.customerName}</p>
          </div>
          {/* Show status here if smaller than sm */}
          <div className=" flex  sm:hidden justify-end items-center mb-2 ">
            <p className={" text-sm sm:text-base px-1 rounded-lg bg-opacity-60 " + typeDesign}>
              {applicationStatus.get(dispApplications?.applicationStatus)}
            </p>
          </div>
        </div>
        {/* Show amount if bigger than md */}
        <div className=" hidden min-[900px]:flex justify-center items-center  text-ghGreen-600 border-b border-grey">
          <p>
            <NumericFormat displayType="text" value={dispApplications?.quantity} thousandSeparator={" "} />{" "}
            {productUnit.get(dispApplications?.planProduct?.planProductUnit)}
          </p>
        </div>
        {/* Show date if bigger than sm */}
        <div className=" hidden sm:flex justify-center items-center text-ghGreen-600 border-b border-grey">
          <p>{moment(dispApplications?.dateFrom).format("L")}</p>
        </div>
        {/* Status */}
        <div className={" hidden  sm:flex justify-center items-center border-b border-grey"}>
          <p className={"  px-1 rounded-lg bg-opacity-60 bg-" + typeDesign}>
            {applicationStatus.get(dispApplications?.applicationStatus)}
          </p>
        </div>
        {/* Delete button */}
        <div className=" col-span-2 sm:col-span-1 flex items-center justify-center border-b border-grey">
          {dispApplications?.applicationStatus === 292460000 || dispApplications?.applicationStatus === 292460001 ? (
            <button
              onClick={() => setSelectedId(dispApplications.id)}
              className="border-2 border-grey hover:border-black active:bg-disabled rounded-lg p-1 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
            >
              <XButtonIcon />
            </button>
          ) : null}
        </div>
        {/* Button */}
        <div className=" border-none flex items-center justify-center ">
          <button
            onClick={() => navigate("/minside/soknad-dispensasjon?id=" + dispApplications?.id)}
            className=" md:py-0.5 px-1 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <MagnifyingGlassIcon />
            <span className=" hidden lg:flex ">Se detaljer</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default DispensationApplicationContent
