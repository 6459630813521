import { useState } from "react"

const CustomerFilter = ({ prop, setId, filterValue, setFilterValue, placeholder }) => {
  const [isFocus, setIsFocus] = useState(false)

  const handleFilter = (event) => {
    setFilterValue(event.target.value)
    setId("")
    // setHasYearTerms(false)
  }

  return (
    <>
      <input
        onChange={handleFilter}
        onFocus={() => setIsFocus(true)}
        onBlur={() => filterValue !== ""}
        value={filterValue}
        type="text"
        placeholder={placeholder}
        className={
          isFocus && filterValue.length > 2
            ? " rounded-t-lg text-xs sm:text-base placeholder:text-black font-sans font-normal py-2 px-2 sm:px-4 border border-grey w-full h-8 sm:h-12 focus:ring-0 focus:ring-grey focus:border-grey  "
            : " rounded-lg text-xs sm:text-base placeholder:text-black font-sans font-normal py-2 px-2 sm:px-4 border border-grey w-full h-8 sm:h-12 focus:ring-0 focus:ring-grey focus:border-grey  "
        }
      ></input>
      <>
        {isFocus ? (
          <ul className=" w-full absolute shadow-lg shadow-grey bg-white border border-grey max-h-48 h-auto overflow-auto font-sans font-normal text-xs sm:text-base ">
            {prop?.data?.map((customer, i) => (
              <button
                onClick={() => {
                  setFilterValue(customer.customerName)
                  setId(customer.id)
                  setIsFocus(false)
                  // setHasYearTerms(customer.hasYearTerms)
                }}
                className=" hover:bg-grey w-full text-left"
              >
                <li className=" py-2 px-4 " id={i}>
                  <p>{customer?.customerName}</p>
                </li>
              </button>
            ))}
          </ul>
        ) : null}
      </>
    </>
  )
}

export default CustomerFilter
