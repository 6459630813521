const DateComponent = ({ Day, Month, Year, DayValue, MonthValue, YearValue, Error }) => {
  return (
    <>
      <div className=" flex flex-row space-x-4">
        <div className=" flex flex-col ">
          <p className=" ml-2 "> Dag </p>
          <input
            defaultValue={DayValue}
            className={
              Error === true
                ? " text-center rounded-xl border-Avvist w-12 ml-2 focus:ring-1 focus:ring-Avvist focus:border-Avvist "
                : " text-center rounded-xl border-grey w-12 ml-2 focus:ring-1 focus:ring-black focus:border-black "
            }
            type="text"
            maxLength={2}
            onChange={Day}
            required
          />
        </div>
        <div className=" flex flex-col ">
          <p className=" ml-2 "> Måned </p>
          <input
            defaultValue={MonthValue}
            className={
              Error === true
                ? " text-center rounded-xl border-Avvist w-12 ml-2 focus:ring-1 focus:ring-Avvist focus:border-Avvist "
                : " text-center rounded-xl border-grey w-12 ml-2 focus:ring-1 focus:ring-black focus:border-black "
            }
            type="text"
            maxLength={2}
            onChange={Month}
            required
          />
        </div>
        <div className=" flex flex-col ">
          <p className=" ml-2 "> År </p>
          <input
            defaultValue={YearValue}
            className={
              Error === true
                ? " text-center rounded-xl border-Avvist w-20 ml-2 focus:ring-1 focus:ring-Avvist focus:border-Avvist "
                : " text-center rounded-xl border-grey w-20 ml-2 focus:ring-1 focus:ring-black focus:border-black "
            }
            type="text"
            maxLength={4}
            onChange={Year}
            pattern="\d{4}"
            required
          />
        </div>
      </div>
    </>
  )
}

export default DateComponent
