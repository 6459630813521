import React, { useContext, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { productUnit } from "../../ProductionPlan/Components/Maps"
import { UserContext } from "../../ProductionPlan/Components/UserContext"

const BamaPlansTableLines = ({ prodPlan, percentage, amount, amountChanged, weekNumber, manualRegistration }) => {
  const [registeredValue, setRegisteredValue] = useState<number>(0)
  const [registeredArray, setRegisteredArray, setShowSaveButton] = useContext(UserContext)

  //Check if it is for manual registration false. Do the percentage calculation if it registered value is -1
  useEffect(() => {
    if (manualRegistration === false) {
      if (prodPlan?.planWeeks?.[0]?.registered === -1 || amountChanged === true) {
        setRegisteredValue(Math.round(amount * percentage) || 0)
      } else {
        setRegisteredValue(prodPlan?.planWeeks?.[0]?.registered === -1 ? 0 : prodPlan?.planWeeks?.[0]?.registered)
      }
    } else {
      setRegisteredValue(prodPlan?.planWeeks?.[0]?.registered === -1 ? 0 : prodPlan?.planWeeks?.[0]?.registered)
      setRegisteredArray((prevState) =>
        prevState.map((obj) =>
          obj.prodPlanId === prodPlan.id
            ? { ...obj, amount: prodPlan?.planWeeks?.[0]?.registered === -1 ? 0 : prodPlan?.planWeeks?.[0]?.registered }
            : obj
        )
      )
    }
  }, [amount, manualRegistration])

  //Update the registeredArray with the new value
  useEffect(() => {
    setRegisteredArray((prevState) =>
      prevState.map((obj) =>
        obj.prodPlanId === prodPlan.id ? { ...obj, amount: registeredValue === -1 ? 0 : registeredValue } : obj
      )
    )
  }, [registeredValue, manualRegistration])

  return (
    <React.Fragment key={weekNumber + prodPlan.id}>
      <tr className=" text-xs sm:text-base  ">
        <td className=" pl-2 sm:pl-10 py-4 border-b border-grey uppercase">{prodPlan?.customer?.customerName}</td>
        {/* Plan */}
        <td className=" table-cell border-b border-grey text-center text-ghGreen-600 ">
          <NumericFormat
            displayType="text"
            value={prodPlan?.planWeeks?.[0]?.planWeekQuantity}
            thousandSeparator={" "}
          />{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
        {/* Registered */}
        <td className="  text-center  border-b border-grey ">
          {manualRegistration ? (
            <NumericFormat
              className="text-center text-xs h-8 w-14 sm:text-base sm:w-28 mr-2"
              onValueChange={(e) => {
                setRegisteredValue(e.floatValue)
                setShowSaveButton(true)
              }}
              displayType="input"
              value={registeredValue}
              thousandSeparator={" "}
              decimalScale={0}
            />
          ) : (
            <NumericFormat displayType="text" value={registeredValue} thousandSeparator={" "} decimalScale={0} />
          )}{" "}
          {productUnit.get(prodPlan?.planProduct?.planProductUnit)}
        </td>
        {/* Empty space */}
        <td className="  text-center  border-b border-grey "></td>
      </tr>
    </React.Fragment>
  )
}

export default BamaPlansTableLines
