import { useAccount, useMsal } from "@azure/msal-react"
import { Switch } from "@headlessui/react"
import React, { useContext, useEffect, useState } from "react"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { closeNotification, fetchApi, patchApi } from "../../Common/WebApi-utils"
import Cookies from "js-cookie"
import LoadingData from "../../Graphics/LoadingData"
import UpdatingData from "../../Graphics/UpdatingData"
import Notification from "../../Graphics/Notification"
import { ConnectionContext } from "../../CustomHooks/ConnectionContext"

const analyticsCookie = "gatsby-gdpr-google-analytics"
const personalizationCookie = "gatsby-gdpr-google-tagmanager"
const marketingCookie = "gatsby-gdpr-facebook-pixel"
const consentExperationDays = 90

const Consent = () => {
  const queryClient = useQueryClient()
  const [enabled1, setEnabled1] = useState(false)
  const [enabled2, setEnabled2] = useState(false)
  const [cannotChangeConsent, setCannotChangeConsent] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [contactId, setContactId] = useState("false")
  const [showUpdateData, setShowUpdateData] = useState(false)
  const { connection, setConnection } = useContext(ConnectionContext)
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  const userProfileQuery = useQuery(
    "userProfileData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Contacts", account, inProgress, instance).then((res) => res.data),
    {
      onSuccess: (data) => {
        setContactId(data.id)
        if (data?.contsentGiven === 587030005) {
          setEnabled1(true)
          setEnabled2(true)
        }
        if (data?.contsentGiven < 587030005) {
          setEnabled1(true)
          setEnabled2(false)
        }
        if (data?.contsentGiven < 587030003) {
          setEnabled1(false)
          setEnabled2(false)
        }
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )

  const mutationUpdate = useMutation(
    (data) => {
      return patchApi(process.env.REACT_APP_API_URL_APIM + "/Contacts/me", data, account, inProgress, instance).then(
        (res) => res.data
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userProfileData"])
        closeNotification(setNotification)
        setNotification({
          title: "Vellykket",
          message: "Informasjonen er oppdatert",
          color: "green",
          delay: 3000,
          showNotification: true,
        })
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
      onSettled: () => {
        setShowUpdateData(false)
      },
    }
  )

  const userConnectionQuery = useQuery(
    "userConnectionData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: !!contactId,
      onSuccess: (connection) => {
        setConnection(connection)
        if (connection !== undefined && Object.keys(connection).length > 0) {
          const acRole = connection.some((v) => v.accessRole === 1 || v.accessRole === 3)

          if (!acRole) {
            if (!enabled1) {
              // let data = {}
              // let consentGiven = 587030003
              setEnabled1(true)

              if (!enabled2) {
                setEnabled2(true)
                // consentGiven = 587030005
              }

              // data = { ContsentGiven: consentGiven }
              // mutationUpdate.mutate(data)
            }
          } else setCannotChangeConsent(true)
        }
      },
      onError: (_error) => {
        //
      },
    }
  )

  const Consent1 = () => {
    let data = {}
    let consentGiven = 587030003
    setEnabled1(!enabled1)

    if (enabled2) consentGiven = 587030005

    if (enabled1) {
      setEnabled2(false)
      consentGiven = 587030001
    }

    if (enabled1) {
      Cookies.set(personalizationCookie, "false", { expires: consentExperationDays })
      Cookies.set(marketingCookie, "false", { expires: consentExperationDays })
    }

    if (!enabled1) Cookies.set(marketingCookie, "true", { expires: consentExperationDays })

    data = { ContsentGiven: consentGiven }
    mutationUpdate.mutate(data)
  }

  const Consent2 = () => {
    let data = {}
    let consentGiven = 587030005
    setEnabled2(!enabled2)

    if (!enabled2) setEnabled1(true)

    if (enabled2) consentGiven = 587030003

    if (!enabled2) {
      Cookies.set(personalizationCookie, "true", { expires: consentExperationDays })
      Cookies.set(marketingCookie, "true", { expires: consentExperationDays })
    }

    if (enabled2) {
      Cookies.set(personalizationCookie, "false", { expires: consentExperationDays })
      Cookies.set(marketingCookie, "false", { expires: consentExperationDays })
    }

    data = { ContsentGiven: consentGiven }
    mutationUpdate.mutate(data)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  return userProfileQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userProfileQuery.isError ? (
    userProfileQuery.error.message
  ) : (
    <>
      <div>
        <div>{showUpdateData && <UpdatingData />}</div>
        <div>{notification.showNotification && <Notification {...notification} />}</div>
        <h2 className="text-header22 font-medium leading-normal text-gray-900">Mine samtykker</h2>
        {!cannotChangeConsent ? (
          <div>
            <Switch.Group as="div" className="flex items-center justify-between my-4">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className=" text-header18 mt-4 font-medium text-gray-900" passive>
                  Markedsføring
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className="text-body18 leading-6 text-gray-500  w-auto  pr-8 md:pr-4 md:w-4/5"
                >
                  Jeg samtykker til at Gartnerhallen kan sende meg informasjon om aktuelle nyheter, arrangementer,
                  spørreundersøkelser og lignende via e-post og SMS.
                </Switch.Description>
              </span>
              <Switch
                disabled={cannotChangeConsent}
                checked={enabled1}
                onChange={Consent1}
                className={classNames(
                  enabled1 ? " bg-tomat-500" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled1 ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>

            <Switch.Group as="div" className="flex items-center justify-between my-4">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className=" text-header18 mt-4 font-medium text-gray-900" passive>
                  Personlig tilpasning
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className="text-body18 leading-6 text-gray-500  w-auto  pr-8 md:pr-4 md:w-4/5"
                >
                  Jeg samtykker til at Gartnerhallen kan benytte automatisk analyse av mine data. Slik analyse benyttes
                  til å tilpasse og forbedre brukeropplevelsen, sende og vise relevant informasjon med utgangspunkt i
                  mine preferanser og til å forbedre interne prosesser.
                </Switch.Description>
              </span>
              <Switch
                disabled={cannotChangeConsent}
                checked={enabled2}
                onChange={Consent2}
                className={classNames(
                  enabled2 ? " bg-tomat-500" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled2 ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        ) : null}
        <div className="mt-12">
          <a
            className="text-body16 leading-6  underline hover:text-tomat-500"
            href="https://gartnerhallen.no/personvern"
          >
            Les mer i vår personvernerklæring
          </a>
        </div>
      </div>
    </>
  )
}

export default Consent
