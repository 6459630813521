import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useMutation, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useEffect, useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi, postApi } from "../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import VendorComponent from "./Components/VendorComponent"
import { NumericFormat } from "react-number-format"
import DateComponent from "./Components/DateComponent"
import { productUnit } from "../ProductionPlan/Components/Maps"
import { inputField } from "../../Styling/InputField"
import ApplicationProductFilter from "./Components/ApplicationProductFilter"
import ApplicationCustomerFilter from "./Components/ApplicationCustomerFilter"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

export type newApplicationDisp = {
  Type: number
  Account: string
  Vendor: string
  ApplicationStatus: number
  ProducerComment: string
  Quantity: number
  DateFrom: string
  Source: string
  PlanProduct: string
  Customer: string
}

const NewDispensationApplicationContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })
  const [loadingDone, setLoadingDone] = useState(false)
  const [productFilterValue, setProductFilterValue] = useState("")
  const [customerFilterValue, setCustomerFilterValue] = useState("")
  const [productId, setProductId] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [toWeek, setToWeek] = useState("")
  const [fromDay, setFromDay] = useState("")
  const [fromMonth, setFromMonth] = useState("")
  const [fromYear, setFromYear] = useState("")
  const [unit, setUnit] = useState(0)
  const [comment, setComment] = useState("")
  const [quantity, setQuantity] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [listOfVendors, setListOfVendors] = useState(false)

  const infoText = portalSettingsText?.data?.find((x) => x.key === "DispensasjonSøknadIngress")

  const fromDate = new Date(fromYear + "-" + fromMonth + "-" + fromDay)

  useEffect(() => {
    if (isNaN(fromDate) === false) {
      setDateError(false)
    }
  }, [fromDate])

  const handleFromDay = (e) => {
    setFromDay(e.target.value)
  }

  const handleFromMonth = (e) => {
    setFromMonth(e.target.value)
  }

  const handleFromYear = (e) => {
    setFromYear(e.target.value)
  }

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const getMyVendor = useQuery(
    "myVendorData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
        setLoadingDone(true)
        if (data.length > 0) {
          setListOfVendors(true)
        }
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )
  const [activeVendor, setActiveVendor] = useState(getMyVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyVendor?.data?.[0]?.accountId)

  const getPlanProduct = useQuery(
    "PlanProductData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanProduct/PlanProducts?searchParam=" + productFilterValue,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: productFilterValue.length > 2,
      onSuccess: (data) => {
        //   console.log(data)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
      },
    }
  )

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (productFilterValue.length) {
      case 3:
        getPlanProduct.refetch()
        break
      case 5:
        getPlanProduct.refetch()
        break
      case 7:
        getPlanProduct.refetch()
        break
      case 9:
        getPlanProduct.refetch()
        break
      case 11:
        getPlanProduct.refetch()
        break
      case 13:
        getPlanProduct.refetch()
        break
    }
  }, [productFilterValue])

  const getCustomers = useQuery(
    "CustomerData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Customer?searchParam=" +
          customerFilterValue +
          "&customerType=dispCustomers",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        //   console.log(data)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
      },
    }
  )

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (customerFilterValue.length) {
      case 0:
        getCustomers.refetch()
        break
      case 2:
        getCustomers.refetch()
        break
      case 4:
        getCustomers.refetch()
        break
      case 6:
        getCustomers.refetch()
        break
      case 8:
        getCustomers.refetch()
        break
      case 10:
        getCustomers.refetch()
        break
      case 12:
        getCustomers.refetch()
        break
    }
  }, [customerFilterValue])

  const CreateNewApplication = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/Application", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        setLoadingDone(true)
        navigate("/minside/oversikt-dispensasjon/")
      },
    }
  )

  const sendApplication = () => {
    if (isNaN(fromDate) === false) {
      const applicationObject: newApplicationDisp = {
        Type: 292460000,
        Account: activeAccount,
        Vendor: activeVendor,
        ApplicationStatus: 292460000,
        ProducerComment: comment,
        Quantity: parseInt(quantity),
        DateFrom: fromDate.toISOString(),
        Source: "MinSide",
        PlanProduct: productId,
        Customer: customerId,
      }
      CreateNewApplication.mutate(applicationObject)
      setLoadingDone(false)
    } else {
      setDateError(true)
    }
  }

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row font-sans mt-6 sm:mt-0 ">
            <Link to="/minside/oversikt-dispensasjon" className=" flex flex-row ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              <p className="underline underline-offset-2 decoration-tomat-500">Tilbake til dispensasjoner</p>
            </Link>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Søknad om dispensasjon</h1>
            <p>{infoText?.value}</p>
          </div>
          {listOfVendors ? (
            <form name="newApplicationDisp" method="post" onSubmit={handleSubmit(sendApplication)}>
              {/* Vendor */}
              <div className=" mb-8 flex flex-col">
                <div>
                  <p className=" text-base mb-4 font-semibold ">Hvem søker du dispensasjon for?</p>
                </div>
                <VendorComponent
                  getMyVendor={getMyVendor}
                  activeVendor={activeVendor}
                  setActiveVendor={setActiveVendor}
                  setActiveAccount={setActiveAccount}
                  refresh={setRefresh}
                />
              </div>
              {/* Product filter */}
              <div className=" mb-8 flex flex-col max-w-63">
                <p className=" font-semibold mb-2">Hvilken vare skal dispensasjonen gjelde for?</p>
                <p className=" text-sm mb-1">Varenavn</p>
                <div className=" relative ">
                  <ApplicationProductFilter
                    prop={getPlanProduct}
                    setId={setProductId}
                    filterValue={productFilterValue}
                    setFilterValue={setProductFilterValue}
                    placeholder={"Skriv for å søke etter varenavn"}
                    setUnit={setUnit}
                  />
                </div>
              </div>
              {/* Amount */}
              <div className=" mb-8 flex flex-col">
                <p className=" font-semibold mb-2">Hvilken mengde skal leveres?</p>
                <p className=" text-sm mb-1">Må skrives i hele tall</p>
                <div className=" flex flex-row items-center ">
                  <NumericFormat
                    type="tel"
                    displayType="input"
                    onChange={(e) => setQuantity(e.target.value.replace(/\s/g, ""))}
                    className={inputField + " w-44 "}
                    required
                    thousandSeparator=" "
                  />
                  <p className=" ml-2 "> {unit > 0 ? productUnit.get(unit) : null} </p>
                </div>
              </div>
              {/* Customer filter */}
              <div className=" mb-8 flex flex-col max-w-63">
                <p className=" font-semibold mb-2">Hvilken kunde ønsker du å levere til?</p>
                <p className=" text-sm mb-1">Kundenavn</p>
                <div className=" relative ">
                  <ApplicationCustomerFilter
                    prop={getCustomers}
                    setId={setCustomerId}
                    filterValue={customerFilterValue}
                    setFilterValue={setCustomerFilterValue}
                    placeholder={"Skriv for å søke etter kundenavn"}
                  />
                </div>
              </div>
              {/* From date */}
              <div className=" mb-8 flex flex-col">
                <p className=" font-semibold mb-2">Hvilken dato skal dispensasjonen gjelde fra?</p>
                <p className=" text-sm mb-2">F.eks. 20 05 2023</p>
                <DateComponent
                  Day={handleFromDay}
                  Month={handleFromMonth}
                  Year={handleFromYear}
                  DayValue={fromDay}
                  MonthValue={fromMonth}
                  YearValue={fromYear}
                  Error={dateError}
                />
                {dateError ? <p className=" text-sm pl-2 mt-2 text-avvist ">Datoen er ikke gyldig</p> : null}
              </div>
              {/* Comment */}
              <div className=" mb-8 flex flex-col">
                <p className=" font-semibold mb-2">Skriv en kort begrunnelse for hvorfor det søkes dispensasjon</p>
                <p className=" text-sm mb-1">Begrunnelse</p>
                <textarea
                  required
                  maxLength={200}
                  onChange={handleComment}
                  className=" max-w-63 border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black "
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0  focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500"
                >
                  Send søknad
                </button>
              </div>
            </form>
          ) : (
            <div>
              <p>Du har ingen leverandører å velge mellom. Ta kontakt med Gartnerhallen for mer informasjon.</p>
            </div>
          )}
        </div>
      </MyPageLayout>
    </>
  )
}

function NewDispensationApplication() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <NewDispensationApplicationContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default NewDispensationApplication
