import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useMutation, useQuery } from "react-query"
import "moment/locale/nb"
import { GetProfile } from "./Components/ReactQueries"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { fetchApi, patchApi, postApi } from "../../Common/WebApi-utils"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { PlanLoading } from "./Components/PlanLoading"
import MyPageLayout from "../../Layout/MyPageLayout"
import { NumberParam, StringParam, useQueryParam } from "use-query-params"
import { NumericFormat } from "react-number-format"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { navigate } from "gatsby"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

export type comment = {
  PlanProductProcess: string
  CommentFrom: number
  Comment: string
  RegionCommitteId: string
}

export type status = {
  Id: string
  ProcessStatus: number
}

const RkpPlansCommentContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const isAuthenticated = useIsAuthenticated()
  const account = useAccount(accounts[0] || {})
  const { handleSubmit } = useForm({
    mode: "onChange",
  })
  const [planProductProcessId, setPlanProductProcessId] = useQueryParam("id", StringParam)
  const [product, setProduct] = useQueryParam("product", StringParam)
  const [period, setPeriod] = useQueryParam("period", StringParam)
  const [sum, setSum] = useQueryParam("sum", NumberParam)
  const [productUnit, setProductUnit] = useQueryParam("unit", StringParam)
  const [loadingDone, setLoadingDone] = useState(false)
  const [comment, setComment] = useState("")
  const [commentExist, setCommentExist] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [error, setError] = useState(false)
  const [commitee, setCommitee] = useState("")

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const userConnection = useQuery(
    "checkForCommiteeData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: !!isAuthenticated,
      onSuccess: (data) => {
        data.map((connection, key) => {
          return connection.accessRole === 7 && connection.connectedFromEntity === "pp_committee"
            ? setCommitee(connection.connectedFrom)
            : console.log("no access")
        })
      },
      onError: (_error) => {
        navigate("/minside/")
      },
    }
  )

  // The planProductProcess status needs to change to "Ferdig Regionsråd/292460004". Then the post for the comment will run.
  const changeStatus = () => {
    const planObjectStatus: status = {
      Id: planProductProcessId,
      ProcessStatus: 292460004,
    }
    patchPlanProductProcess.mutate(planObjectStatus)
    setLoadingDone(false)
  }

  // Patch status to "Ferdig SKP/292460002"
  const patchPlanProductProcess = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/PlanProductProcess", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        const commentObject: comment = {
          PlanProductProcess: planProductProcessId,
          CommentFrom: 292460001,
          Comment: comment,
          RegionCommitteId: commitee,
        }
        SendNewComment.mutate(commentObject)
      },
    }
  )

  // Reset status to "Klar SKP/292460001"
  const patchPlanProductProcessReset = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/PlanProductProcess", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        // What if this path also fails?
      },
      onSuccess: (res) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  // Get planProductProcess
  const getPlanProductProcessComment = useQuery(
    "PlanProductProcessComment",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanProductProcess/Comment?processId=" + planProductProcessId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userConnection.isSuccess && commitee !== "",
      onSuccess: (data) => {
        setLoadingDone(true)
        if (data?.length !== 0) {
          data?.map((comment) => {
            return comment?.commentFrom === 292460001
              ? (setComment(comment?.comment), setCommentExist(true))
              : console.log("ingen kommentar")
          })
        }
      },
      onError: (_error) => {
        //  error,message
      },
    }
  )

  // Post comment after patching status. If the comment fails, we need a system to reset the status back to its original status, value 292460001.
  const SendNewComment = useMutation(
    async (values) =>
      postApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanProductProcess/Comment",
        values,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        const restStatusObject: status = {
          Id: planProductProcessId,
          ProcessStatus: 292460003,
        }
        patchPlanProductProcessReset.mutate(restStatusObject)
      },
      onSuccess: (res) => {
        navigate("/minside/regionrad/innspill-produksjonsplaner")
      },
    }
  )

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row font-sans mt-6 sm:mt-0 ">
            <Link to="/minside/regionrad/innspill-produksjonsplaner" className=" flex flex-row ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              <p className="underline underline-offset-2 decoration-tomat-500">Tilbake til produksjonplaner</p>
            </Link>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Legg til en kommentar</h1>
          </div>
          <div className=" flex flex-col max-w-128 ">
            {/* Product and year */}
            <div className=" flex h-16 justify-center items-center bg-ghGreen-100 text-center uppercase font-semibold mb-2">
              {product} {period}
            </div>
            {/* Sum information */}
            <div className=" h-12 w-full border-b border-x border-grey flex flex-row items-center justify-center">
              <p className=" mr-2">Sum total</p>
              <p className="text-base font-semibold ">
                <NumericFormat displayType="text" value={sum} thousandSeparator={" "} /> {productUnit}
              </p>
            </div>
            {/* Comment */}
            {commentExist ? (
              <div className=" mt-8">
                <textarea
                  readOnly
                  maxLength={200}
                  defaultValue={comment}
                  className=" w-full border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black"
                ></textarea>
              </div>
            ) : (
              <form name="sendComment" method="post" onSubmit={handleSubmit(changeStatus)}>
                <div className=" mt-8">
                  <textarea
                    required
                    placeholder="Her kan man gi innspill til produksjonsplan til Gartnerhallens produksjonsplanleggere"
                    maxLength={200}
                    defaultValue={comment}
                    onChange={handleComment}
                    className=" w-full border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black"
                  ></textarea>
                </div>
                <div className=" flex justify-end">
                  <button
                    type="submit"
                    className=" mt-4 px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0  focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500"
                  >
                    Send kommentar
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function RkpPlansComment() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <RkpPlansCommentContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default RkpPlansComment
