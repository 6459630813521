import { Fragment, useState, useEffect } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

const sortOptions = [
  { name: "Alle fakturaer", value: "alt", current: true },
  { name: "Kjøpsfakturaer", value: "kunde", current: false },
  { name: "Salgsfakturaer", value: "leverandor", current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function InvoiceFilter({ setSortType, setOptionName }) {
  //   const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  function updateState(value: string, name: string) {
    setSortType(value)
    setOptionName(name)
  }

  return (
    <div className="bg-white">
      <div>
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative z-10 mb-8 -mt-8 md:mr-8 flex flex-row-reverse ">
            <div className="">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group rounded-2xl inline-flex justify-center border-2 py-2 px-4 text-base font-medium text-gray-600 hover:text-gray-900  hover:bg-knapplysgronn-500  ">
                    Filtrér utvalg
                    <ChevronDownIcon
                      className="flex-shrink-0 mt-1 -mr-1 ml-1 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-50 rounded-md  bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <button
                              onClick={() => updateState(option.value, option.name)}
                              className={classNames(
                                option.current ? "font-medium text-gray-900" : "text-gray-500 ",
                                active ? "bg-knapplysgronn-500" : "",
                                " px-4 py-2 text-base w-full flex justify-start"
                              )}
                            >
                              {option.name}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
