import { useState } from "react"
import { errorHandler } from "../../Common/errorHandler"
import { deleteApi, fetchApi, patchApi } from "../../Common/WebApi-utils"
import axios from "axios"
import MyPageLayout from "../../Layout/MyPageLayout"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { AuthToken } from "../../Security/authToken"
import { InteractionType } from "@azure/msal-browser"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { FormatMobile } from "../../CustomHooks/MobilePhoneFormatter"

/**
 *
 * @description This component provides and interface to DocumentAPI and UI less method to get a document from Document API
 *
 */

const DocumentsContent = () => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const DocumentId = ""
  const regardingObjectId = "" // props.regardingObjectId
  const regardingObjectName = "" // props.regardingObjectName
  const [selectedFiles, setselectedFiles] = useState([{}])

  const documentQuery = useQuery(
    "documentData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Documents/me", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: () => {
        // Loading
      },
      onError: (_error) => {
        // error.message
      },
    }
  )

  const CreateDocument = useMutation(
    async () => {
      if (selectedFiles == null) return

      const fileArray = selectedFiles
      const formData = new FormData()
      Array.from(fileArray).forEach((file) => formData.append("arrayOfFiles", file))

      formData.append("DocumentId", DocumentId)
      formData.append("EntityId", accounts[0].idTokenClaims.extension_contactId)
      formData.append("EntityName", "contact")

      const accessToken = await AuthToken(account, inProgress, instance)

      axios.post(process.env.REACT_APP_API_URL_APIM + "/Documents", formData, {
        headers: {
          "Conent-Type": "multipart/form-data",
          Authorization: "Bearer " + accessToken,
          "Ocp-Apim-Subscription-Key": process.env.API_KEY,
        },
      })
    },
    {
      onError: (_error) => {
        const errorMessage = errorHandler(_error)
        // error.message
      },
      onSettled: () => {
        queryClient.invalidateQueries("documentData")
        setselectedFiles(null)
        // Dokumentet er lagret
      },
    }
  )

  const UpdateDocument = useMutation(
    () => {
      if (selectedFiles == null) return

      if (selectedFiles.length > 1) {
        // Waring:Du kan bare oppdatere et dokument av gangen
        return
      }

      const fileArray = selectedFiles
      const formData = new FormData()
      formData.append("DocumentId", DocumentId)
      Array.from(fileArray).forEach((file) => formData.append("arrayOfFiles", file))

      return patchApi(process.env.REACT_APP_API_URL_APIM + "/Documents", formData, account, inProgress, instance)
    },
    {
      onError: (_error) => {
        const errorMessage = errorHandler(_error)
        // Error.message
      },
      onSettled: () => {
        queryClient.invalidateQueries("documentData")
        // Message; dokumentet er oppdatert
        setselectedFiles(null)
      },
    }
  )

  const DeleteDoc = useMutation(
    (currentDoc) => {
      deleteApi(
        process.env.REACT_APP_API_URL_APIM + "/Documents/Portal/Delete/" + currentDoc,
        account,
        inProgress,
        instance
      )
    },
    {
      onError: (_error) => {
        const errorMessage = errorHandler(_error)
        // Error.message
      },
      onSettled: () => {
        queryClient.invalidateQueries("documentData")
        // Message: Dokumentet er slettet
      },
    }
  )

  const DownloadDoc = useMutation(
    async (currentDoc) => {
      const accessToken = await AuthToken(account, inProgress, instance)

      axios.get(process.env.REACT_APP_API_URL_APIM + "/Documents/Download/" + currentDoc, {
        headers: {
          "Conent-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          "Ocp-Apim-Subscription-Key": process.env.API_KEY,
        },
        responseType: "blob",
      })
    },
    {
      onError: (_error) => {
        const errorMessage = errorHandler(_error)
        // error.message
      },
      onSettled: (response, currentDoc) => {
        queryClient.invalidateQueries("documentData")
        // Dokumentet er lastet ned

        const statusCode = response.status
        if (statusCode === 200) {
          // TODO: remove window
          // const url = window.URL.createObjectURL(new Blob([response.data]))
          // const link = document.createElement("a")
          // link.href = url
          // link.setAttribute("download", currentDoc)
          // document.body.appendChild(link)
          // link.click()
        } else {
          // error.message
        }
      },
    }
  )

  const onFileChange = (e) => setselectedFiles(e.target.files)

  const onCreateDocument = () => CreateDocument.mutate()

  const onUpdateDocument = () => UpdateDocument.mutate()

  const onDeleteDoc = (currentDoc) => DeleteDoc.mutate(currentDoc)

  const onDownloadDoc = (currentDoc) => DownloadDoc.mutate(currentDoc)

  const renderDocuments = () => {
    if (documentQuery.isLoading) return <div>laster inn..</div>

    if (documentQuery.isError) return <div>documentQuery.error.message</div>

    const data = Array.from(documentQuery.data)

    return data?.map((document, index) => {
      return (
        <tr key={document.id}>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <a onClick={() => onDownloadDoc(document.blobId)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
                />
              </svg>
            </a>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.title}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.contentType}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.fileSize}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.owner}</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {FormatMobile(document.modifiedOn)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <a onClick={() => onDeleteDoc(document.blobId)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
            </a>
          </td>
        </tr>
      )
    })
  }

  return (
    <MyPageLayout title="Dokumenter">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Download
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th> */}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Size(kb)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Owner
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Modiefied on
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">{renderDocuments()}</tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <fieldset className="mt-6 bg-white">
            <div className="mt-1 flex rounded-md shadow-sm">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  {/* <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                </svg> */}
                </div>
                <input
                  type="file"
                  name="fileupload"
                  id="fileupload"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                  onChange={onFileChange}
                  multiple
                />
              </div>
              <button
                onClick={() => onCreateDocument()}
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                {/* <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                  </svg> */}
                <span>Lastopp</span>
              </button>
              <button
                onClick={() => onUpdateDocument()}
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                {/* <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                  </svg> */}
                <span>Oppdater</span>
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    </MyPageLayout>
  )
}

function Documents() {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <DocumentsContent />
    </MsalAuthenticationTemplate>
  )
}

export default Documents
