import React from "react"
import { PrimaryBadgeLinkMedium } from "../../Graphics/Badge"

const HomeDefault = () => {
  return (
    <div>
      <div className="w-auto md:w-63 lg:w-84  xl:w-116 2xl:w-176">
        <h2 className="text-header22 mb-4">Ønsker du bli medeier i Gartnerhallen?</h2>
        <div className=" max-w-3xl">
          <p>
            Foretak som produserer poteter, grønnsaker, frukt, bær og planter kan søke om å bli eier. Eierskap fordrer
            produksjonsplan eller kontrakt, samt leveranse gjennom Gartnerhallen SA. Eierskapet registreres på
            organisasjonsnummer, og inntil to personer knyttet til enkeltpersonforetak kan registreres i et felles
            eierskap.
          </p>
          <div className=" mb-16 mt-8  flex justify-center md:justify-end md:mr-8 lg:mr-8">
            <PrimaryBadgeLinkMedium
              title="Søk medlemskap"
              url="https://assets.ctfassets.net/n81z2a3e2ebd/7khpsNN3plyjoQBRM3jwrD/f80596a99d3e945661994df3627b7b04/S__knad_om_medlemskap_Gartnerhallen.pdf"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeDefault
