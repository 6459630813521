import { useAccount, useMsal } from "@azure/msal-react"
import { useMutation } from "react-query"
import { patchApi } from "../../../Common/WebApi-utils"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"
import { XButtonIcon } from "../../../Graphics/IconsMyPage"
import { useState } from "react"
import NotificationAlert from "../../../Graphics/NotificationAlert"

export type terminate = {
  Id: string
  ApplicationStatus: number
  Source: string
}

const TerminateApplication = ({ dispApplications, key, selectedId, setSelectedId, setLoadingDone, setRefresh }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const TerminateApplication = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/Application", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        setSelectedId("")
        setLoadingDone(true)
        setRefresh(true)
      },
    }
  )

  const terminateAplication = () => {
    const terminateObject: terminate = {
      Id: selectedId,
      ApplicationStatus: 292460004,
      Source: "MinSide",
    }
    TerminateApplication.mutate(terminateObject)
    setLoadingDone(false)
  }

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <div key={key} className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 h-20 sm:h-16">
        <div className=" col-span-2 sm:col-span-3  flex flex-col justify-center   min-[900px]:pl-8 border-b border-grey ">
          <div className=" text-sm min-[900px]:text-base  pl-2 uppercase font-semibold mt-2 mb-2 sm:mt-0 sm:mb-0 ">
            <p>{dispApplications?.customer?.customerName}</p>
          </div>
        </div>
        {/* Delete button */}
        <div className="  flex items-center justify-center border-b border-grey">
          <button
            onClick={terminateAplication}
            className=" float-right items-center text-xs sm:text-sm flex flex-row p-1 sm:p-2 rounded-xl border border-tomat-500 bg-tomat-500 bg-opacity-20 hover:bg-opacity-40 active:bg-opacity-60 focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <XButtonIcon />
            Ja, avslutt
          </button>
        </div>
        {/* X button */}
        <div className=" col-span-1  min-[900px]:col-span-2 flex items-center justify-center border-b border-grey ">
          <button
            onClick={() => setSelectedId("")}
            className="border-2 border-grey hover:border-black active:bg-disabled rounded-lg p-1 focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
          >
            <XButtonIcon />
          </button>
        </div>
      </div>
    </>
  )
}

export default TerminateApplication
