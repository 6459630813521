import { useContext, useState } from "react"
import { UserContext } from "../../ProductionPlan/Components/UserContext"
import MyCensuesProduct from "./MyCensusesProduct"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Common/WebApi-utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"
import NotificationAlert from "../../../Graphics/NotificationAlert"

const MyCensuesPhase = ({ MyCensuses }) => {
  const [activeAccount, activeVendor, loadingDone, setLoadingDone, setRefresh] = useContext(UserContext)
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [today, setToday] = useState(new Date(""))
  const [year, setYear] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const getSettingKeyDates = useQuery(
    "settingKeyDates",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/SettingKeys", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile?.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setToday(new Date(userProfile?.data?.today))
        setYear(userProfile?.data?.today.slice(0, 4))
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const censusPeriodArea = getSettingKeyDates?.data?.find((x) => x.key === "TellingPeriodeAreal")
  const censusPeriodNov = getSettingKeyDates?.data?.find((x) => x.key === "TellingPeriodeNov")
  const censusPeriodJan = getSettingKeyDates?.data?.find((x) => x.key === "TellingPeriodeJan")
  const censusPeriodMar = getSettingKeyDates?.data?.find((x) => x.key === "TellingPeriodeMar")
  const censusPeriodApr = getSettingKeyDates?.data?.find((x) => x.key === "TellingPeriodeApr")

  return (
    <div className=" space-y-4 ">
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      {/* Area */}
      <MyCensuesProduct
        MyCensuses={MyCensuses}
        type={"Area"}
        date={"06-20"}
        dateText={"20. juni (arealregistrering)"}
        censusType={292460001}
        addNewText={"Legg til nytt areal"}
        settingKey={censusPeriodArea}
        today={today}
        year={parseInt(year)}
      />
      {/* Stock start */}
      <MyCensuesProduct
        MyCensuses={MyCensuses}
        type={"Stock"}
        date={"11-01"}
        dateText={"1. november"}
        censusType={292460000}
        addNewText={"Legg til ny telling"}
        settingKey={censusPeriodNov}
        today={today}
        year={parseInt(year)}
      />
      <MyCensuesProduct
        MyCensuses={MyCensuses}
        type={"Stock"}
        date={"01-01"}
        dateText={"1. januar"}
        censusType={292460000}
        addNewText={"Legg til ny telling"}
        settingKey={censusPeriodJan}
        today={today}
        year={parseInt(year)}
      />
      <MyCensuesProduct
        MyCensuses={MyCensuses}
        type={"Stock"}
        date={"03-01"}
        dateText={"1. mars"}
        censusType={292460000}
        addNewText={"Legg til ny telling"}
        settingKey={censusPeriodMar}
        today={today}
        year={parseInt(year)}
      />
      <MyCensuesProduct
        MyCensuses={MyCensuses}
        type={"Stock"}
        date={"04-15"}
        dateText={"15. april"}
        censusType={292460000}
        addNewText={"Legg til ny telling"}
        settingKey={censusPeriodApr}
        today={today}
        year={parseInt(year)}
      />
      {/* Stock end */}
    </div>
  )
}

export default MyCensuesPhase
