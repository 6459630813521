import { QueryClient } from "react-query"
import { useContext, useEffect, useState } from "react"
import { ArrowDown, ArrowUp } from "../../../Graphics/IconsMyPage"
import { UserContext } from "../../ProductionPlan/Components/UserContext"
import RegisterCensus from "./RegisterCensus"
import AddNewButton from "./AddNewButton"
import NewCensus from "./NewCensus"
import moment from "moment"
import "moment/locale/nb"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"

const queryClient = new QueryClient()

const MyCensuesProduct = ({ MyCensuses, dateText, date, type, censusType, addNewText, settingKey, today, year }) => {
  moment.locale("nb")
  const [activeAccount, activeVendor, loadingDone, setLoadingDone, setRefresh] = useContext(UserContext)
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [open, setOpen] = useState(false)
  const [addNewCensus, setAddNewCensus] = useState(false)
  const [fromDate, setFromDate] = useState(new Date(""))
  const [toDate, setToDate] = useState(new Date(""))

  // Set open to false whenever the user chanages the activeVendor. This is needed to close the open componenet because if the api does not have for example a value on quantity, it will keep the previous value in the new input feild without re rendering.
  useEffect(() => {
    setOpen(false)
  }, [activeVendor])

  // --------------------------
  // This useEffect gives a fromDate and toDate value.
  // If it is between the months of nov -> feb and the census time for Jan 1 the year value changes.
  // For example if it is desember 25 2023 today, the fromDate use this years year value and toDate gets a +1 on year. Open between desmber 5 2023 -> january 5 2024.
  // if it is january 1 2024, the fromDate use lasts years year value. So year -1.
  // All the other "TellingPeriode" are not depedent on the year value to be dynamic and change.
  // --------------------------
  useEffect(() => {
    if (settingKey?.key === "TellingPeriodeJan") {
      /* Month is november and december */
      if (today.getMonth() >= 10) {
        setFromDate(new Date(settingKey?.value.slice(3, 5) + "/" + settingKey?.value.slice(0, 2) + "/" + year))
        setToDate(new Date(settingKey?.value.slice(9, 11) + "/" + settingKey?.value.slice(6, 8) + "/" + (year + 1)))
      } else if (today.getMonth() <= 2) {
        /* Month is januray or february */
        setFromDate(new Date(settingKey?.value.slice(3, 5) + "/" + settingKey?.value.slice(0, 2) + "/" + (year - 1)))
        setToDate(new Date(settingKey?.value.slice(9, 11) + "/" + settingKey?.value.slice(6, 8) + "/" + year))
      } else {
        setFromDate(new Date(settingKey?.value.slice(3, 5) + "/" + settingKey?.value.slice(0, 2) + "/" + year))
        setToDate(new Date(settingKey?.value.slice(9, 11) + "/" + settingKey?.value.slice(6, 8) + "/" + (year + 1)))
      }
    } else {
      /* Does not need to check if it goes between 2 different years */
      setFromDate(new Date(settingKey?.value.slice(3, 5) + "/" + settingKey?.value.slice(0, 2) + "/" + year))
      setToDate(new Date(settingKey?.value.slice(9, 11) + "/" + settingKey?.value.slice(6, 8) + "/" + year))
    }
  }, [year, settingKey])

  // Return contains an empty div if loadingDone is false to reset rendered input fields and states in RegisterCensus.
  return (
    <>
      {loadingDone ? (
        <div className=" max-w-116 ">
          {/* Green table head button */}
          <button
            className=" w-full "
            disabled={!(today >= fromDate && today <= toDate)}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <div className=" bg-ghGreen-100 px-4 h-16 text-ghGreen-600 grid grid-cols-3 items-center ">
              <p className=" text-sm sm:text-base font-medium flex justify-start  ">{dateText}</p>
              <div>
                {open ? null : (
                  <p className=" text-sm "> {!(today >= fromDate && today <= toDate) ? "Stengt" : "Åpen"} </p>
                )}
              </div>
              <div className=" flex justify-end ">{open ? <ArrowDown /> : <ArrowUp />}</div>
            </div>
          </button>
          {open === true ? (
            <div>
              {MyCensuses?.data?.map((CensusProduct, i) => (
                <div key={i}>
                  {CensusProduct?.censusDate.slice(5, 10) === date ? (
                    <div key={i}>
                      {CensusProduct?.censuses?.map((Censuses, i) => {
                        return (
                          <div key={i}>
                            {Censuses?.censusType === censusType ? (
                              <div>
                                <div className=" mt-2 px-4 h-16 border-grey border-b-2 bg-blue bg-opacity-20 flex items-center ">
                                  <p className=" text-base font-semibold uppercase ">
                                    {Censuses?.planProduct?.planProductName}
                                  </p>
                                </div>
                                <RegisterCensus Censuses={Censuses} i={i} today={today} />
                              </div>
                            ) : null}
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ))}
              <div>
                {addNewCensus ? (
                  <div>
                    <NewCensus cancelProduct={setAddNewCensus} type={type} date={date} today={today} />
                  </div>
                ) : null}
                <div className=" flex justify-end mt-8 mb-12 ">
                  {addNewCensus ? (
                    <AddNewButton onClick={setAddNewCensus} disabled={true} buttonText={addNewText} />
                  ) : (
                    <AddNewButton onClick={setAddNewCensus} disabled={false} buttonText={addNewText} />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default MyCensuesProduct
