import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi } from "../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import { NumericFormat } from "react-number-format"
import { productUnit } from "../ProductionPlan/Components/Maps"
import { StringParam, useQueryParam } from "use-query-params"
import moment from "moment"
import "moment/locale/nb"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const LeaveApplicationContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [applicationId, setApplicationId] = useQueryParam("id", StringParam)
  const [loadingDone, setLoadingDone] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  moment.locale("nb")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "PermisjonSøknadIngress")

  const getMyApplication = useQuery(
    "myApplicationData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Application?applicationID=" + applicationId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      enabled: userProfile.isSuccess,
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
      },
    }
  )

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row font-sans mt-6 sm:mt-0 ">
            <Link to="/minside/oversikt-permisjon" className=" flex flex-row ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              <p className="underline underline-offset-2 decoration-tomat-500">Tilbake til permisjoner</p>
            </Link>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Søknad om permisjon</h1>
            <p>{infoText?.value}</p>
          </div>
          <div>
            {/* Vendor */}
            <div className=" mb-8 flex flex-col">
              <div>
                <p className=" text-base mb-4 font-semibold ">Hvem søker du permisjon for?</p>
              </div>
              <p>{getMyApplication?.data?.vendor?.vendorName}</p>
            </div>
            {/* Product filter */}
            <div className=" mb-8 flex flex-col max-w-63">
              <p className=" font-semibold mb-2">Hvilken vare skal permisjonen gjelde for?</p>
              <p>{getMyApplication?.data?.planProduct?.planProductName}</p>
            </div>
            {/* Amount */}
            <div className=" mb-8 flex flex-col">
              <p className=" font-semibold mb-2">Hvilken mengde skal leveres?</p>
              <div className=" flex flex-row items-center ">
                <NumericFormat
                  value={getMyApplication?.data?.quantity}
                  valueIsNumericString
                  type="tel"
                  displayType="text"
                  thousandSeparator=" "
                />
                <p className=" ml-2 "> {productUnit.get(getMyApplication?.data?.planProduct?.planProductUnit)} </p>
              </div>
            </div>
            {/* From date */}
            <div className=" mb-8 flex flex-col">
              <p className=" font-semibold mb-2">Hvilken dato skal permisjonen gjelde fra?</p>
              <p>{moment(getMyApplication?.data?.dateFrom).format("L")}</p>
            </div>
            {/* To date */}
            <div className=" mb-8 flex flex-col">
              <p className=" font-semibold mb-2">Hvilken dato skal permisjonen gjelde til?</p>
              <p>{moment(getMyApplication?.data?.dateTo).format("L")}</p>
            </div>
            {/* Comment from user */}
            <div className=" mb-8 flex flex-col">
              <p className=" font-semibold mb-2">Kort begrunnelse for hvorfor det søkes permisjon</p>
              <p className="max-w-63 p-2 pb-4 border-2 border-grey rounded-2xl h-44  ">
                {getMyApplication?.data?.producerComment}
              </p>
            </div>
            {/* Comment from GH */}
            <div className=" mb-8 flex flex-col">
              <p className=" font-semibold mb-2">Begrunnelse fra produksjonsplanlegger</p>
              <p className="max-w-63 p-2 pb-4 border-2 border-grey rounded-2xl h-44 ">
                {getMyApplication?.data?.reasonFromProductionPlanner}
              </p>
            </div>
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function LeaveApplication() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <LeaveApplicationContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default LeaveApplication
