import { useAccount, useMsal } from "@azure/msal-react"
import { Dialog, Transition } from "@headlessui/react"
import { navigate } from "gatsby"
import { Fragment, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { fetchApi, patchApi } from "../../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../Components/ReactQueries"
import { greenSaveButton } from "../../../Styling/ButtonStyles"
import { PlanLoading } from "../Components/PlanLoading"

export type Confirm = {
  Id: string
  ConditionConfirmed: string
  ConditionConfirmedBy: string
}

const GhConditionModal = ({ accountId, conditionConfirmed, setRefresh }) => {
  const [open, setOpen] = useState(!conditionConfirmed)
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const date = new Date()
  const [loadingDone, setLoadingDone] = useState(true)
  const [error, setError] = useState(false)

  const getUrls = useQuery(
    "settingsUrls",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/SettingKeys", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile.isSuccess,
      onSuccess: (data) => {
        setError(false)
      },
      onError: (_error) => {
        setError(true)
      },
    }
  )

  const text1 = getUrls?.data?.find((x) => x.key === "GodkjennRegelverkTekst1")
  const text2 = getUrls?.data?.find((x) => x.key === "GodkjennRegelverkTekst2")

  const PatchAccount = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/Accounts", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setError(true)
        setLoadingDone(true)
      },
      onSuccess: () => {
        setOpen(false)
        setLoadingDone(true)
        setRefresh(true)
      },
    }
  )

  const confirmCondtion = () => {
    const confirmObject: Confirm = {
      Id: accountId,
      ConditionConfirmed: date.toISOString(),
      ConditionConfirmedBy: userProfile?.data?.id,
    }
    setLoadingDone(false)
    PatchAccount.mutate(confirmObject)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto p-18 font-sans "
        onClose={() => {
          setOpen(true)
        }}
      >
        {loadingDone ? null : <PlanLoading />}
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center backdrop-blur sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {error ? (
              <div className=" font-sans inline-block align-bottom bg-white border-ghGreen-400 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title as="h3" className="text-base font-medium ">
                      Det har skjedd en feil, prøv igjen senere
                    </Dialog.Title>
                  </div>
                  <div className="flex justify-evenly mt-12">
                    <div>
                      <button
                        className=" py-1 px-2 border-2 border-black rounded-lg hover:text-grey "
                        onClick={() => navigate("/minside/")}
                      >
                        Gå tilbake
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" font-sans inline-block align-bottom bg-white border-ghGreen-400 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="text-left">
                    <Dialog.Title as="h3" className="text-base ">
                      <p>{text1?.value}</p>
                    </Dialog.Title>
                  </div>
                  <div className=" flex flex-col mt-4 ">
                    <div className=" flex flex-col space-y-2 ">
                      <p className=" text-sm ">Lenker:</p>
                      {getUrls?.data?.map((Urls, i) => (
                        <>
                          {Urls?.key?.includes("GodkjennRegelverkURL") ? (
                            <a
                              key={i}
                              href={Urls?.value}
                              target="_blank"
                              rel="noopener noreferrer"
                              className=" text-sm underline underline-offset-1 hover:text-grey "
                            >
                              {Urls?.data}
                            </a>
                          ) : null}
                        </>
                      ))}
                    </div>
                    <div className="mt-8">{<p className=" text-sm ">{text2?.value}</p>}</div>
                  </div>
                  <div className="flex justify-evenly mt-12">
                    <div>
                      <button className={greenSaveButton} onClick={confirmCondtion}>
                        Bekreft
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GhConditionModal
