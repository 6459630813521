import { useState, useEffect } from "react"
import { models, Report, Embed, service, Page } from "powerbi-client"
import { PowerBIEmbed } from "powerbi-client-react"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import DebugLogger from "../../Common/Debug"
import { fetchApi } from "../../Common/WebApi-utils"
import "../../Styling/report-css.css"
import { useQuery, useQueryClient } from "react-query"
import useWindowSize from "../../CustomHooks/useWindowSize"
import LoadingData from "../../Graphics/LoadingData"
import Notification from "../../Graphics/Notification"
import ReportIngress from "../../../pages/reportingress"

// Vise hver enkelt rapport fra powerbi

const ReportsContent = () => {
  const queryClient = useQueryClient()
  const [report, setReport] = useState<Report>()
  const [accessToken, setAccessToken] = useState()
  const [reportUrl, setReportUrl] = useState()
  const [reportName, setReportName] = useState()
  const [smallScreen, setSmallScreen] = useState(true)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const windowsize = useWindowSize()

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  useEffect(() => {
    if (windowsize.width > 1024) {
      setSmallScreen(false)
    }
  }, [windowsize])

  const userPowerBiQuery = useQuery(
    "userReportData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PowerBi?reportId=04506d21-caef-4069-b9e6-0f35c0c926db",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setAccessToken(data.embedToken.token)
        setReportUrl(data.embedReports[0].embedUrl)
        setReportName(data.embedReports[0].reportName)
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )

  // Forventer at mobiltilpasset rapport er laget i PowerBI. Ikke avgjort om GH lager dette pt, så kode ikke konkludert.

  return userPowerBiQuery.isLoading ? (
    // "Laster inn..."
    <LoadingData />
  ) : userPowerBiQuery.isError ? (
    userPowerBiQuery.error.message
  ) : (
    <>
      <MyPageLayout title="">
        <div>{notification.showNotification && <Notification {...notification} />}</div>
        <div className=" mb-20 ">
          {/* <p className=" max-w-116 mb-8 text-body18 leading-none text-center sm:text-left text-gray-500">
          <ReportIngress />
        </p>
        <h3 className="mb-6">{reportName}</h3> */}
          {smallScreen ? (
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: "04506d21-caef-4069-b9e6-0f35c0c926db", // reportid fra powerbi
                embedUrl: reportUrl,
                viewMode: models.ViewMode.View,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                permissions: models.Permissions.All,
                settings: {
                  filterPaneEnabled: false,
                  navContentPaneEnabled: true,
                  background: models.BackgroundType.Transparent,
                  layoutType: models.LayoutType.MobilePortrait,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                      DebugLogger("Report", "Report loaded")
                    },
                  ],
                  [
                    "rendered",
                    function () {
                      DebugLogger("Report", "Report rendered")
                    },
                  ],
                  [
                    "error",
                    function (event) {
                      DebugLogger("Report", event.detail)
                    },
                  ],
                ])
              }
              cssClassName={"embed-container"}
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report)
              }}
            />
          ) : (
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                // id: "87f5a3a5-44cc-4835-9c23-319330bae108",
                id: "04506d21-caef-4069-b9e6-0f35c0c926db", // reportid
                embedUrl: reportUrl,
                viewMode: models.ViewMode.View,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                permissions: models.Permissions.All,
                settings: {
                  filterPaneEnabled: false,
                  navContentPaneEnabled: true,
                  background: models.BackgroundType.Transparent,
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                      DebugLogger("Report", "Report loaded")
                    },
                  ],
                  [
                    "rendered",
                    function () {
                      DebugLogger("Report", "Report rendered")
                    },
                  ],
                  [
                    "error",
                    function (event) {
                      DebugLogger("Report", event.detail)
                    },
                  ],
                ])
              }
              cssClassName={"embed-container"}
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report)
              }}
            />
          )}
        </div>
      </MyPageLayout>
    </>
  )
}

function Reports() {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ReportsContent />
    </MsalAuthenticationTemplate>
  )
}

export default Reports
