/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { Transition } from "@headlessui/react"
import { CheckCircleIcon } from "@heroicons/react/outline"
import { XButtonIcon } from "./IconsMyPage"

const NotificationAlert = ({ alert, setAlert, type, message, underlineMessage }) => {
  setTimeout(() => {
    setAlert(false)
  }, 10000)

  return (
    <>
      {alert ? (
        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
        >
          <div className="w-full flex flex-col items-center space-y-4 ">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={
                  type === "success"
                    ? "max-w-sm w-full bg-ghGreen-100 shadow-md  pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                    : "max-w-sm w-full bg-orange-50 shadow-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                }
              >
                <div
                  className={
                    type === "success" ? " border-l-4 border-l-ghGreen-500 p-4 " : " border-l-4 border-avvist p-4 "
                  }
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {type === "success" ? (
                        <CheckCircleIcon className="h-6 w-6  text-brokoli-500 " aria-hidden="true" />
                      ) : null}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-left">
                        {message}
                        <span className=" underline underline-offset-2 ">{underlineMessage}</span>
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tomat-500"
                        onClick={() => setAlert(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XButtonIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      ) : null}
    </>
  )
}
export default NotificationAlert
