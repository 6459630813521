import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useEffect, useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import { fetchApi } from "../../Common/WebApi-utils"
import VendorComponent from "../Applications/Components/VendorComponent"
import { UserContext } from "../ProductionPlan/Components/UserContext"
import MyCensuesPhase from "./Components/MyCensuesPhase"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const MyCensusesContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const [refresh, setRefresh] = useState(false)
  const [loadingDone, setLoadingDone] = useState(true)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "TellingIngress")

  const getMyCensusVendor = useQuery(
    "myCensusVendorData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors?type=census",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userProfile.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )
  const [activeVendor, setActiveVendor] = useState(getMyCensusVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyCensusVendor?.data?.[0]?.accountId)

  const getMyCensuses = useQuery(
    "myCensusesData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Census/MyCensuses?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor +
          "&censusStatuses=" +
          "readyToSend,sentIn",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: getMyCensusVendor.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setRefresh(false)
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil med tellingene. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  useEffect(() => {
    if (refresh === true) {
      getMyCensuses.refetch()
      setLoadingDone(false)
    }
  }, [refresh])

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row mt-8 md:mt-0">
            <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
              Min side
            </Link>
            <p className=" font-semibold"> / Telling / Registrere telling</p>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Registrere telling</h1>
            <p>{infoText?.value}</p>
          </div>
          {/* Dispensasjon or Permisjon filter buttons */}
          <div>
            <VendorComponent
              getMyVendor={getMyCensusVendor}
              activeVendor={activeVendor}
              setActiveVendor={setActiveVendor}
              setActiveAccount={setActiveAccount}
              refresh={setRefresh}
            />
          </div>
          <UserContext.Provider value={[activeAccount, activeVendor, loadingDone, setLoadingDone, setRefresh]}>
            <div className="flex flex-col">
              <MyCensuesPhase MyCensuses={getMyCensuses} />
            </div>
          </UserContext.Provider>
        </div>
      </MyPageLayout>
    </>
  )
}

function MyCensuses() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <MyCensusesContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default MyCensuses
