import { NumericFormat } from "react-number-format"
import { productUnit } from "../../ProductionPlan/Components/Maps"
import { Arrow, PenIcon, XButtonIcon } from "../../../Graphics/IconsMyPage"
import BamaPlansTableLines from "./BamaPlansTableLines"
import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../../ProductionPlan/Components/UserContext"

const BamaPlansTableContent = ({ planProduct, weekNumber, activeVendor, setValues }) => {
  const [registeredArray, setRegisteredArray, setShowSaveButton] = useContext(UserContext)
  const [amount, setAmount] = useState<number>(0)
  const [amountChanged, setAmountChanged] = useState(false)
  const [manualRegistration, setManualRegistration] = useState(false)
  const [manualAmount, setManualAmount] = useState<number>(0)

  useEffect(() => {
    const total =
      planProduct?.prodPlan
        ?.map((prodPlan) => (prodPlan?.planWeeks?.[0]?.registered === -1 ? 0 : prodPlan?.planWeeks?.[0]?.registered))
        ?.reduce((acc, cur) => acc + cur, 0) || 0
    setAmount(total)
    setAmountChanged(false)
  }, [weekNumber, planProduct, activeVendor, manualRegistration])

  useEffect(() => {
    const manualTotal =
      registeredArray
        ?.filter((regArray) => regArray.planProductId === planProduct?.id)
        .map((regArray) => (regArray?.amount === -1 ? 0 : regArray?.amount))
        .reduce((acc, cur) => acc + cur, 0) || 0
    setManualAmount(manualTotal)
  }, [registeredArray, manualRegistration])

  useEffect(() => {
    setManualRegistration(false)
  }, [weekNumber, planProduct, activeVendor])

  return (
    //Add weekNumber to key to render new table each time week is changed
    <React.Fragment key={planProduct.id + weekNumber + activeVendor}>
      {/* white space divider */}
      {/* <div className="bg-white h-4"></div> */}
      <tr className=" bg-blue bg-opacity-20 text-xs sm:text-base whitespace-nowrap">
        <th className=" pl-1 sm:pl-5 py-4 text-left font-semibold border-b border-grey uppercase">
          {planProduct?.planProductName}
        </th>
        {/* This year */}
        <td className="  text-center border-b border-grey ">
          <NumericFormat
            displayType="text"
            value={planProduct?.prodPlan
              ?.map((prodPlan) => prodPlan?.planWeeks?.[0]?.planWeekQuantity)
              ?.reduce((acc, cur) => acc + cur)}
            thousandSeparator={" "}
          />{" "}
          {productUnit.get(planProduct?.prodPlan[0]?.planProduct?.planProductUnit)}
        </td>
        {/* Registered */}
        <td className="  text-center border-b border-grey ">
          {manualRegistration ? (
            <NumericFormat
              className=" text-center text-xs h-8 w-14 sm:text-base sm:w-28 mr-2"
              displayType="text"
              value={manualAmount}
              thousandSeparator={" "}
            />
          ) : (
            <NumericFormat
              className=" text-center text-xs h-8 w-14 sm:text-base sm:w-28 mr-2"
              displayType="input"
              value={amount}
              thousandSeparator={" "}
              onValueChange={(e) => {
                setAmount(e.floatValue)
                setAmountChanged(true)
                setShowSaveButton(true)
              }}
            />
          )}
          {productUnit.get(planProduct?.prodPlan[0]?.planProduct?.planProductUnit)}
        </td>
        <td className="text-center border-b border-grey">
          <button
            className={
              manualRegistration
                ? " mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-black bg-tomat-500 bg-opacity-20 hover:bg-opacity-40 active:bg-opacity-60 focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
                : " mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
            }
            onClick={() => setManualRegistration(!manualRegistration)}
          >
            {manualRegistration ? <XButtonIcon /> : <Arrow />}
          </button>
        </td>
        {/* Change capacity */}
        <td className="table-cell bg-white border-none">
          <button
            onClick={() => {
              setValues.setShowModal(true)
              setValues.setPlanProductName(planProduct?.planProductName)
              setValues.setPlanProductId(planProduct?.id)
            }}
            className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <PenIcon />
            <span className=" hidden lg:flex sm:ml-1 ">Rediger kapasitet</span>
          </button>
        </td>
      </tr>
      {planProduct?.prodPlan?.map((prodPlan) => {
        //Get the total quantity of the plan to calculate the percentage and use on registered plans
        const totalQuantity = planProduct?.prodPlan
          ?.map((prodPlan) => prodPlan?.planWeeks?.[0]?.planWeekQuantity)
          ?.reduce((acc, cur) => acc + cur)
        return (
          <BamaPlansTableLines
            prodPlan={prodPlan}
            percentage={prodPlan?.planWeeks?.[0]?.planWeekQuantity / totalQuantity}
            amount={amount}
            amountChanged={amountChanged}
            weekNumber={weekNumber}
            manualRegistration={manualRegistration}
          />
        )
      })}
    </React.Fragment>
  )
}

export default BamaPlansTableContent
