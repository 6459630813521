import { useState } from "react"

const ApplicationProductFilter = ({ prop, setId, filterValue, setFilterValue, placeholder, setUnit }) => {
  const [isFocus, setIsFocus] = useState(false)
  const handleFilter = (event) => {
    setFilterValue(event.target.value)
    setId("")
    setUnit(0)
  }

  return (
    <>
      <input
        onChange={handleFilter}
        onFocus={() => setIsFocus(true)}
        onBlur={() => filterValue !== ""}
        value={filterValue}
        required
        type="text"
        placeholder={placeholder}
        className={
          isFocus && filterValue.length > 2
            ? " rounded-t-lg text-base placeholder:text-black font-sans font-normal py-2 px-4 border border-grey w-full h-12 focus:ring-0 focus:ring-grey focus:border-grey  "
            : " rounded-lg text-base placeholder:text-black font-sans font-normal py-2 :px-4 border border-grey w-full h-12 focus:ring-0 focus:ring-grey focus:border-grey  "
        }
      ></input>
      {filterValue.length > 2 ? (
        <>
          {isFocus ? (
            <ul className=" w-full absolute shadow-lg shadow-grey bg-white border border-grey max-h-48 h-auto overflow-auto font-sans font-normal text-base ">
              {prop?.data?.map((product, key) => (
                <button
                  onClick={() => {
                    console.log(product?.id)
                    setFilterValue(product?.planProductName)
                    setId(product?.id)
                    setIsFocus(false)
                    setUnit(product?.planProductUnit)
                  }}
                  className=" hover:bg-grey w-full text-left"
                >
                  <li className=" py-2 px-4 " id={key}>
                    <p>{product?.planProductName}</p>
                  </li>
                </button>
              ))}
            </ul>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default ApplicationProductFilter
