import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { loginRequest } from "./authConfig"
import { ErrorComponent } from "./ErrorComponent"
import { Loading } from "./Loading"

/**
 * Denne kompoenten logger brukeren ut. Enkelt og greit
 */
const LogoutContent = () => {
  const { instance } = useMsal()
  instance.logoutRedirect(loginRequest)

  return <></>
}

function Logout() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <LogoutContent />
    </MsalAuthenticationTemplate>
  )
}

export default Logout
