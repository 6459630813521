import { useAccount, useMsal } from "@azure/msal-react"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, patchApi, postApi } from "../../../../Common/WebApi-utils"
import { year } from "../Maps"
import { GetProfile } from "../ReactQueries"
import { UserContext } from "../UserContext"
import CustomerFilter from "./CustomerFilter"
import { XButtonIcon } from "../../../../Graphics/IconsMyPage"
import NotificationAlert from "../../../../Graphics/NotificationAlert"

// Add a new customer by adding search values in an input. This component is made up of a search bar, "Legg til" button and cancel button

export type newCustomerPost = {
  Producer: string
  Vendor: string
  Customer: string
  PlanProduct: string
  ProdPlanYearOption: number | undefined
  ProdPlanType: number
  ProdPlanStatus: number
}

export type newCustomerPatch = {
  Id: string
  Customer: string
}

const AddNewCustomerComponent = ({ cancelCustomer, planProductId, prop, toWeek }) => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const account = useAccount(accounts[0] || {})
  const [id, setId] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount] = useContext(UserContext)
  const [yearOption, setYearOption] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString()

  // Check the toWeek value, if it is bigger than 100 wish will last in to the next year
  useEffect(() => {
    if (parseInt(toWeek) > 100) {
      setYearOption(thisYearString + "-" + nextYearString)
    } else {
      setYearOption(thisYearString)
    }
  }, [toWeek])

  const getMyPlans = useQuery(
    "myPlansData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/ProductionPlan/MyWhishes?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setLoadingDone(true)
      },
    }
  )

  const getCustomers = useQuery(
    "CustomerData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Customer?searchParam=" + filterValue + "&customerType=prodPlanAvailable",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const AddCustomerPost = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries("myPlansData")
        cancelCustomer(false)
      },
    }
  )

  const AddCustomerPatch = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries("myPlansData")
        cancelCustomer(false)
      },
    }
  )

  const onSubmitCustomer = () => {
    // If a new product was added and it is an empty row without a customer. Add the values with a PATCH with the selected customer.
    if (prop.prodPlan[0].customer === undefined && prop.prodPlan.length === 1) {
      const CustomerObjectPatch: newCustomerPatch = {
        Id: prop.prodPlan[0].id,
        Customer: id,
      }
      setLoadingDone(false)
      AddCustomerPatch.mutate(CustomerObjectPatch)
    }
    // If the product have customers from before and you want to add a new one. Do a POST
    else {
      const CustomerObjectPost: newCustomerPost = {
        Producer: activeAccount,
        Vendor: activeVendor,
        Customer: id,
        PlanProduct: planProductId,
        ProdPlanYearOption: year.get(yearOption),
        ProdPlanType: 292460000,
        ProdPlanStatus: 292460000,
      }
      setLoadingDone(false)
      AddCustomerPost.mutate(CustomerObjectPost)
    }
  }

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (filterValue.length) {
      case 0:
        getCustomers.refetch()
        break
      case 2:
        getCustomers.refetch()
        break
      case 4:
        getCustomers.refetch()
        break
      case 6:
        getCustomers.refetch()
        break
      case 8:
        getCustomers.refetch()
        break
      case 10:
        getCustomers.refetch()
        break
      case 12:
        getCustomers.refetch()
        break
    }
  }, [filterValue])

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <tr className="">
        {/* Render this customer filter colSpan if smaller than sm */}
        <th colSpan={2} className=" pl-2 sm:pl-8 sm:hidden py-4 border-b border-grey relative font-sans font-normal">
          <div className=" relative ">
            <CustomerFilter
              prop={getCustomers}
              setId={setId}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              placeholder={"Skriv for å søke etter kunde/pakkeri"}
            />
          </div>
        </th>
        {/* Render this customer filter colSpan if bigger than sm */}
        <th
          colSpan={3}
          className=" hidden sm:table-cell pl-2 sm:pl-8 py-4 border-b border-grey relative font-sans font-normal"
        >
          <div className=" relative ">
            <CustomerFilter
              prop={getCustomers}
              setId={setId}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              placeholder={"Skriv for å søke etter kunde/pakkeri"}
            />
          </div>
        </th>
        {/* The "Legg til" button is only active if a value with an Id is selected */}
        <td className=" border-b border-grey ">
          {id === "" ? (
            <button
              disabled
              className="  h-8 sm:h-12 float-right text-white bg-disabled border border-disabled rounded-lg  items-center text-xs sm:text-sm flex flex-row py-2 px-1"
            >
              Legg til
            </button>
          ) : (
            <button
              onClick={onSubmitCustomer}
              className=" h-8 sm:h-12 float-right items-center text-xs sm:text-sm flex flex-row rounded-lg py-2 px-1 border border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
            >
              Legg til
            </button>
          )}
        </td>
        {/* The cancel button */}
        <td className=" pr-2 border-b border-grey text-right  ">
          <button
            onClick={() => {
              cancelCustomer(null)
            }}
            className="border-2 border-grey rounded-lg p-1 hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
          >
            <XButtonIcon />
          </button>
        </td>
        <td className=" border-none "></td>
      </tr>
    </>
  )
}

export default AddNewCustomerComponent
