import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import moment from "moment"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { fetchApi, postApi } from "../../Common/WebApi-utils"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import "moment/locale/nb"
import { Link } from "@reach/router"
import { GetProfile } from "../ProductionPlan/Components/ReactQueries"
import { UserContext } from "../ProductionPlan/Components/UserContext"
import { Loading } from "../../Security/Loading"
import VendorComponent from "../Applications/Components/VendorComponent"
import BamaPlansTable from "./Components/BamaPlansTable"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import WeekPicker from "./Components/WeekPicker"
import CapacityModal from "./CapacityModal"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { greenSaveButton } from "../../Styling/ButtonStyles"
import { productUnit } from "../ProductionPlan/Components/Maps"

// Function to get the current week number
function getISOWeek(currentDate) {
  const date = new Date(currentDate)
  // const date = new Date("2024/12/15")
  date.setHours(0, 0, 0, 0)
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  const week1 = new Date(date.getFullYear(), 0, 4)
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7)
}

// Function to determine if a year has 53 weeks
function has53Weeks(year) {
  const firstDayOfYear = new Date(year, 0, 1).getDay()
  const isLeapYear = new Date(year, 1, 29).getMonth() === 1

  // If the year starts on a Thursday or if it's a leap year that starts on a Wednesday
  return firstDayOfYear === 4 || (isLeapYear && firstDayOfYear === 3)
}

type RegisteredObjectsType = [
  {
    prodPlanId: string
    weekNumber: number
    amount: number
    accountId: string
    vendorId: string
    customerId: string
    planProductId: string
    prodPlanYearOption: number
    planProductNumber: number
    planProductName: string
    customerGLN: string
    customerName: string
    vendorNumber: number
    vendorName: string
    unitCode: string
    plansForBama: boolean
    planWeekNo: number
  }
]

const BamaPlansContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [refresh, setRefresh] = useState(false)
  const [refreshVendor, setRefreshVendor] = useState(false)
  const [loadingDone, setLoadingDone] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [planProductName, setPlanProductName] = useState()
  const [planProductId, setPlanProductId] = useState()
  const [showModal, setShowModal] = useState(false)
  const [weekCounter, setWeekCounter] = useState(0)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [registeredArray, setRegisteredArray] = useState<RegisteredObjectsType | []>([])

  // const [currentDate, setCurrentDate] = useState(new Date("2024/12/15"))
  const [currentDate, setCurrentDate] = useState(new Date(userProfile?.data?.today))
  const [thisYear, setThisYear] = useState(new Date(userProfile?.data?.today).getFullYear())
  const [weekPickerYear, setWeekPickerYear] = useState(new Date(userProfile?.data?.today).getFullYear())
  const [weekNumber, setWeekNumber] = useState(getISOWeek(userProfile?.data?.today))
  const [thisYearHas53Weeks, setThisYearHas53Weeks] = useState(
    has53Weeks(new Date(userProfile?.data?.today).getFullYear())
  )

  moment.locale("nb")

  const getMyVendor = useQuery(
    "myVendorData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors?type=bama", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
        // setCurrentDate(new Date("2024/12/15"))
        setCurrentDate(new Date(userProfile?.data?.today))
        setThisYear(new Date(userProfile?.data?.today).getFullYear())
        setWeekPickerYear(new Date(userProfile?.data?.today).getFullYear())
        setWeekNumber(getISOWeek(new Date(userProfile?.data?.today)))
        setThisYearHas53Weeks(has53Weeks(new Date(userProfile?.data?.today).getFullYear()))
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const [activeVendor, setActiveVendor] = useState(getMyVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyVendor?.data?.[0]?.accountId)

  const getBamaPlans = useQuery(
    "myBamaPlansData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/ProductionPlan/MyPlansForBama?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor +
          "&weekNumber=" +
          weekNumber +
          "&year=" +
          weekPickerYear,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: getMyVendor.isSuccess,
      onSuccess: (data) => {
        setRefresh(false)
        setLoadingDone(true)
        const registeredObjects: RegisteredObjectsType = data.flatMap((item) => {
          return item.prodPlan.map((prodPlanItem) => {
            return {
              prodPlanId: prodPlanItem?.id,
              weekNumber: weekNumber,
              amount: prodPlanItem?.planWeeks?.[0]?.registered === -1 ? 0 : prodPlanItem?.planWeeks?.[0]?.registered,
              accountId: prodPlanItem?.account?.id,
              vendorId: activeVendor,
              customerId: prodPlanItem?.customer?.id,
              planProductId: prodPlanItem?.planProduct?.id,
              prodPlanYearOption: prodPlanItem?.prodPlanYearOption,
              planProductNumber: Number(prodPlanItem?.planProduct?.planProductNumber), // Convert to number
              planProductName: prodPlanItem?.planProduct?.planProductName,
              customerGLN: prodPlanItem?.customer?.customerGLN,
              customerName: prodPlanItem?.customer?.customerName,
              vendorNumber: Number(prodPlanItem?.vendor?.vendorNumber), // Convert to number
              vendorName: prodPlanItem?.vendor?.vendorName,
              unitCode: productUnit.get(prodPlanItem?.planProduct?.planProductUnit), // send the converted to string value
              plansForBama: prodPlanItem?.customer?.planForBama,
              planWeekNo: prodPlanItem?.planWeeks?.[0]?.planWeekNo,
            }
          })
        })
        setRegisteredArray(registeredObjects)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil, prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const postRegisterValues = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/Bama/MakeProposal", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (res) => {
        setAlert(true)
        setAlertType("success")
        setAlertMessage("Endringene er lagret")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
        setRefresh(true)
      },
      onError: (_error) => {
        setLoadingDone(true)
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil, prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const saveRegisteredData = (values) => {
    postRegisterValues.mutate(values)
  }

  useEffect(() => {
    if (refresh === true) {
      setLoadingDone(false)
      setRegisteredArray([])
      getBamaPlans.refetch()
    }
  }, [refresh])

  useEffect(() => {
    if (getMyVendor.isSuccess) {
      setRefresh(true)
    }
  }, [weekNumber])

  const incrementWeek = () => {
    setWeekNumber((prevWeek) => {
      if ((thisYearHas53Weeks && prevWeek === 53) || (!thisYearHas53Weeks && prevWeek === 52)) {
        setWeekPickerYear((prevYear) => prevYear + 1)
        setWeekCounter(weekCounter + 1)
        setShowSaveButton(false)
        return 1
      } else if (weekCounter < 40) {
        setWeekCounter(weekCounter + 1)
        setShowSaveButton(false)
        return prevWeek + 1
      } else {
        return prevWeek
      }
    })
  }

  const decrementWeek = () => {
    setWeekNumber((prevWeek) => {
      if (prevWeek === 1 && weekCounter > 0) {
        setWeekPickerYear((prevYear) => prevYear - 1)
        setWeekCounter(weekCounter - 1)
        setShowSaveButton(false)
        return thisYearHas53Weeks ? 53 : 52
      } else if (weekCounter > 0) {
        setWeekCounter(weekCounter - 1)
        setShowSaveButton(false)
        return prevWeek - 1
      } else {
        return prevWeek
      }
    })
  }

  return (
    <MyPageLayout title={null}>
      {loadingDone ? null : <PlanLoading />}
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      {showModal ? (
        <CapacityModal
          setShowModal={setShowModal}
          planProductName={planProductName}
          vendorId={activeVendor}
          planProductId={planProductId}
        />
      ) : null}
      <div className="mb-28">
        <div className="flex flex-row mb-8">
          <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
            Min side
          </Link>
          <p className=" font-semibold">/ Innmelding </p>
        </div>
        <div className=" flex flex-col ">
          <h1 className=" mb-10 text-3xl">Innmelding</h1>
          {/* <p>{infoText?.value}</p> */}
        </div>
        <div className=" mb-8 flex flex-col">
          {getMyVendor?.data?.length < 2 ? (
            <div>
              <h2 className=" text-xl sm:text-2xl my-6">
                Innmeldinger sesongen {thisYear}/{(thisYear + 1).toString().slice(2)} for{" "}
                {getMyVendor?.data?.[0]?.vendorName}
              </h2>
            </div>
          ) : (
            <>
              <div>
                <h2 className=" text-xl sm:text-2xl my-6">
                  Innmeldinger sesongen {thisYear}/{(thisYear + 1).toString().slice(2)} for
                </h2>
              </div>
              <VendorComponent
                getMyVendor={getMyVendor}
                activeVendor={activeVendor}
                setActiveVendor={setActiveVendor}
                setActiveAccount={setActiveAccount}
                refresh={setRefresh}
              />
            </>
          )}
        </div>
        <div>
          <WeekPicker
            weekNumber={weekNumber}
            year={weekPickerYear}
            decrementWeek={decrementWeek}
            incrementWeek={incrementWeek}
          />
        </div>
        <div className="flex flex-col font-sans">
          <UserContext.Provider value={[registeredArray, setRegisteredArray, setShowSaveButton]}>
            <BamaPlansTable
              plans={getBamaPlans}
              weekNumber={weekNumber}
              activeVendor={activeVendor}
              // setShowModal={setShowModal}
              // setPlanProductName={setPlanProductName}
              // setPlanProductId={setPlanProductId}
              setValues={{
                setShowModal,
                setPlanProductName,
                setPlanProductId,
              }}
            />
          </UserContext.Provider>
        </div>
        <div>
          {showSaveButton ? (
            <div className="mt-4 flex flex-row justify-center">
              <button className={greenSaveButton} onClick={() => saveRegisteredData(registeredArray)}>
                Lagre
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </MyPageLayout>
  )
}

function BamaPlans() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <BamaPlansContent />
    </MsalAuthenticationTemplate>
  )
}

export default BamaPlans
