import moment from "moment"
import { productUnit, year, yearString } from "../../ProductionPlan/Components/Maps"
import "moment/locale/nb"
import { NumericFormat } from "react-number-format"
import { useState } from "react"
import { useMutation } from "react-query"
import { patchApi, postApi } from "../../../Common/WebApi-utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"
import DeliveredButton from "../Buttons/DeliveredButton"
import ReportButton from "../Buttons/ReportButton"
import { useForm } from "react-hook-form"
import NotificationAlert from "../../../Graphics/NotificationAlert"

// Check if application?.deliveryReport?.status === 292460001. This means that the report is delivered, if not the user can make a delivery report.

export type patchReport = {
  Id: string
  Application: string
  Year: number
  ReportQuantity: number
  Comment: string
  Status: number
}

const DeliveryReportContent = ({ application, activeReport, setActiveReport, setLoadingDone, setRefresh }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [comment, setComment] = useState("")
  const [quantity, setQuantity] = useState(0)
  const [yearOption, setYearOption] = useState(parseInt(application?.deliveryReport?.deliveryReportDate) - 1)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const { handleSubmit } = useForm({
    mode: "onChange",
  })
  moment.locale("nb")

  const patchDeliveryReport = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/DeliveryReport", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        setRefresh(true)
        setActiveReport("")
      },
    }
  )

  const submitDeliveryReport = () => {
    const patchDeliveryReportObject: patchReport = {
      Id: application?.deliveryReport?.id,
      Application: application?.deliveryReport?.application?.id,
      Year: year.get(yearOption.toString()),
      ReportQuantity: parseInt(quantity),
      Comment: comment,
      Status: 292460001,
    }
    patchDeliveryReport.mutate(patchDeliveryReportObject)
    setLoadingDone(false)
  }

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <div className=" flex flex-col ">
        <div className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 h-20 ">
          <div className=" col-span-2  flex flex-col justify-center   min-[900px]:pl-8 ">
            <div className=" text-sm min-[900px]:text-base  pl-2 uppercase mt-2 mb-2 sm:mt-0 sm:mb-0  ">
              <p>{application?.customer?.customerName}</p>
            </div>
          </div>
          {/* Show amount if bigger than md */}
          <div className=" hidden min-[900px]:flex justify-end items-center  text-ghGreen-600 ">
            <p>
              <NumericFormat displayType="text" value={application?.quantity} thousandSeparator={" "} /> kg
            </p>
          </div>
          {/* Show date if bigger than sm */}
          <div className=" hidden sm:flex justify-end items-center text-ghGreen-600 ">
            <p>{moment(application?.dateFrom).format("L")}</p>
          </div>
          <div className={" col-span-2 flex justify-center items-center "}>
            <p className={" text-sm sm:text-base  px-1 rounded-lg bg-opacity-60 bg-godkjent"}>Godkjent</p>
          </div>
          {/* Edit button */}
          <>
            {application?.deliveryReport?.status === 292460001 ? (
              <DeliveredButton
                activeReport={activeReport}
                setActiveReport={setActiveReport}
                application={application}
              />
            ) : (
              <ReportButton activeReport={activeReport} setActiveReport={setActiveReport} application={application} />
            )}
          </>
        </div>
        {/* Show information if user press the "Rapportér" button above */}
        {activeReport === application?.id ? (
          <div>
            {application?.deliveryReport === undefined ? (
              <p className=" flex flex-col pl-2 sm:pl-10 mt-8 mb-8 ">
                Det har ikke blitt opprettet en rapport for denne perioden. Ta kontakt hvis du har spørsmål.
              </p>
            ) : (
              <form name="newApplicationDisp" method="post" onSubmit={handleSubmit(submitDeliveryReport)}>
                <div className=" flex flex-col pl-2 sm:pl-10 mt-8 mb-8 space-y-12 ">
                  {/* Year */}
                  <div className=" flex flex-col">
                    <p className=" text-base font-semibold pb-3 ">Høsteår</p>
                    {application?.deliveryReport?.status === 292460001 ? null : <p className=" text-sm pb-2 ">År</p>}

                    {application?.deliveryReport?.status === 292460001 ? (
                      <NumericFormat displayType="text" value={yearOption} />
                    ) : (
                      <NumericFormat
                        displayType="text"
                        value={yearOption}
                        className=" border border-grey rounded-lg w-16 h-10 p-2 text-center "
                      />
                    )}
                  </div>
                  {/* Amount */}
                  <div className=" flex flex-col">
                    <p className=" text-base font-semibold pb-3 ">Hvilken mengde ble levert?</p>
                    {application?.deliveryReport?.status === 292460001 ? null : (
                      <p className=" text-sm pb-2 ">Må skrives i hele tall</p>
                    )}
                    <div className=" flex flex-row items-center ">
                      {application?.deliveryReport?.status === 292460001 ? (
                        <NumericFormat
                          displayType="text"
                          value={application?.deliveryReport?.reportQuantity}
                          thousandSeparator=" "
                        />
                      ) : (
                        <NumericFormat
                          type="tel"
                          displayType="input"
                          onChange={(e) => setQuantity(e.target.value.replace(/\s/g, ""))}
                          className=" border border-grey rounded-lg  w-32 h-10 text-center p-2"
                          required
                          thousandSeparator=" "
                        />
                      )}

                      <p className=" ml-2 "> {productUnit.get(application?.planProduct?.planProductUnit)} </p>
                    </div>
                  </div>
                  {/* Comment */}
                  <div className=" flex flex-col">
                    <p className=" text-base font-semibold pb-3 ">Kommentar</p>
                    {application?.deliveryReport?.status === 292460001 ? (
                      <textarea
                        readOnly
                        maxLength={200}
                        value={application?.deliveryReport?.comment}
                        className=" border border-grey rounded-lg h-40 max-w-50 min-[900px]:max-w-md "
                      />
                    ) : (
                      <textarea
                        maxLength={200}
                        onChange={(e) => setComment(e.target.value)}
                        className=" border border-grey rounded-lg h-40 max-w-50 min-[900px]:max-w-md "
                      />
                    )}
                  </div>
                  {/* Button */}
                  {application?.deliveryReport?.status === 292460001 ? null : (
                    <div>
                      <button className=" px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0 disabled:bg-disbled disabled:border-disbled disabled:text-white focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500 ">
                        Lagre rapport
                      </button>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
        ) : null}
        {/* This div is used as the bottom border */}
        <div className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 ">
          <div className=" border border-grey col-span-4 sm:col-span-5 min-[900px]:col-span-6 "></div>
          <div className=" border border-none "></div>
        </div>
      </div>
    </>
  )
}

export default DeliveryReportContent
