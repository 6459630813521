import { useContext, useEffect, useState } from "react"
import AddNewProductButton from "../../Buttons/NewProductButton"
import AddNewProductComponent from "./AddNewProductComponent"
import EditRowComponent from "./EditRowComponent"
import ProductComponent from "./ProductComponent"
import ReadRowComponent from "./ReadRowComponent"
import AddNewCustomerButton from "../../Buttons/NewCustomerButton"
import AddNewCustomerComponent from "./AddNewCustomerComponent"
import { UserContext } from "../UserContext"
import { useMutation } from "react-query"
import { postApi } from "../../../../Common/WebApi-utils"
import { useAccount, useMsal } from "@azure/msal-react"
import { GetProfile } from "../ReactQueries"
import NotificationAlert from "../../../../Graphics/NotificationAlert"

const TableComponent = ({ ProductionPlan, showButtons }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [addNewCustomer, setAddNewCustomer] = useState(null)
  const [addNewProduct, setAddNewProduct] = useState(false)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount] = useContext(UserContext)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearNumber = thisYear
  const lastYearNumber = thisYear - 1
  // const [savedDate, setSavedDate] = useState(new Date())

  const RemoveClick = (id) => {
    setSelectedPlan(id)
  }
  const CustomerId = (id) => {
    setAddNewCustomer(id)
  }
  const Cancel = (bool) => {
    setAddNewProduct(bool)
  }

  const ProdPlanReceipts = useMutation(
    async (values) =>
      postApi(
        process.env.REACT_APP_API_URL_APIM + "/Activity/ProdPlanReceipts",
        values,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        setAlert(true)
        setAlertType("success")
        setAlertMessage("Kvittering er sendt på epost til ")
        setAlertUnderlineMessage(userProfile?.data?.emailAddress)
        setLoadingDone(true)
      },
    }
  )

  let receiptArray = []

  const sendReceipt = () => {
    ProdPlanReceipts.mutate(receiptArray)
    setLoadingDone(false)
  }

  useEffect(() => {
    receiptArray = []
  }, [activeVendor])

  return (
    <>
      <div>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
      </div>
      <table className=" mb-20 table-auto w-auto">
        <thead className=" bg-ghGreen-100">
          <tr className="  text-xs sm:text-base ">
            <th className=" border-none"></th>
            <td className=" hidden min-[355px]:table-cell py-4 text-center sm:text-right border-none text-ghGreen-600 ">
              Plan {lastYearNumber}
            </td>
            {/* <td className=" text-center sm:text-right border-none text-ghGreen-600 ">Levert {lastYearNumber}</td> */}
            <td className=" hidden sm:table-cell text-center sm:text-right border-none text-ghGreen-600 "></td>
            <td className=" text-center sm:text-right  border-none py-4 text-ghGreen-600 ">Ønske {thisYearNumber}</td>
            <td className=" text-right border-none "></td>
          </tr>
        </thead>
        <div className="bg-white h-4"></div>
        <tbody className="">
          {ProductionPlan?.data?.map((prop, i) => {
            return (
              <>
                <ProductComponent
                  prop={prop}
                  i={i}
                  sumQuantity={prop.prodPlan.map((prodPlan) => prodPlan.sumQuantity).reduce((acc, cur) => acc + cur)}
                  // lastYearDelivered={prop.prodPlan
                  //   .map((prodPlan) => prodPlan.lastYearDelivered)
                  //   .reduce((acc, cur) => acc + cur)}
                  lastYearPlan={prop.prodPlan.map((prodPlan) => prodPlan.lastYearPlan).reduce((acc, cur) => acc + cur)}
                />
                {prop.prodPlan.map((prodPlan) => {
                  if (receiptArray.find((element) => element === prodPlan.id)) {
                    console.log("copy")
                  } else if (prodPlan.id !== "00000000-0000-0000-0000-000000000000") {
                    receiptArray.push(prodPlan.id)
                  }
                  return (
                    <>
                      {selectedPlan === prodPlan.id ? (
                        <EditRowComponent prop={prodPlan} i={i} removeClick={RemoveClick} />
                      ) : (
                        <ReadRowComponent
                          prop={prodPlan}
                          i={i}
                          removeClick={RemoveClick}
                          sumQuantity={prop.prodPlan
                            .map((prodPlan) => prodPlan.sumQuantity)
                            .reduce((acc, cur) => acc + cur)}
                        />
                      )}
                    </>
                  )
                })}
                {/* Add new customer component */}
                {addNewCustomer === prop.id ? (
                  <AddNewCustomerComponent
                    cancelCustomer={CustomerId}
                    planProductId={prop.id}
                    prop={prop}
                    toWeek={prop?.prodPlan[0]?.planProduct?.toWeek.toString().slice(-3)}
                  />
                ) : null}
                {/* Add new customer button */}
                {addNewCustomer === prop.id ? (
                  <AddNewCustomerButton prop={prop} disabled={true} getId={CustomerId} />
                ) : (
                  <AddNewCustomerButton prop={prop} disabled={false} getId={CustomerId} />
                )}
              </>
            )
          })}
          {/* Add new product component */}
          {addNewProduct ? <AddNewProductComponent cancelProduct={Cancel} /> : null}
          {/* Add new product button */}
          {showButtons ? (
            <tr className="ml-4 mt-8">
              <td colSpan={5} className=" border-none pr-0 ">
                <div className="flex justify-end">
                  {addNewProduct ? (
                    <AddNewProductButton onClick={setAddNewProduct} disabled={true} />
                  ) : (
                    <AddNewProductButton onClick={setAddNewProduct} disabled={false} />
                  )}
                </div>
              </td>
              <td className=" border-none "></td>
            </tr>
          ) : null}
          {/* Ask for receipt button */}
          {showButtons ? (
            <tr className="">
              <td colSpan={5} className=" border-none pr-0 ">
                <div className="flex justify-end">
                  <button
                    onClick={sendReceipt}
                    className=" bg-white text-sm items-center flex flex-row p-2 rounded-lg border border-grey mt-4 hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
                  >
                    Send meg kvittering på ønske
                  </button>
                </div>
              </td>
              <td className=" border-none "></td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  )
}

export default TableComponent
