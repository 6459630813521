import { useIsAuthenticated } from "@azure/msal-react"
import { useQuery } from "react-query"
import { fetchApi } from "../../../Common/WebApi-utils"

export const GetProfile = (account, inProgress, instance) => {
  const isAuthenticated = useIsAuthenticated()
  return useQuery(
    "userProfileData",
    async () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Contacts", account, inProgress, instance).then((res) => res.data),
    {
      enabled: !!isAuthenticated,
    }
  )
}

export const GetProducts = (account, inProgress, instance) => {
  const isAuthenticated = useIsAuthenticated()
  return useQuery(
    "productseData",
    async () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Product/Products", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: !!isAuthenticated,
    }
  )
}

export const GetPortalSettingsText = (account, inProgress, instance) => {
  const isAuthenticated = useIsAuthenticated()
  return useQuery(
    "portalSettingsText",
    async () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/SettingKeys", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: !!isAuthenticated,
    }
  )
}
