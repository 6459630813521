import { graphql, useStaticQuery } from "gatsby"
import { Paginator } from "../components/Search/Pagination"
import AktueltCards from "../components/Cards/AktueltCards"
import { SortByDate } from "../components/Common/DateConverter"
import { useState } from "react"
import getMainPath from "../components/Common/MainPaths"

/**
 * @description Veiledning
 * @param param0
 */

const HomeRelatedArticles = () => {
  // Sort all related articles from new to old. Let GH override with createdAtModified

  const data = useStaticQuery(graphql`
    {
      allContentfulArticle(
        filter: { public: { eq: false }, node_locale: { eq: "nb-NO" }, articleType: { eq: false } }
      ) {
        nodes {
          id
          public
          contentful_id
          title
          subtitle
          slug
          order
          articleType
          node_locale
          metadataDescription
          createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
          createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
          updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
          category {
            id
            category
            slug
          }
          tags {
            id
            title
          }
          videoUrl
          heroImage {
            id
            public_id
            context {
              custom {
                Credits
                alt
              }
            }
            url
            secure_url
            width
            height
            format
          }
          ingress {
            ingress
            childMdx {
              timeToRead
            }
          }
          body {
            childMdx {
              timeToRead
              body
            }
          }
          author {
            name
            firstName
            lastName
            twitter
            twitterId
          }
        }
      }
      contentfulGeneric(slug: { eq: "aktuelt" }, node_locale: { eq: "nb-NO" }) {
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        body {
          childMdx {
            timeToRead
            body
          }
        }
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        node_locale
        slug
        title
        metadataDescription
      }
    }
  `)

  const mainPath = getMainPath("eiernytt")
  const [filter, setFilter] = useState(null)
  const [page, setPage] = useState(1)

  const articlesPerPage = 11

  const {
    allContentfulArticle: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  const sortedContent = SortByDate(allContent)

  // Applys filter
  let filteredContent = sortedContent.filter((article) => {
    if (!filter) return true
    return article.category?.id === filter.id
  })

  const numPages = Math.ceil(filteredContent.length / articlesPerPage)

  filteredContent = filteredContent.slice((page - 1) * articlesPerPage, articlesPerPage * page)

  // finds the first two featured Articles sorted på order (priority)
  const featuredArticles = filteredContent
    .filter((article) => article.articleType)
    .sort((a, b) => a.order - b.order)
    .slice(0, 2)

  // get all other articles, that is not in featuredArticles
  const standardArticles = filteredContent.filter((article) => {
    return !(article.id === featuredArticles[0]?.id || article.id === featuredArticles[1]?.id)
  })

  const categorys = sortedContent.map((article) => article.category).filter((cat) => cat?.id)
  const catIds = categorys.map((cat) => cat.id)

  const uniqueCategory = categorys.filter((category, index) => {
    return catIds.indexOf(category.id) === index
  })

  const handleChange = (e) => {
    if (e.target.value === "alle") {
      setFilter(null)
      return
    }
    setFilter(uniqueCategory.find(({ id }) => id === e.target.value))
  }

  return (
    <>
      <div>
        <main className="flex justify-center">
          <div className="relative  pb-20  sm:px-6  lg:px-8 md:max-w-7xl">
            {/* <header>
              <HeroAktueltHeadline {...heroBannerProps} />
            </header> */}
            <div className="hidden xl:block p-6  bg-transparent mx-auto max-w-5xl">
              <ul className="flex justify-around sm:flex-row sm:flex-wrap">
                <button
                  className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:outline-none focus:border-transparent border-none hover:border-transparent rounded-full"
                  role="menuitem"
                  onClick={() => setFilter(null)}
                  // autofocus="true"
                >
                  Vis alle #
                </button>

                {uniqueCategory.map((category) => {
                  return (
                    <button
                      key={category.id}
                      className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:outline-none focus:border-transparent border-none hover:border-transparent rounded-full"
                      role="menuitem"
                      onClick={() => setFilter(category)}
                      // autofocus="true"
                    >
                      #{category.category}
                    </button>
                  )
                })}
              </ul>
            </div>
            <div className="flex xl:hidden justify-center ">
              <select
                defaultValue={"DEFAULT"}
                onChange={handleChange}
                id="location"
                name="location"
                className="mt-1 w-auto text-base border-gray-300 hover:outline-none focus:outline-none  sm:text-sm  rounded-3xl bg-white squash-500 ring-1 ring-black  focus:ring-none"
              >
                <option value="alle">Vis alle #</option>
                {uniqueCategory.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.category}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="flex justify-center">
              <div className="mt-12 max-w-lg mx-auto grid gap-24 lg:grid-cols-2 xl:grid-cols-3 lg:max-w-none">
                {standardArticles
                  .map((article) => {
                    const props = {
                      data: article,
                      url: "nb/minside" + mainPath,
                      height: "lg:h-72",
                    }
                    return <AktueltCards key={article.id} {...props} />
                  })
                  .filter((article) => {
                    if (!filter) return true
                    return article.props.data.category.id === filter?.id
                  })}
              </div>
            </div>
            <div className="mt-16">
              <Paginator maxPage={numPages} currentPage={page} changePage={(e) => setPage(e)} />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default HomeRelatedArticles
