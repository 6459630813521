import { productUnit } from "../Maps"
import { NumericFormat } from "react-number-format"
import ProductionPlansTableContent from "./ProductionPlansTableContent"
import { useMsal } from "@azure/msal-react"
import { GetProfile } from "../ReactQueries"

const ProductionPlansTable = ({ ProductionPlan }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const currentDate = new Date(userProfile?.data?.today)
  const seasonChange = new Date(currentDate.getFullYear().toString() + "-10-15")
  const thisYear = currentDate > seasonChange ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearNumber = thisYear
  const lastYearNumber = thisYear - 1

  return (
    <>
      <table className=" mb-20 table-auto w-auto">
        <thead className=" bg-ghGreen-100">
          <tr className="  text-xs sm:text-base ">
            <th className=" border-none"></th>
            {/* Last year */}
            <td className=" hidden min-[355px]:table-cell text-center border-none text-ghGreen-600 ">
              Plan {lastYearNumber}
            </td>
            {/* This year */}
            <td className=" text-center border-none py-4 ">Plan {thisYearNumber}</td>
            {/* <td className=" text-right border-none "></td> */}
            {/* Status */}
            <td className=" text-center border-none py-4 ">Status</td>
          </tr>
        </thead>
        <tbody className="">
          {ProductionPlan?.data?.map((plans, i) => {
            return (
              <>
                {/* white space divider */}
                <div className="bg-white h-4"></div>
                <tr key={i} className=" bg-blue bg-opacity-20 mt-2 text-xs sm:text-base whitespace-nowrap ">
                  <th className=" pl-1 sm:pl-5 py-4 text-left font-semibold border-b border-grey uppercase">
                    {plans?.planProductName}
                  </th>
                  {/* Last year */}
                  <td className=" hidden min-[355px]:table-cell border-b border-grey text-center  text-ghGreen-600 ">
                    <NumericFormat
                      displayType="text"
                      value={plans?.prodPlan.map((prodPlan) => prodPlan.lastYearPlan).reduce((acc, cur) => acc + cur)}
                      thousandSeparator={" "}
                    />{" "}
                    {productUnit.get(plans?.prodPlan[0]?.planProduct?.planProductUnit)}
                  </td>
                  {/* This year */}
                  <td className="  text-center border-b border-grey ">
                    <NumericFormat
                      displayType="text"
                      value={plans?.prodPlan?.map((prodPlan) => prodPlan?.sumQuantity).reduce((acc, cur) => acc + cur)}
                      thousandSeparator={" "}
                    />{" "}
                    {productUnit.get(plans?.prodPlan[0]?.planProduct?.planProductUnit)}
                  </td>
                  {/* Empty spaces */}
                  <td className=" border-b border-grey"></td>
                  <td className="hidden sm:table-cell bg-white border-none"></td>
                </tr>
                {plans.prodPlan.map((prodPlan, key) => {
                  return <ProductionPlansTableContent prodPlan={prodPlan} plans={plans} key={key} />
                })}
              </>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default ProductionPlansTable
