const DeliveredButton = ({ activeReport, setActiveReport, application }) => {
  return (
    <>
      {activeReport === application?.id ? (
        <div className=" flex items-center justify-center ">
          <button
            onClick={() => setActiveReport("")}
            disabled
            className=" font-sans md:py-0.5 px-1 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white disabled:border-disabled focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#EBEBEB"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <span className=" hidden lg:flex ">Levert</span>
          </button>
        </div>
      ) : (
        <div className=" flex items-center justify-center ">
          <button
            onClick={() => {
              setActiveReport(application?.id)
            }}
            className=" font-sans text-white md:py-0.5 px-1 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg bg-ghGreen-500 border-ghGreen-500 hover:border-black focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#326846"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <span className=" hidden lg:flex ">Levert</span>
          </button>
        </div>
      )}
    </>
  )
}

export default DeliveredButton
