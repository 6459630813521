import moment from "moment"
import "moment/locale/nb"

const LastSaved = ({ savedDate, placeholder }) => {
  moment.locale("nb")

  return (
    <div className="flex flex-row mb-2 text-sm">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-5 h-5"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
      <p className=" text-left ">
        {placeholder} {moment(savedDate).format("LLLL")}
      </p>
    </div>
  )
}

export default LastSaved
