import { useAccount, useMsal } from "@azure/msal-react"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { fetchDownLoadFileApi, closeNotification } from "../../Common/WebApi-utils"
import LoadingData from "../../Graphics/LoadingData"
import { DownLoadVendorInvoiceProps } from "../../Sections/InvoiceFilterSection"
import Notification from "../../Graphics/Notification"

const DownLoadVendorInvoice = (props: DownLoadVendorInvoiceProps) => {
  const queryClient = useQueryClient()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [loading, setLoading] = useState(false)

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  let documentName = "FileName"

  const mutationDownload = useMutation(
    (e) => {
      documentName = e.invoiceNumber + ".pdf"
      return fetchDownLoadFileApi(
        process.env.REACT_APP_API_URL_APIM + "/Invoices/Download",
        {
          vendorRequest: {
            dataAreaId: "GH01",
            headerReference: props.headerReference,
            invoiceNumber: props.invoiceNumber,
          },
          salesRequest: {},
          isSales: false,
        },
        account,
        inProgress,
        instance
      ).then((res) => res.data)
    },
    {
      onSuccess: (pdf) => {
        // Create blob link to download
        const linkSource = `data:application/pdf;base64,${pdf}`
        const link = document.createElement("a")
        link.href = linkSource
        link.setAttribute("download", documentName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)

        closeNotification(setNotification)
        setNotification({
          title: "Vellykket",
          message: "Pdf er lastet ned",
          color: "green",
          delay: 3000,
          showNotification: true,
        })
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  const onDownLoadInvoice = (e) => {
    setLoading(true)
    mutationDownload.mutate(e)
  }

  return (
    <>
      <div>{loading && <LoadingData />}</div>
      <button
        onClick={(e) => onDownLoadInvoice(props)}
        className="inline-flex items-center text-button18 place-content-center font-normal text-black underline hover:text-tomat-500 active:text-tomat-500 focus:bg-white focus:text-tomat-500"
      >
        Last ned (pdf)
      </button>
      <div>{notification.showNotification && <Notification {...notification} />}</div>
    </>
  )
}

export default DownLoadVendorInvoice
