import { Link } from "gatsby-plugin-react-i18next"
import { TypographyRelatedCardTitle, TypographyRelatedCardDate } from "../Styling/Style"
import { useIsAuthenticated } from "@azure/msal-react"
import HeroBannerProps from "../Hero/HeroTypes"
import { getCloudinaryImage } from "../Common/Cloudinary"
import { Image, Transformation } from "cloudinary-react"

/**
 * @description: Tegner kort
 * @param props Innput
 */
const AktueltCards = (props) => {
  // const { accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const topSlug = props.topSlug || "kategori"

  // Get the default hero image
  let image = getCloudinaryImage(null)
  // replace with real image if this exists
  if (props.data.heroImage !== undefined && props.data.heroImage !== null)
    image = getCloudinaryImage(props.data.heroImage[0])

  const heroProps: HeroBannerProps = {
    image: image,
    videoUrl: props.data.videoUrl,
  }

  let date = props.data.createdAt
  if (props.data.updatedAt !== undefined && props.data.updatedAt !== null) date = props.data.updatedAt
  if (props.data.createdAtModified !== undefined && props.data.createdAtModified !== null)
    date = props.data.createdAtModified

  date = date.split(" ")[0]

  const title = props.data.title || props.data.topic || "Title missing"
  const url = "/" + props.url + "/" + props.data.slug + "/"
  const category = props.data.category?.category || " "
  const categoryUrl = "/nb/" + topSlug + "/" + props.data.category?.slug

  return (
    <div className={"flex flex-col"}>
      <figure className={"w-full overflow-hidden " + props.height}>
        <div className="flex justify-center min-w-full w-80 h-64 sm:max-w-xs sm:h-32 md:max-w-xs md:max-h-52 md:w-60 md:h-52 lg:max-w-xs lg:max-h-64 lg:w-80 lg:h-64">
          {heroProps.image !== undefined && heroProps.image !== null ? (
            <>
              <Image
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                responsiveUseBreakpoints="true"
                cloudName={process.env.CLOUDINARY_CLOUD_NAME}
                publicId={heroProps.image.imagePublicId}
                alt={heroProps.image.imageAlt}
                // loading="lazy"
                className="object-cover object-center z-0"
                secure
              >
                <Transformation quality="auto" fetchFormat="webp" />
              </Image>
            </>
          ) : (
            <div></div>
          )}
        </div>
      </figure>

      <div className={TypographyRelatedCardDate}>
        <div className="grid grid-cols-6 justify-items-stretch my-5">
          <time className="col-start-1 col-span-2" dateTime="DD. MMM, YYYY">
            {date}
          </time>
          {props.data.category ? (
            props.data.articleType ? (
              <Link className="col-start-3 col-end-7 inline-block text-squash-500" to={categoryUrl}>
                #{category}
              </Link>
            ) : (
              <span className="col-start-3 col-end-7 inline-block text-squash-500">#{category}</span>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        {props.data.public ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : isAuthenticated ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : props.data.public === undefined ? (
          <>
            <Link to={url} className={TypographyRelatedCardTitle}>
              {title}
            </Link>
          </>
        ) : (
          <>
            <Link to="/minside/" className={TypographyRelatedCardTitle}>
              {title} (For innlogget brukere)
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

export default AktueltCards
