import { toPrettyDate, parseDate2 } from "../../components/Common/DateConverter"
import { toNorwegianCurrencyFormat } from "../../components/Common/CurrencyFormatter"
import DownLoadSalesInvoice from "../MyPage/Economy/DownLoadSalesInvoice"
import DownLoadVendorInvoice from "../MyPage/Economy/DownLoadVendorInvoice"

export type FilterProps = {
  isSales: boolean
  invoiceNumber: string
  amount: number
  invoiceDueDate: string
  customerNumber: string
  key: number
  invoiceDate: string
  ledgerVoucher: string
  vendorInvoiceNumber: string
}

export type SalesFilterProps = {
  invoiceNumber: string
  amount: number
  invoiceDueDate: string
  customerNumber: string
  key: number
  invoiceDate: string
  ledgerVoucher: string
}

export type VendorFilterProps = {
  invoiceNumber: string
  amount: number
  invoiceDueDate: string
  customerNumber: string
  key: number
  headerReference: string
}

export type DownLoadSalesInvoiceProps = {
  invoiceNumber: string
  invoiceDate: string
  ledgerVoucher: string
}

export type DownLoadVendorInvoiceProps = {
  invoiceNumber: string
  headerReference: string
}

export const SalesInvoiceFilterSectionTable = (props: SalesFilterProps) => {
  return (
    <tr key={props.key}>
      <td className="whitespace-nowrap text-right py-4 pl-4 pr-3 text-body18 text-gray-900 sm:pl-6">
        {props.customerNumber}
      </td>
      <td className="whitespace-nowrap text-right py-4 pl-4 pr-3 text-body18 text-gray-900 sm:pl-6">
        {props.invoiceNumber}
      </td>
      <td className="whitespace-nowrap text-right px-3 py-4 text-body18 text-gray-900 ">
        {parseDate2(toPrettyDate(props.invoiceDueDate))}
      </td>
      <td className="whitespace-nowrap text-right pl-3  py-4 text-body18 text-gray-900  ">
        {toNorwegianCurrencyFormat(props.amount)}
      </td>

      <td className="text-right">
        <DownLoadSalesInvoice
          invoiceNumber={props.invoiceNumber}
          invoiceDate={props.invoiceDate}
          ledgerVoucher={props.ledgerVoucher}
        />
      </td>
    </tr>
  )
}

export const VenorInvoiceFilterSectionTable = (props: VendorFilterProps) => {
  return (
    <tr key={props.key}>
      <td className="whitespace-nowrap text-right py-4 pl-4 pr-3 text-body18 text-gray-900 sm:pl-6">
        {props.customerNumber}
      </td>
      <td className="whitespace-nowrap text-right py-4 pl-4 pr-3 text-body18 text-gray-900 sm:pl-6">
        {props.invoiceNumber}
      </td>
      <td className="whitespace-nowrap text-right px-3 py-4 text-body18 text-gray-900 ">
        {parseDate2(toPrettyDate(props.invoiceDueDate))}
      </td>
      <td className="whitespace-nowrap text-right pl-3  py-4 text-body18 text-gray-900  ">
        {toNorwegianCurrencyFormat(props.amount)}
      </td>

      <td className="text-right">
        <DownLoadVendorInvoice invoiceNumber={props.invoiceNumber} headerReference={props.headerReference} />
      </td>
    </tr>
  )
}

export const SalesInvoiceFilterSection = (props: SalesFilterProps) => {
  return (
    <div>
      <h2 className=" text-body18 font-semibold leading-normal mt-12 text-gray-900">Konto - {props.customerNumber}</h2>
      <div>
        <div className="mt-2 ">
          <dl className="divide-y divide-gray-200 xl:order-last">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Fakturanr.</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{props.invoiceNumber}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Bruttobeløp</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{toNorwegianCurrencyFormat(props.amount)}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Forfall</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0">
                {parseDate2(toPrettyDate(props.invoiceDueDate))}
              </dd>
            </div>
            {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{status}</dd>
            </div> */}

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Fakturakopi</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">
                <DownLoadSalesInvoice
                  invoiceNumber={props.invoiceNumber}
                  invoiceDate={props.invoiceDate}
                  ledgerVoucher={props.ledgerVoucher}
                />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export const VendorInvoiceFilterSection = (props: VendorFilterProps) => {
  return (
    <div>
      <h2 className=" text-body18 font-semibold leading-normal mt-12 text-gray-900">Konto - {props.customerNumber}</h2>
      <div>
        <div className="mt-2 ">
          <dl className="divide-y divide-gray-200 xl:order-last">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Fakturanr.</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{props.invoiceNumber}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Bruttobeløp</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{toNorwegianCurrencyFormat(props.amount)}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Forfall</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0">
                {parseDate2(toPrettyDate(props.invoiceDueDate))}
              </dd>
            </div>
            {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">{status}</dd>
            </div> */}

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-body18 font-medium text-gray-500">Fakturakopi</dt>
              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 ">
                <DownLoadVendorInvoice invoiceNumber={props.invoiceNumber} headerReference={props.headerReference} />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}
