const MyVendorComponentWithConditions = ({
  vendor,
  i,
  active,
  setActive,
  setActiveAccount,
  setGhConditionConfirmed,
  setAccountName,
  refresh,
}) => {
  return (
    <>
      {active ? (
        <button
          type="button"
          className=" text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans bg-black text-white rounded-lg "
          key={i}
          onClick={() => {
            setActive(vendor?.vendorId)
            setActiveAccount(vendor?.accountId)
            setGhConditionConfirmed(vendor?.conditionConfirmed)
            setAccountName(vendor?.accountName)
          }}
        >
          {vendor?.vendorName}
        </button>
      ) : (
        <button
          type="button"
          className=" text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans text-black rounded-lg "
          key={i}
          onClick={() => {
            setActive(vendor?.vendorId)
            setActiveAccount(vendor?.accountId)
            setGhConditionConfirmed(vendor?.conditionConfirmed)
            setAccountName(vendor?.accountName)
            refresh(true)
          }}
        >
          {vendor?.vendorName}
        </button>
      )}
    </>
  )
}

export default MyVendorComponentWithConditions
