import { ArrowCircleLeft, ArrowCircleRight } from "../../../Graphics/Arrow"

const WeekPicker = ({ weekNumber, year, decrementWeek, incrementWeek }) => {
  return (
    <section className="flex flex-row justify-center mb-4 space-x-3">
      <button className=" hover:text-ghGreen-600" onClick={decrementWeek}>
        <ArrowCircleLeft />
      </button>
      <p>
        Uke {weekNumber}/{year}
      </p>
      <button className=" hover:text-ghGreen-600" onClick={incrementWeek}>
        <ArrowCircleRight />
      </button>
    </section>
  )
}

export default WeekPicker
