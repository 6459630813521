import MyPageLayout from "../../Layout/MyPageLayout"
import ContactUsSection from "../../Sections/ContactUsSecton"
import EmployeeSection from "../../Sections/EmployeeSection"
import { TypographyHeading1 } from "../../Styling/Headings"
import { MDXRenderer } from "gatsby-plugin-mdx"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"

// For google maps
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import { MyMapComponent } from "../../../pages/kontakt"

// GOOGLE MAP
const render = (status: Status) => {
  if (status === Status.LOADING) return <div>Loading</div>
  if (status === Status.FAILURE) return <div>Error</div>
  return null
}

const ContactUs = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGeneric(
        id: {}
        createdAt: {}
        contentful_id: {}
        updatedAt: {}
        title: {}
        node_locale: { eq: "nb-NO" }
        slug: { eq: "kontakt" }
      ) {
        metadataDescription
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            body
          }
        }
      }
    }
  `)
  const { contentfulGeneric: content } = data

  const [option, setOption] = useState("")

  function handleChange(e) {
    setOption(e.target.value)

    // Smooth-scroll fra nedtrekksmeny for små skjermer
    e.preventDefault()
    const href = "#" + `${e.target.value}`
    const offsetTop = document.querySelector(href).offsetTop
    scroll({ top: offsetTop, behavior: "smooth" })
  }

  return (
    <>
      <div>
        <MyPageLayout title="Kontakt Gartnerhallen">
          {/* Sub-menu */}

          <div className="flex xl:hidden justify-center mt-9 mr-12 sm:mx-auto ">
            <select
              defaultValue={"DEFAULT"}
              onChange={handleChange}
              id="location"
              name="location"
              className="mt-1 pl-6 w-72 text-base border-gray-300 hover:outline-none focus:outline-none  sm:text-sm  rounded-3xl bg-white squash-500 ring-1 ring-black  focus:ring-none"
            >
              <option value="DEFAULT">Adresse</option>
              <option value="administrasjon">Administrasjon</option>
              <option value="pressekontakt">Pressekontakt</option>
              <option value="landsstyret">Styret</option>
              <option value="andre-tillitsvalgte">Andre tillitsvalgte</option>
            </select>
          </div>

          <div className="hidden xl:block lg:mr-8 lg:p-0 p-6 mx-auto xl:mx-auto mt-8 bg-transparent max-w-3xl ">
            <div className="flex justify-around md:flex-row md:flex-wrap">
              <AnchorLink
                href={"#kontaktinformasjon"}
                className="nav-link bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:border-transparent border-none hover:border-transparent rounded-full 
              "
                role="menuitem"
                // autofocus="true"
              >
                Adresse
              </AnchorLink>

              <AnchorLink
                href={"#administrasjon"}
                className="bg-transparent text-black active:bg-knapplysgronn-500  hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal    focus:bg-knapplysgronn-500 py-2 px-4  border-none hover:border-transparent rounded-full"
                role="menuitem"
              >
                Administrasjon
              </AnchorLink>
              <AnchorLink
                href={"#pressekontakt"}
                className="bg-transparent text-black active:bg-knapplysgronn-500  hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal    focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
                role="menuitem"
              >
                Pressekontakt
              </AnchorLink>
              <AnchorLink
                href={"#landsstyret"}
                className="bg-transparent text-black hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
                role="menuitem"
              >
                Styret
              </AnchorLink>
              <AnchorLink
                href={"#andre-tillitsvalgte"}
                className="bg-transparent text-black hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
                role="menuitem"
              >
                Andre tillitsvalgte
              </AnchorLink>
            </div>
          </div>

          {/* <div className="relative max-w-4xl mx-auto text-center mt-8 mb-8">{content.ingress.ingress}</div> */}

          <div className="w-auto md:w-63 lg:w-84  xl:w-116 2xl:w-auto mt-16">
            <div className="flex flex-col xl:flex-row xl:flex-wrap mt-8 2xl:mr-48 ">
              <div className=" bg-white mt-8 mr-16  lg:ml-0 lg:mr-28 xl:mx-auto  flex-auto order-2 md:h-128 h-80 flex-grow min-w-max sm:min-w-0 2xl:max-w-84">
                {/* Div container for the map.  */}
                <div id="map" className="h-full w-full mx-auto"></div>
              </div>

              <div className="flex-shrink lg:mr-16  xl:mr-28 2xl:mr-36 ">
                <div className="max-w-md ml-6 mr-6  sm:ml-0  sm:mr-0  ">
                  <h2 id="kontaktinformasjon" className={TypographyHeading1}>
                    Adresse
                  </h2>

                  <ContactUsSection />
                </div>
              </div>
            </div>

            <div className="mt-16   mx-auto">
              <div id="administrasjon">
                <EmployeeSection function="administrasjon">Administrasjon</EmployeeSection>
              </div>
              <div id="pressekontakt">
                <EmployeeSection function="pressekontakt">Pressekontakt</EmployeeSection>
              </div>
              <div id="landsstyret">
                <EmployeeSection function="landsstyret">Styret</EmployeeSection>
              </div>
            </div>

            {/* Gartnerhallens andre tillitsvalgte og regioner */}
            <div className="mr-16 sm:mr-8 " id="andre-tillitsvalgte">
              <h2 className={TypographyHeading1}>Andre tillitsvalgte</h2>
              <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
            </div>
          </div>
        </MyPageLayout>
      </div>
      {/* Google Maps */}
      <Wrapper apiKey="AIzaSyCws_sKOtT8LFjkJOxpDQnOFfbhuhpnk_o" render={render}>
        <MyMapComponent />
      </Wrapper>
    </>
  )
}
export default ContactUs
