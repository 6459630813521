// import "tailwindcss/tailwind.css"
import { useMemo, useState } from "react"
import { Router } from "@reach/router"
import { withPrefix } from "gatsby"
import { ConnectionContext } from "../components/CustomHooks/ConnectionContext"
import Home from "../components/MyPage/Home/Home" // Startsiden til Min Side /minside/
import UserProfile from "../components/MyPage/Profile/UserProfile"
import Account from "../components/MyPage/Profile/Account"
import Logout from "../components/Security/Logout"
import Messages from "../components/MyPage/Profile/Messages"
import ProductionPlan from "../components/MyPage/ProductionPlan"
import Projects from "./prosjekt"
import Documents from "../components/MyPage/Documents/Documents"
import Reports from "../components/MyPage/Economy/Reports"
import Invoice from "../components/MyPage/Economy/Invoice"
import ContactUs from "../components/MyPage/Support/Contact"
import GroupRoute1 from "../components/ConnectionRoutes/GroupRoute1"
import GroupRoute2 from "../components/ConnectionRoutes/GroupRoute2"
import Veiledning from "../components/MyPage/Support/Veiledning"
import Faq from "../components/MyPage/Support/Faq"
import OwnerNews from "../components/MyPage/Home/OwnerNews"
import ProductionWishes from "../components/MyPage/ProductionPlan/ProductionWishes"
import PlanWeek from "../components/MyPage/ProductionPlan/PlanWeek"
import LeaveApplication from "../components/MyPage/Applications/LeaveApplication"
import LeaveOverview from "../components/MyPage/Applications/LeaveOverview"
import DispensationApplication from "../components/MyPage/Applications/DispensationApplication"
import DispensationOverview from "../components/MyPage/Applications/DispensationOverview"
import NewLeaveApplication from "../components/MyPage/Applications/NewLeaveApplication"
import NewDispensationApplication from "../components/MyPage/Applications/NewDispensationApplication"
import DeliveryReportOverview from "../components/MyPage/DeliveryReport/DeliveryReportOverview"
import RKPApplications from "../components/MyPage/Applications/RKPApplications"
import RKPEditApplications from "../components/MyPage/Applications/RKPEditApplication"
import ProductionPlans from "../components/MyPage/ProductionPlan/ProductionPlans"
import AcceptPlan from "../components/MyPage/ProductionPlan/AcceptPlans"
import RkpProductionPlanReport from "../components/MyPage/PowerBI/RKP/RkpProductionPlanReport"
import RkpApplicationsReport from "../components/MyPage/PowerBI/RKP/RkpApplicationsReport"
import SkpPlans from "../components/MyPage/ProductionPlan/SkpPlanProductProcess"
import SkpPlansComment from "../components/MyPage/ProductionPlan/SkpPlanProductProcessComment"
import RkpPlans from "../components/MyPage/ProductionPlan/RkpPlanProductProcess"
import RkpPlansComment from "../components/MyPage/ProductionPlan/RkpPlanProductProcessComment"
import SkpProductionPlanReport from "../components/MyPage/PowerBI/SKP/SkpProductionPlanReport"
import SkpApplicationsReport from "../components/MyPage/PowerBI/SKP/SkpApplicationsReport"
import MyCensuses from "../components/MyPage/MyCensuses/MyCensuses"
import ProductionCensusesReport from "../components/MyPage/PowerBI/ProductionCensusesReport"
import SkpCensusesReport from "../components/MyPage/PowerBI/SKP/SkpCensusesReport"
import RkpCensusesReport from "../components/MyPage/PowerBI/RKP/RkpCensusesReport"
import NorrekReport from "../components/MyPage/PowerBI/NorrekReport"
import PackagingCensusesReport from "../components/MyPage/PowerBI/Packaging/PackagingCensusesReport"
import PackagingProductionPlanReport from "../components/MyPage/PowerBI/Packaging/PackagingProductionPlanReport"
import BamaPlans from "../components/MyPage/Bama/BamaPlans"
import OrderOverview from "../components/MyPage/Economy/OrderOverview"
import InvoiceOverview from "../components/MyPage/Economy/InvoiceOverview"
import AnnualReport from "../components/MyPage/Economy/AnnualReport"
import SmartSuggestion from "../components/MyPage/PowerBI/SmartSuggestionReport"

const Minside = () => {
  return (
    <>
      <div className="col-span-1"> </div>
      <div className="col-span-auto">
        <Router basepath={withPrefix("/minside")}>
          {/* /minside */}
          <Home path="/" component={Minside} />
          <OwnerNews path="eiernytt" component={Minside} />
          {/* Sikkerhet */}
          <Logout path="innstillinger/Logout" />
          {/* Innstillinger og brukerprofil */}
          <UserProfile path="innstillinger/brukerprofil" />
          {/* Kundekommunikasjon og Innboks */}
          <Messages path="meldinger" />
          {/* Gartnerhallen spesifikke ting */}
          <GroupRoute1 path="produksjonsplan" component={ProductionPlan} />
          <Projects path="prosjekter" />
          <Documents path="documents" />
          <GroupRoute1 path="virksomhet" component={Account} />
          {/* <GroupRoute1 path="okonomi/rapporter" component={Reports} /> */}
          <GroupRoute1 path="okonomi/ordreoversikt" component={OrderOverview} />
          <GroupRoute1 path="okonomi/fakturaoversikt" component={InvoiceOverview} />
          <GroupRoute1 path="okonomi/aarsoppgave" component={AnnualReport} />
          <GroupRoute1 path="okonomi/fakturaer" component={Invoice} />
          <GroupRoute1 path="okonomi/kvalitetsrapport-norrek" component={NorrekReport} />
          {/* Gartnerhallen support */}
          <GroupRoute2 path="support/kontakt" component={ContactUs} />
          <GroupRoute2 path="support/veiledning" component={Veiledning} />
          <GroupRoute2 path="support/faq" component={Faq} />
          {/* Gartnerhallen Produksjonsønsker */}
          <ProductionWishes path="produksjonsonsker" />
          <PlanWeek path="produksjonsonsker/planlegger" />
          {/* Gartnerhallen Produksjonsplaner */}
          <ProductionPlans path="produksjonsplaner" />
          <AcceptPlan path="produksjonsplaner/plan" />
          {/* Permisjon */}
          <NewLeaveApplication path="ny-soknad-permisjon" />
          <LeaveApplication path="soknad-permisjon" />
          <LeaveOverview path="oversikt-permisjon" />
          {/* Dispensasjon */}
          <DispensationApplication path="soknad-dispensasjon" />
          <NewDispensationApplication path="ny-soknad-dispensasjon" />
          <DispensationOverview path="oversikt-dispensasjon" />
          {/* Regionråd */}
          <RkpProductionPlanReport path="/regionrad/produksjonsplaner" />
          <RkpApplicationsReport path="/regionrad/oversikt-soknader" />
          <RKPApplications path="/regionsrad/se-soknader" />
          <RKPEditApplications path="/regionsrad/endre-soknad" />
          <RkpPlans path="regionrad/innspill-produksjonsplaner" />
          <RkpPlansComment path="regionrad/innspill-produksjonsplaner/kommentar" />
          <RkpCensusesReport path="regionsrad/oversikt-lagertall" />
          {/* Leveringsrapport */}
          <DeliveryReportOverview path="/dispensasjon/leveringsrapport" />
          {/* SKP */}
          <SkpProductionPlanReport path="/skp/produksjonsplaner" />
          <SkpApplicationsReport path="/skp/oversikt-soknader" />
          <SkpPlans path="skp/innspill-produksjonsplaner" />
          <SkpPlansComment path="skp/innspill-produksjonsplaner/kommentar" />
          <SkpCensusesReport path="skp/oversikt-lagertall" />
          {/* Lagertelling */}
          <MyCensuses path="telling/registrere-telling" />
          <ProductionCensusesReport path="telling/oversikt-tellinger" />
          {/* Pakkeri */}
          <PackagingProductionPlanReport path="pakkeri/produksjonsplan" />
          <PackagingCensusesReport path="pakkeri/oversikt-lagertall" />
          {/* Bama */}
          <BamaPlans path="innmelding" />
          {/* Smart forslag */}
          <SmartSuggestion path="smart-forslag" />
        </Router>
      </div>
    </>
  )
}

export default Minside
