import { useAccount, useMsal } from "@azure/msal-react"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, patchApi } from "../../../../Common/WebApi-utils"
import SaveButton from "../../Buttons/SaveButton"
import SavedButton from "../../Buttons/SavedButton"
import { month, productUnit } from "../Maps"
import { NumericFormat } from "react-number-format"
import LastSaved from "../LastSavedComponent"
import NotificationAlert from "../../../../Graphics/NotificationAlert"
import { Link } from "@reach/router"
import { GetProfile } from "../ReactQueries"

// This component lets the user change and add new week values on a selected productionplan. The user can also add a comment.

type Quantity = {
  Id: string
  Quantity: number
}

type Comment = {
  Id: string
  ProdPlanComment: string
}

const WeekComponent = ({
  prodPlanId,
  lastYearPlanId,
  setLoadingDone,
  customer,
  unit,
  product,
  newSum,
  setNewSum,
  totalSum,
  setTotalSum,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  const account = useAccount(accounts[0] || {})
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [saved, setSaved] = useState(false)
  const [array, setArray] = useState([])
  const [sum, setSum] = useState(newSum)
  const [originalSum, setOriginalSum] = useState(newSum)
  const [quantityArray, setQuantityArray] = useState([])
  const [changedValues, setChangedValues] = useState(false)
  const [comment, setComment] = useState()
  const [changedComment, setChangedComment] = useState(false)
  const [activeComment, setActiveComment] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString().slice(2)
  const thisYearNumber = thisYear
  const lastYearNumber = thisYear - 1
  const [savedDate, setSavedDate] = useState(new Date())
  let query = ""

  if (lastYearPlanId === undefined) {
    query = "/PlanWeek/ProdPlanWeeks?prodPlanId=" + prodPlanId
  } else {
    query = "/PlanWeek/ProdPlanWeeks?prodPlanId=" + prodPlanId + "&lastYearPlanId=" + lastYearPlanId
  }

  const getMyPlanWeeks = useQuery(
    "myPlansWeekData",
    () => fetchApi(process.env.REACT_APP_API_URL_APIM + query, account, inProgress, instance).then((res) => res.data),
    {
      onSuccess: (data) => {
        setComment(data?.whishes?.[0]?.planWeeks?.[0]?.prodPlanNo?.prodPlanComment)
        setLoadingDone(true)
        setQuantityArray([])
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const addComment = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil med kommentaren. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["myPlansWeekData"])
        setSaved(true)
      },
    }
  )

  const patchPlanWeek = useMutation(
    async (values) =>
      patchApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanWeek?prodPlanId=" + prodPlanId,
        values,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["myPlansWeekData"])
        setTotalSum(totalSum - originalSum + sum)
        setNewSum(sum)
        setOriginalSum(sum)
        setSaved(true)
        setSavedDate(new Date())
      },
    }
  )

  // Checks i only need to run one mutate or both.
  const onSubmit = () => {
    if (changedValues === true) {
      patchPlanWeek.mutate(array)
      setLoadingDone(false)
    }

    if (changedComment === true) {
      const commentObject: Comment = {
        Id: prodPlanId,
        ProdPlanComment: activeComment,
      }
      addComment.mutate(commentObject)
      setLoadingDone(false)
    }
  }

  //= ==============================================================
  //  On change make 2 different arrays.
  // "array" is empty and gets objects with an id and quantity with the different weeks that gets new values.
  // "quantityArray" contains all the weeks and its current input value. If the input value is changed, the sum of all the inputs is also changed.
  //= ==============================================================
  const handleValues = (e, id) => {
    setChangedValues(true)
    const newQuantity = { Id: id, Quantity: e }

    // if array already contains the id from before, change the quantity for the object with that id. Or else add the object with that id to the array.
    if (array.find(({ Id }) => Id === id)) {
      const indexChanges = array.findIndex((element) => element.Id === id)
      if (Number.isNaN(e)) {
        array[indexChanges].Quantity = 0
      } else {
        array[indexChanges].Quantity = e
      }
    } else {
      array.push(newQuantity)
    }

    // When the input gets a new value, check for the id and change the quantity in that object.
    const index = quantityArray.findIndex((element) => element.Id === id)
    if (Number.isNaN(e)) {
      quantityArray[index].Quantity = 0
    } else {
      quantityArray[index].Quantity = e
    }

    // Add all values in quantityArray and get the sum.
    const tempSum = quantityArray.reduce((acc, current) => (acc = acc + current.Quantity), 0)
    setSum(tempSum)
  }

  const handleComment = (e) => {
    setActiveComment(e.target.value)
    setChangedComment(true)
  }

  const plansArray = getMyPlanWeeks?.data?.plans

  return (
    <div>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      {saved ? <LastSaved savedDate={savedDate} placeholder={"Sist lagret"} /> : null}
      <div className=" flex flex-col items-center lg:items-stretch lg:flex-row lg:space-x-8 lg:appearance-none overflow-y-auto ">
        <div className="flex flex-col ">
          <div className=" grid-cols-3 mb-4 grid sm:grid-cols-4 h-16">
            <div className=" hidden sm:flex bg-ghGreen-100 sm:bg-white"></div>
            <div className=" pl-4 sm:pl-0 flex justify-start sm:justify-center items-center bg-ghGreen-100 h-16 ">
              Uke
            </div>
            <div className=" flex justify-start items-center bg-ghGreen-100 h-16 text-ghGreen-600 ">
              Plan {lastYearNumber}
            </div>
            <div className="flex justify-start items-center bg-ghGreen-100 h-16">Ønske {thisYearNumber}</div>
          </div>
          {/* Table content */}
          <div className="flex flex-row w-full">
            <div className="flex flex-col">
              {getMyPlanWeeks?.data?.whishes.map((prop, index) => (
                <div key={index} className="flex flex-col sm:flex-row">
                  <p className=" w-36 mt-5 ">
                    {month.get(prop.month)} {prop.year}
                  </p>
                  <ul>
                    {prop.planWeeks.map((planWeekProp, i) => {
                      const quantityObject: Quantity = {
                        Id: planWeekProp.id,
                        Quantity: planWeekProp.planWeekQuantity,
                      }
                      const qty =
                        lastYearPlanId !== undefined
                          ? plansArray?.find((item) => item.weekNumber === planWeekProp.planWeekNo)
                          : null

                      if (quantityArray.find(({ Id }) => Id === planWeekProp.id)) {
                        console.log("Skip")
                      } else {
                        quantityArray.push(quantityObject)
                      }

                      return (
                        <li key={i} className=" grid grid-cols-3">
                          <div className=" pl-2 sm:pl-0 flex justify-start sm:justify-center items-center border-b-2 border-black ">
                            <p>
                              {planWeekProp?.planWeekNo?.toString().slice(-2) < 10
                                ? planWeekProp?.planWeekNo?.toString().slice(-1)
                                : planWeekProp?.planWeekNo?.toString().slice(-2)}
                            </p>
                          </div>
                          {/* Prev plan */}
                          <div className=" flex justify-start items-center pl-2 text-ghGreen-500 border-b-2 border-black ">
                            <p>
                              <NumericFormat
                                displayType="text"
                                value={lastYearPlanId !== undefined ? qty?.quantity : 0}
                                thousandSeparator={" "}
                              />{" "}
                              {productUnit.get(unit)}
                            </p>
                          </div>
                          {/* Input */}
                          <div className=" flex pr-4 justify-start items-center border-b-2 border-black ">
                            <NumericFormat
                              type="tel"
                              displayType="input"
                              value={planWeekProp.planWeekQuantity}
                              placeholder="0"
                              onChange={(e) =>
                                handleValues(parseInt(e.target.value.replace(/\s/g, "")), planWeekProp.id)
                              }
                              thousandSeparator=" "
                              className=" appearance-none w-24 h-11 border border-black rounded-lg my-3 text-center focus:ring-1 focus:ring-black focus:border-black "
                            />
                            <span className="pl-2">{productUnit.get(unit)}</span>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Product information side content. Hidden if screen is smaller than sm. */}
        <div className=" mb-4 flex flex-col w-full sm:w-2/3 lg:self-start mt-8 lg:w-1/3 lg:mt-0 lg:overflow-y-auto lg:sticky lg:top-0 lg:right-0 ">
          <div className=" hidden sm:flex h-16 justify-center items-center bg-ghGreen-100 text-center uppercase font-semibold mb-2">
            {product} {thisYearString}/{nextYearString}
          </div>
          <div className=" hidden sm:flex ">
            <div className=" pl-4 w-full border-r border-b border-grey">
              Til {customer}
              <p className="mt-2 mb-4 text-base font-semibold">
                <NumericFormat displayType="text" value={sum} thousandSeparator={" "} /> {productUnit.get(unit)}
              </p>
            </div>
            <div className=" pl-4 w-full border-b border-grey">
              Sum total
              <p className="mt-2 mb-4 text-base font-semibold ">
                <NumericFormat displayType="text" value={totalSum - originalSum + sum} thousandSeparator={" "} />{" "}
                {productUnit.get(unit)}
              </p>
            </div>
          </div>
          <div className="flex flex-col pt-8 mb-2">
            <textarea
              placeholder="Her kan man skrive en tilbakemelding til Gartnerhallens produksjonsplanleggere"
              maxLength={200}
              defaultValue={comment}
              onChange={handleComment}
              className=" border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black"
            ></textarea>
          </div>
          <div className=" hidden sm:flex mt-4 justify-end">
            {saved ? (
              <SavedButton onClick={onSubmit} />
            ) : (
              <SaveButton changedComment={changedComment} changedValues={changedValues} onClick={onSubmit} />
            )}
          </div>
          {saved ? (
            <div className=" hidden sm:flex mt-4 justify-end">
              <Link to="/minside/produksjonsonsker" className=" flex flex-row ">
                <p className="underline underline-offset-4 decoration-tomat-500">Gå til mine produksjonsønsker</p>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      {/* Sticky footer. Hidden if screen is bigger than sm. */}
      <div className=" bg-white z-50  fixed inset-x-0 bottom-0 px-4 flex flex-row items-center justify-between text-sm min-[360px]:text-base sm:hidden border-grey border-t border-b h-20 ">
        <div className=" flex flex-col ">
          <p className=" text-sm text-grey ">{product}</p>
          <p className="text-base ">
            <NumericFormat displayType="text" value={sum} thousandSeparator={" "} /> {productUnit.get(unit)}
          </p>
        </div>
        <div>
          {saved ? (
            <SavedButton onClick={onSubmit} />
          ) : (
            <SaveButton changedComment={changedComment} changedValues={changedValues} onClick={onSubmit} />
          )}
        </div>
      </div>
    </div>
  )
}

export default WeekComponent
