import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useEffect, useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi, patchApi } from "../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import { navigate } from "gatsby"
import moment from "moment"
import "moment/locale/nb"
import VendorComponent from "../Applications/Components/VendorComponent"
import DeliveryReportContent from "./Components/DeliveryReportContent"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const DeliveryReport = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [loadingDone, setLoadingDone] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [activeReport, setActiveReport] = useState("")
  const [hasVendor, setHasVendor] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  moment.locale("nb")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "LeveringsrapportIngress")

  const getMyDeliveryReportVendor = useQuery(
    "myDeliveryReportVendorVendorData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors?type=deliveryReport",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userProfile.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
        if (data?.length > 0) {
          setHasVendor(true)
        } else {
          setLoadingDone(true)
        }
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )
  const [activeVendor, setActiveVendor] = useState(getMyDeliveryReportVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyDeliveryReportVendor?.data?.[0]?.accountId)

  const getMyApplication = useQuery(
    "myApplicationData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Application/ApprovedDisp?producer=" +
          activeAccount +
          "&vendor=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: getMyDeliveryReportVendor.isSuccess && hasVendor === true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLoadingDone(true)
        setRefresh(false)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  useEffect(() => {
    if (refresh === true) {
      getMyApplication.refetch()
      setLoadingDone(false)
    }
  }, [refresh])

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row mt-8 md:mt-0">
            <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
              Min side
            </Link>
            <p className=" font-semibold"> / Dispensasjon og permisjon / Leveringsrapport</p>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Leveringsrapport</h1>
            <p>{infoText?.value}</p>
          </div>
          {hasVendor ? (
            <div className=" mb-8 flex flex-col">
              <div>
                <p className=" text-lg mb-4 font-semibold ">Leveringsrapport for</p>
              </div>
              <VendorComponent
                getMyVendor={getMyDeliveryReportVendor}
                activeVendor={activeVendor}
                setActiveVendor={setActiveVendor}
                setActiveAccount={setActiveAccount}
                refresh={setRefresh}
              />
            </div>
          ) : null}
          <div>
            {/* Info text */}
            <div className=" text-sm flex flex-row items-center ">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.00001 15.1667C4.31811 15.1667 1.33334 12.1819 1.33334 8.5C1.33334 4.8181 4.31811 1.83334 8.00001 1.83334C9.76812 1.83334 11.4638 2.53571 12.7141 3.78596C13.9643 5.0362 14.6667 6.73189 14.6667 8.5C14.6667 12.1819 11.6819 15.1667 8.00001 15.1667ZM8.66668 9.16667V5.16667C8.66668 4.79848 8.3682 4.5 8.00001 4.5C7.63182 4.5 7.33334 4.79848 7.33334 5.16667V9.16667C7.33334 9.53486 7.63182 9.83334 8.00001 9.83334C8.3682 9.83334 8.66668 9.53486 8.66668 9.16667ZM8.25334 11.22C8.29583 11.2346 8.33618 11.2548 8.37334 11.28C8.40827 11.3046 8.44166 11.3313 8.47334 11.36C8.59517 11.4878 8.66422 11.6568 8.66668 11.8333C8.6677 12.0105 8.59813 12.1809 8.47334 12.3067C8.40854 12.3656 8.33413 12.4129 8.25334 12.4467C8.09198 12.518 7.90804 12.518 7.74668 12.4467C7.66589 12.4129 7.59148 12.3656 7.52668 12.3067C7.40189 12.1809 7.33232 12.0105 7.33334 11.8333C7.33232 11.6561 7.40189 11.4858 7.52668 11.36C7.68428 11.2039 7.90919 11.1365 8.12668 11.18C8.17083 11.1862 8.21365 11.1997 8.25334 11.22ZM8.00001 3.16667C10.9455 3.16667 13.3333 5.55448 13.3333 8.5C13.3333 9.91449 12.7714 11.271 11.7712 12.2712C10.7711 13.2714 9.4145 13.8333 8.00001 13.8333C5.05449 13.8333 2.66668 11.4455 2.66668 8.5C2.66668 5.55448 5.05449 3.16667 8.00001 3.16667Z"
                  fill="#231F20"
                />
              </svg>
              <p>Kun varer det er søkt om dispensasjon for vises</p>
            </div>
            {/* Table header */}
            {hasVendor ? (
              <div className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 ">
                <div className=" col-span-1 sm:col-span-2 bg-ghGreen-100 h-16"></div>
                <div className=" hidden min-[900px]:flex items-center justify-end bg-ghGreen-100 text-ghGreen-600 h-16">
                  Mengde
                </div>
                <div className=" hidden sm:flex col-span-2 pr-8 sm:pr-0 sm:col-span-1 min-[900px]:flex items-center justify-end  text-ghGreen-600 bg-ghGreen-100 h-16 ">
                  Gyldig fra
                </div>
                <div className=" col-span-3 sm:col-span-2 flex items-center justify-center whitespace-nowrap sm:whitespace-normal pl-0 sm:justify-center bg-ghGreen-100 h-16 ">
                  Status søknad
                </div>
              </div>
            ) : null}
            {loadingDone ? (
              <>
                {getMyApplication?.data?.map((applicationProduct, key) => (
                  <div className=" mb-8 ">
                    <div key={key} className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7 mt-2 ">
                      <div className=" text-sm min-[900px]:text-base flex items-center pl-2 sm:pl-4 border-b-2 border-grey bg-blue bg-opacity-20 h-16 uppercase font-semibold col-span-4 sm:col-span-5 min-[900px]:col-span-6 ">
                        {applicationProduct?.planProductName}
                      </div>
                    </div>
                    {/* Customers content */}
                    {applicationProduct?.dispApplications?.map((application, key) => (
                      <div key={key}>
                        <DeliveryReportContent
                          application={application}
                          activeReport={activeReport}
                          setActiveReport={setActiveReport}
                          setLoadingDone={setLoadingDone}
                          setRefresh={setRefresh}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function DeliveryReportOverview() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <DeliveryReport />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default DeliveryReportOverview
