const SavedCensusButton = () => {
  return (
    <button
      type="submit"
      className="  px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-500 bg-ghGreen-500 text-white rounded-xl active:ring-0 disabled:bg-disbled disabled:border-disbled disabled:text-white focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500 "
    >
      <div className=" flex flex-row ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
        Lagret
      </div>
    </button>
  )
}

export default SavedCensusButton
