import { useAccount, useMsal } from "@azure/msal-react"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchApi, postApi } from "../../../../Common/WebApi-utils"
import { year } from "../Maps"
import { GetProducts, GetProfile } from "../ReactQueries"
import { UserContext } from "../UserContext"
import ProductFilter from "./ProductFilter"
import { XButtonIcon } from "../../../../Graphics/IconsMyPage"
import NotificationAlert from "../../../../Graphics/NotificationAlert"

// Add a new product by adding search values in an input. This component is made up of a search bar, "Legg til" button and cancel button

export type newProduct = {
  Producer: string
  Vendor: string
  Customer: string
  PlanProduct: string
  ProdPlanYearOption: number | undefined
  ProdPlanType: number
  ProdPlanStatus: number
}

const AddNewProductComponent = ({ cancelProduct }) => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  // const allProducts = GetProducts(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [id, setId] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount] = useContext(UserContext)
  const [toWeek, setToWeek] = useState("")
  const [yearOption, setYearOption] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString()

  // Check the toWeek value, if it is bigger than 100 wish will last in to the next year
  useEffect(() => {
    if (parseInt(toWeek) > 100) {
      setYearOption(thisYearString + "-" + nextYearString)
    } else {
      setYearOption(thisYearString)
    }
  }, [toWeek])

  const getMyPlans = useQuery(
    "myPlansData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/ProductionPlan/MyWhishes?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        // error,message
      },
    }
  )

  const getPlanProduct = useQuery(
    "PlanProductData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanProduct/PlanProducts?searchParam=" + filterValue,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: filterValue.length > 2,
      onSuccess: (data) => {
        //   console.log(data)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const AddProduct = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["myPlansData"])
        cancelProduct(false)
      },
    }
  )

  // Submit the selected product to the active vendor.
  const onSubmitProduct = () => {
    const ProductObject: newProduct = {
      Producer: activeAccount,
      Vendor: activeVendor,
      Customer: "",
      PlanProduct: id,
      ProdPlanYearOption: year.get(yearOption),
      ProdPlanType: 292460000,
      ProdPlanStatus: 292460000,
    }
    setLoadingDone(false)
    AddProduct.mutate(ProductObject)
  }

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (filterValue.length) {
      case 3:
        getPlanProduct.refetch()
        break
      case 5:
        getPlanProduct.refetch()
        break
      case 7:
        getPlanProduct.refetch()
        break
      case 9:
        getPlanProduct.refetch()
        break
      case 11:
        getPlanProduct.refetch()
        break
      case 13:
        getPlanProduct.refetch()
        break
    }
  }, [filterValue])

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <tr className=" bg-blue bg-opacity-20 ">
        {/* Render this product filter colSpan if smaller than sm */}
        <th colSpan={2} className=" pl-2 sm:pl-8 py-4 sm:hidden  border-none relative">
          <div className="text-left relative ">
            <p className=" text-sm sm:text-base pb-2">Ny vare</p>
            <ProductFilter
              prop={getPlanProduct}
              setId={setId}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              placeholder={"Skriv for å søke etter varenavn"}
              setToWeek={setToWeek}
            />
          </div>
        </th>
        {/* Render this product filter colSpan if bigger than sm */}
        <th colSpan={3} className=" hidden sm:table-cell pl-2 sm:pl-8 py-4  border-none relative">
          <div className="text-left relative ">
            <p className=" text-sm sm:text-base pb-2">Ny vare</p>
            <ProductFilter
              prop={getPlanProduct}
              setId={setId}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              placeholder={"Skriv for å søke etter varenavn"}
              setToWeek={setToWeek}
            />
          </div>
        </th>
        {/* The "Legg til" button is only active if a value with an Id is selected */}
        <td className=" border-none">
          {id === "" ? (
            <button
              disabled
              className=" mt-8 h-8 sm:h-12 float-right text-white bg-disabled border-disabled border rounded-lg items-center text-xs sm:text-sm flex flex-row py-2 px-1 "
            >
              Legg til
            </button>
          ) : (
            <button
              onClick={onSubmitProduct}
              className=" mt-8 h-8 sm:h-12 float-right text-black border rounded-lg items-center text-xs sm:text-sm flex flex-row py-2 px-1 border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
            >
              Legg til
            </button>
          )}
        </td>
        {/* The cancel button */}
        <td className=" pr-2 border-none text-right  ">
          <button
            onClick={() => cancelProduct(false)}
            className=" mt-8 border-2 border-grey rounded-lg p-1 hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <XButtonIcon />
          </button>
        </td>
        <td className=" border-none bg-white "></td>
      </tr>
    </>
  )
}

export default AddNewProductComponent
