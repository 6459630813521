import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser"
import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import React, { useContext, useEffect } from "react"
import { QueryClient, useQuery } from "react-query"
import axios from "axios"
import { ConnectionContext } from "../CustomHooks/ConnectionContext"
import { loginRequest } from "../Security/authConfig"
import { fetchApi } from "../Common/WebApi-utils"

const GroupRoute1 = ({ component: Component, location, ...rest }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const { connection, setConnection } = useContext(ConnectionContext)

  const userConnectionQuery = useQuery(
    "userConnectionData1",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (data) => {
        setConnection(data)
        if (data !== undefined) {
          const role = data.some(
            (v) => v.accessRole === 1 || v.accessRole === 3 || v.accessRole === 4 || v.accessRole === 5
          )

          if (!role) {
            navigate("/minside/")
            return null
          }
        }
      },
      onError: (_error) => {
        // error.message
      },
    }
  )

  // useEffect(() => {
  //   if (account && inProgress === InteractionStatus.None) {
  //     const request = {
  //       ...loginRequest,
  //       account: account,
  //     }
  //     instance
  //       .acquireTokenSilent(request)
  //       .then((response) => {
  //         const accessToken = response.accessToken

  //         axios
  //           .get(process.env.REACT_APP_API_URL_APIM + "/Connections", {
  //             headers: {
  //               "Conent-Type": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Ocp-Apim-Subscription-Key": process.env.API_KEY,
  //             },
  //           })
  //           .then((response) => {
  //             setConnection(response.data)
  //             if (response.data !== undefined) {
  //               const role = response.data.some(
  //                 (v) => v.accessRole === 1 || v.accessRole === 3 || v.accessRole === 4 || v.accessRole === 5
  //               )

  //               if (!role) {
  //                 navigate("/minside/")
  //                 return null
  //               }
  //             }
  //           })
  //       })
  //       .catch((e) => {
  //         if (e instanceof InteractionRequiredAuthError) {
  //           instance.acquireTokenRedirect(request)
  //         }
  //       })
  //   }
  // }, [account, inProgress, instance])

  return <Component {...rest} />
}

export default GroupRoute1
