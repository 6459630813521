import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import SupportFaq from "../../../pages/faq"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"

const FaqContent = () => {
  return (
    <>
      <div>
        <MyPageLayout title="Ofte stilte spørsmål">
          <SupportFaq />
        </MyPageLayout>
      </div>
    </>
  )
}

function Faq() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <FaqContent />
    </MsalAuthenticationTemplate>
  )
}

export default Faq
