const AddNewCustomerButton = ({ prop, disabled, getId }) => {
  return (
    <>
      {disabled ? (
        <button
          disabled
          className=" whitespace-nowrap ml-0 sm:ml-8 mt-4 mb-6 sm:mb-12 items-center text-sm flex flex-row px-2 py-1 rounded-lg border border-disabled bg-disabled text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className=" w-5 h-5 sm:w-6 sm:h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
          Legg til kunde/pakkeri
        </button>
      ) : (
        <button
          onClick={() => {
            getId(prop.id)
            console.log(prop.id)
          }}
          className=" whitespace-nowrap ml-0 sm:ml-8 mt-4 mb-6 sm:mb-12 items-center text-sm flex flex-row px-2 py-1 rounded-lg border border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className=" w-5 h-5 sm:w-6 sm:h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
          Legg til kunde/pakkeri
        </button>
      )}
    </>
  )
}

export default AddNewCustomerButton
