import React, { useRef, useState } from "react"
import Moment from "moment"
import "moment-timezone"
import { closeNotification, fetchApi, patchApi, postApiAnonymous } from "../../Common/WebApi-utils"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { EventType, InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useQuery, useMutation, useQueryClient } from "react-query"
import Notification from "../../Graphics/Notification"
import MyPageLayout from "../../Layout/MyPageLayout"
import { ButtonStyle } from "../../Styling/Style"
import Consent from "./Consent"
import LoadingData from "../../Graphics/LoadingData"
import UpdatingData from "../../Graphics/UpdatingData"

/**
 * Denne komponenten brukes til å vise og redigere brukerprofil og samtykke.
 *
 */

const UserProfileContent = () => {
  const queryClient = useQueryClient()
  const userForm = useRef(null)
  const [toggleEdit, setToggleEdit] = useState(false)
  const [countryOptions, setcountryOptions] = useState([{}])
  const [showVerifyMobile, setshowVerifyMobile] = useState(false)
  const [isVerifyingMobile, setisVerifyingMobile] = useState(false)
  const [isMobileVerified, setisMobileVerified] = useState(false)
  const [showVerifyCode, setshowVerifyCode] = useState(false)
  const [mobileCode, setmobileCode] = useState("")
  const [mobileCodeSMS, setmobileCodeSMS] = useState("")
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const [mobileVerifiedDate, setmobileVerifiedDate] = useState("")
  const [showUpdateData, setShowUpdateData] = useState(false)

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  // Form
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobileNumber, setMobileNumber] = useState("")
  const [address1, setAddress1] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("")

  const [spanHidden, setSpanHidden] = useState({
    emailHidden: false,
    firstnameHidden: false,
    lastnameHidden: false,
    birthdateHidden: false,
    genderHidden: false,
    addressHidden: false,
    postalCodeHidden: false,
    cityHidden: false,
    countryHidden: false,
    mobileHidden: false,
  })

  const hideShowSpans = (value) => {
    setSpanHidden({
      emailHidden: value,
      firstnameHidden: value,
      lastnameHidden: value,
      birthdateHidden: value,
      genderHidden: value,
      addressHidden: value,
      postalCodeHidden: value,
      cityHidden: value,
      countryHidden: value,
      mobileHidden: value,
    })
  }

  const onToggleEdit = () => {
    setToggleEdit(!toggleEdit)
  }

  const userProfileQuery = useQuery(
    "userProfileData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Contacts", account, inProgress, instance).then((res) => res.data),
    {
      onSuccess: (data) => {
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setEmail(data.email)
        setMobileNumber(data.mobilePhone)
        setAddress1(data.address2.line1)
        setPostalCode(data.address2.postalCode)
        setCity(data.address2.city)
        setCountry(data.address2.country)
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )

  const mutationUpdate = useMutation(
    () => {
      const address2 = {
        line1: address1,
        postalCode: postalCode,
        city: city,
        country: country,
      }
      return patchApi(
        process.env.REACT_APP_API_URL_APIM + "/Contacts/me",
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          MobilePhone: mobileNumber,
          address2: address2,
        },
        account,
        inProgress,
        instance
      ).then((res) => res.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["userProfileData"])
        closeNotification(setNotification)
        setNotification({
          title: "Vellykket",
          message: "Informasjonen er oppdatert",
          color: "green",
          delay: 3000,
          showNotification: true,
        })
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
      onSettled: () => {
        setShowUpdateData(false)
        hideShowSpans(false)
        onToggleEdit()
      },
    }
  )

  const mutationVerifyMobile = useMutation(
    () => {
      const randomNr = Math.floor(1000 + Math.random() * 9000)
      setisVerifyingMobile(true)
      setmobileCodeSMS(randomNr.toString())

      const form = userForm.current
      const fieldValue = form.mobilePhone.value

      return postApiAnonymous(process.env.REACT_APP_API_URL_APIM + "/Twilio", {
        to: fieldValue,
        body: "Bekreftelseskode for Gartnerhallen AS: " + randomNr.toString(),
      })
    },
    {
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
        setisVerifyingMobile(false)
        setshowVerifyCode(false)
      },
      onSettled: () => {
        queryClient.invalidateQueries("userProfile")

        setshowVerifyMobile(false)
        setisVerifyingMobile(false)
        setshowVerifyCode(true)
      },
    }
  )

  const mutationVerifyCode = useMutation(
    () => {
      const form = userForm.current
      const fieldValue = form.mobileverify.value

      if (fieldValue !== mobileCodeSMS) {
        setNotification({
          title: "Koden stemmer ikke",
          message: "Vennlist prøv på nytt",
          color: "red",
          delay: 3000,
          showNotification: true,
        })
        setisMobileVerified(false)
        setshowVerifyCode(true)
      } else {
        const mobileVerifiedCRMDate = Moment()

        return patchApi(
          process.env.REACT_APP_API_URL_APIM + "/Contacts/me",
          { mobileVerified: mobileVerifiedCRMDate },
          account,
          inProgress,
          instance
        )
      }
    },
    {
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Vennlist prøv på nytt",
          color: "red",
          delay: 3000,
          showNotification: true,
        })
        setshowVerifyMobile(true)
        setisVerifyingMobile(false)
        setshowVerifyCode(true)
      },
      onSettled: () => {
        queryClient.invalidateQueries("userProfile")

        setshowVerifyMobile(false)
        setisMobileVerified(true)
        setshowVerifyCode(false)
      },
    }
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowUpdateData(true)
    mutationUpdate.mutate()
  }

  const onVerifyMobile = () => {
    mutationVerifyMobile.mutate()
  }

  const onVerifyCode = () => {
    mutationVerifyCode.mutate()
  }

  const onChangePassword = () => {
    instance.loginRedirect({
      authority:
        process.env.REACT_APP_AAD_B2C_INSTANCE +
        "" +
        process.env.REACT_APP_AAD_B2C_TENTANT +
        "/" +
        process.env.REACT_APP_AAD_B2C_CHANGEPASSWORDPOLICY,
    })
  }

  return userProfileQuery.isLoading ? (
    // // "Laster inn..."
    <LoadingData />
  ) : userProfileQuery.isError ? (
    userProfileQuery.error.message
  ) : (
    // <ConnectionContext.Consumer>
    <MyPageLayout title="Min profil">
      <div>{showUpdateData && <UpdatingData />}</div>
      <div>{notification.showNotification && <Notification {...notification} />}</div>
      <div className="w-auto  lg:w-84  xl:w-116 2xl:w-176 ">
        <div className="max-w-7xl   mb-20  lg:px-8 xl:max-w-6xl ">
          {toggleEdit ? (
            <div id="editDiv" className="sm:mx-8 sm:w-63 md:mx-2 lg:mx-8">
              <h2 className="text-header22 font-medium leading-normal text-gray-900">Endre mine opplysninger</h2>
              <form onSubmit={handleSubmit} className="space-y-8 md:mx-4 ">
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div className="space-y-6 sm:space-y-5 mx-4">
                      <div className="sm:grid sm:grid-cols-3  sm:items-start sm:border-tsm:pt-5">
                        <label
                          htmlFor="first-name"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Fornavn
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            defaultValue={userProfileQuery.data.firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="last-name"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Etternavn
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            defaultValue={userProfileQuery.data.lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="mobile"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Mobil
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="mobile"
                            defaultValue={userProfileQuery.data.mobilePhone}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            name="mobile"
                            type="text"
                            autoComplete=""
                            className="block max-w-lg w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="street-address"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Gateadresse
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            defaultValue={userProfileQuery.data.address2?.line1}
                            onChange={(e) => setAddress1(e.target.value)}
                            name="street-address"
                            id="street-address"
                            autoComplete="street-address"
                            className="block max-w-lg w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="postal-code"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Postnummer
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            defaultValue={userProfileQuery.data.address2?.postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="city"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Poststed
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            defaultValue={userProfileQuery.data.address2?.city}
                            onChange={(e) => setCity(e.target.value)}
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="country"
                          className="block text-body18 font-medium leading-tight text-gray-500 sm:mt-px sm:pt-2"
                        >
                          Land
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            id="country"
                            defaultValue={userProfileQuery.data.address2?.country}
                            onChange={(e) => setCountry(e.target.value)}
                            name="country"
                            type="text"
                            autoComplete=""
                            className="block max-w-lg w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:text-sm border-gray-300 rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-10 pb-16 md:mx-4">
                  <div className="flex justify-end ">
                    <button
                      onClick={onToggleEdit}
                      type="button"
                      className="bg-white py-2 mx-4 px-6 border border-gray-300 rounded-full text-button15 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    >
                      Avbryt
                    </button>
                    <button type="submit" className={ButtonStyle} onClick={handleSubmit}>
                      Lagre opplysninger
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div id="viewDiv" className="sm:mx-8 sm:w-63 md:mx-4 lg:mx-8">
              <div className="relative pb-20 sm:px-0 lg:pb-28 md:max-w-3xl lg:max-w-7xl ">
                {/* <div className="inline-flex flex-col space-y-8 items-start justify-start pl-8"> */}
                <h2 className="text-header22 font-medium leading-normal text-gray-900">Mine opplysninger</h2>
                <div className="mt-8 ">
                  <dl className="divide-y divide-gray-200 ">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-body18 font-medium text-gray-500">Fornavn</dt>
                      <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                        {" "}
                        {userProfileQuery.data.firstName}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-body18 font-medium text-gray-500">Etternavn</dt>
                      <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                        {" "}
                        {userProfileQuery.data.lastName}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-body18 font-medium text-gray-500">E-post</dt>
                      <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                        {" "}
                        {userProfileQuery.data.emailAddress}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-body18 font-medium text-gray-500">Mobil</dt>
                      <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                        {" "}
                        {userProfileQuery.data.mobilePhone}
                      </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-body18 font-medium text-gray-500">Adresse</dt>
                      <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                        {userProfileQuery.data.address2?.line1}
                        <br />
                        {userProfileQuery.data.address2?.postalCode} {userProfileQuery.data.address2?.city}
                        <br />
                        {userProfileQuery.data.address2?.country}
                      </dd>
                    </div>
                    <div className="border-t border-gray-200"></div>
                  </dl>
                </div>

                <div>
                  <div className="mt-4   flex justify-center md:justify-end md:mr-8 lg:mr-0">
                    <button onClick={onToggleEdit} type="button" className={ButtonStyle}>
                      Endre opplysninger
                    </button>
                  </div>

                  {/* Mitt passord */}
                  <div className=" my-16">
                    <div className="flex flex-col items-start justify-start pb-4  max-w-116">
                      <h2 className="text-header22 font-medium leading-normal text-gray-900">Mitt passord</h2>
                      <p className="text-body18 leading-6 text-gray-500 ">Endre ditt passord til Min side.</p>
                    </div>
                    <div className="flex justify-center md:justify-end  md:mr-8 lg:mr-0">
                      <button onClick={onChangePassword} type="button" className={ButtonStyle}>
                        Endre passord
                      </button>
                    </div>
                  </div>

                  {/* Samtykker */}
                  <div className=" max-w-xs sm:max-w-sm md:max-w-md lg:max-w-3xl xl:w-240 md:w-63 ">
                    <Consent />
                    {/* Nyhetsbrev  - tatt ut av scope foreløpig */}
                    {/* <Newsletter /> */}
                  </div>
                </div>
              </div>
            </div>

            // </div>
          )}
        </div>
      </div>
    </MyPageLayout>
    // </ConnectionContext.Consumer>
  )
}

function UserProfile() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <UserProfileContent />
    </MsalAuthenticationTemplate>
  )
}

export default UserProfile
