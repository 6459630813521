import { useContext, useEffect, useState } from "react"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError, InteractionStatus, InteractionType, RedirectRequest } from "@azure/msal-browser"
import { ConnectionContext } from "../../CustomHooks/ConnectionContext"
import { QueryClient } from "react-query"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import axios from "axios"
import { GreenBadgeButton, ShortCutProduction, ShortCutInvoice } from "../../Graphics/Badge"
import HomeDefault from "./HomeDefault"

/**
 * Denne er Start komponenten for MinSide. Den lastes etter /minside/ adressen.
 * Og den danner slikt sett hovedgrunnlaget for MinSide
 * Den skal være minst mulig, og helst kalle på andre komponenter som dashboards osv.
 */

const HomeContent = (data) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const { connection, setConnection } = useContext(ConnectionContext)
  const [showMiniHome, setShowMiniHome] = useState(true)

  useEffect(() => {
    if (connection !== undefined && Object.keys(connection).length > 0) {
      const bRole = connection.some((v) => v.accessRole === 2)
      const adceRole = connection.some(
        (v) => v.accessRole === 1 || v.accessRole === 3 || v.accessRole === 4 || v.accessRole === 5
      )

      if (bRole) setShowMiniHome(false)
      if (adceRole) setShowMiniHome(true)
    } else {
      setShowMiniHome(false)
    }
  }, [connection])

  return (
    <div>
      <MyPageLayout title="Min side">
        {showMiniHome ? (
          <div className="w-auto ">
            <div className="max-w-7xl   mb-20  lg:px-8">
              <div className="grid grid-cols-1 mx-auto gap-10 lg:mr-0 lg:grid-cols-2 2xl:gap-32">
                <div className="mt-2 grid grid-cols-1 gap-0 mb-6  ">
                  <h2 className=" text-header22 leading-6 font-medium mb-4 text-gray-900">Snarveier</h2>

                  <ShortCutInvoice title="Mine fakturaer" url="/minside/okonomi/fakturaer" />
                  <ShortCutProduction title="Min produksjonsplan" url="/minside/produksjonsplan" />
                  {/* <div className="flex">
                    <div className="mt-2 grid grid-cols-1 gap-0   ">
                      <h2 className="text-header22 leading-6 font-medium text-gray-900 mt-20">Produksjonsverktøy</h2>
                      <div className="flex  mt-8 gap-2 justify-start">
                        <GreenBadgeButton
                          title="GH levering"
                          url={`https://medlem.gartnerhallen.no/menystyring/ghlevering/ghleveringweb/logginn.aspx`}
                        />
                        <GreenBadgeButton
                          title="GH innmelding"
                          url={`https://medlem.gartnerhallen.no/menystyring/prognose/registrering/Default.aspx`}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <HomeDefault />
          </>
        )}
      </MyPageLayout>
    </div>
  )
}

function Home() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <HomeContent />
    </MsalAuthenticationTemplate>
  )
}

export default Home
