import { useAccount, useMsal } from "@azure/msal-react"
import { useContext, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { deleteApi, fetchApi } from "../../../../Common/WebApi-utils"
import { redButton } from "../../../../Styling/ButtonStyles"
import { GetProfile } from "../ReactQueries"
import { UserContext } from "../UserContext"
import { XButtonIcon } from "../../../../Graphics/IconsMyPage"
import NotificationAlert from "../../../../Graphics/NotificationAlert"

// If the X button is pressed from ReadRowComponent.tsx the table row will render this component instead and it will allow the user to delete the data for the current year.

const EditRowComponent = ({ prop, i, removeClick }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount] = useContext(UserContext)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const getMyPlans = useQuery(
    "myPlansData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/ProductionPlan/MyWhishes?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        // error,message
      },
    }
  )

  const DeletePlan = useMutation(
    async (id) => {
      deleteApi(
        process.env.REACT_APP_API_URL_APIM + "/ProductionPlan/DeleteProductionPlan?prodPlanId=" + id,
        account,
        inProgress,
        instance
      ).then(() => queryClient.invalidateQueries(["myPlansData"]))
    },
    {
      onError: (_error) => {
        setLoadingDone(true)
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const onDeletePlan = (id) => DeletePlan.mutate(id)

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <tr key={i} className=" text-xs sm:text-base ">
        {/* No active wish for this year */}
        {prop?.id === "00000000-0000-0000-0000-000000000000" ? (
          <>
            <th
              colSpan={3}
              className=" sm:pl-10 py-4 text-left font-normal border-l-2 border-t-2 border-b-2 border-tomat-500  "
            >
              Ingen aktivt ønske for i år til{" "}
              <span className="uppercase font-semibold ">{prop?.customer?.customerName}</span>
            </th>
            <td className="hidden sm:table-cell sm:border-t-2 sm:border-b-2 sm:border-tomat-500 "></td>
            <td className=" text-xs sm:text-base pr-1 sm:pr-2 border-t-2 border-b-2 border-r-2 border-tomat-500 ">
              <button
                onClick={() => removeClick(null)}
                className=" pr-2 mr-0 sm:mr-4 float-right underline underline-offset-8 hover:opacity-30"
              >
                Avbryt
              </button>
            </td>
            <button
              disabled
              className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 rounded-lg md:flex md:flex-row md:justify-center md:items-center bg-disabled text-white border-2 border-disabled"
            >
              <svg
                className=" mt-2 md:mt-1 w-5 h-5 sm:w-6 sm:h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.2267 11.6002L13.0467 3.78017C13.5818 3.28158 13.802 2.53069 13.6211 1.82207C13.4401 1.11344 12.8868 0.560112 12.1781 0.379142C11.4695 0.198173 10.7186 0.418429 10.22 0.953504L2.38003 8.7935C2.32989 8.84505 2.28725 8.9034 2.25337 8.96684L0.400034 12.7068C0.298686 12.9137 0.311289 13.1582 0.433367 13.3535C0.555444 13.5488 0.7697 13.6673 1.00003 13.6668C1.11003 13.6642 1.21768 13.6345 1.31337 13.5802L5.05337 11.7268C5.1168 11.693 5.17515 11.6503 5.2267 11.6002ZM13 13.6668C13.3682 13.6668 13.6667 13.3684 13.6667 13.0002C13.6667 12.632 13.3682 12.3335 13 12.3335H7.00003C6.63184 12.3335 6.33337 12.632 6.33337 13.0002C6.33337 13.3684 6.63184 13.6668 7.00003 13.6668H13ZM9.88003 5.06017L8.94003 4.12017L3.42003 9.66684L2.49337 11.5335L4.33337 10.5802L9.88003 5.06017ZM11.6434 1.666C11.8261 1.666 12.0008 1.74102 12.1267 1.8735C12.2566 2.00209 12.328 2.17838 12.3243 2.36113C12.3205 2.54389 12.2418 2.71707 12.1067 2.84017L10.82 4.12017L9.88003 3.18017L11.16 1.8735C11.2859 1.74102 11.4606 1.666 11.6434 1.666Z"
                  fill="white"
                />
              </svg>
              <span className=" hidden lg:flex sm:ml-2 ">Rediger ønske</span>
            </button>
          </>
        ) : (
          <>
            {/* Active wish for this year that can be deleted */}
            <th className=" sm:pl-10 py-4 text-left font-normal border-l-2 border-t-2 border-b-2 border-tomat-500 whitespace-nowrap ">
              Slette <span className="uppercase font-semibold ">{prop?.customer?.customerName}</span>?
            </th>
            <td colSpan={2} className=" pr-4 sm:pr-0 border-t-2 border-b-2 border-tomat-500 ">
              <button
                onClick={() => {
                  onDeletePlan(prop.id)
                  setLoadingDone(false)
                }}
                className={redButton + " float-right items-center text-xs sm:text-sm flex flex-row  "}
              >
                <XButtonIcon />
                Ja, slett
              </button>
            </td>
            <td className="hidden sm:table-cell sm:border-t-2 sm:border-b-2 sm:border-tomat-500 "></td>
            <td className=" text-xs sm:text-base pr-1 sm:pr-2 border-t-2 border-b-2 border-r-2 border-tomat-500 ">
              <button
                onClick={() => removeClick(null)}
                className=" mr-0 sm:mr-4 float-right underline underline-offset-8 hover:opacity-30"
              >
                Avbryt
              </button>
            </td>
            <button
              disabled
              className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 rounded-lg md:flex md:flex-row md:justify-center md:items-center bg-disabled text-white border-2 border-disabled"
            >
              <svg
                className=" mt-2 md:mt-1 w-5 h-5 sm:w-6 sm:h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.2267 11.6002L13.0467 3.78017C13.5818 3.28158 13.802 2.53069 13.6211 1.82207C13.4401 1.11344 12.8868 0.560112 12.1781 0.379142C11.4695 0.198173 10.7186 0.418429 10.22 0.953504L2.38003 8.7935C2.32989 8.84505 2.28725 8.9034 2.25337 8.96684L0.400034 12.7068C0.298686 12.9137 0.311289 13.1582 0.433367 13.3535C0.555444 13.5488 0.7697 13.6673 1.00003 13.6668C1.11003 13.6642 1.21768 13.6345 1.31337 13.5802L5.05337 11.7268C5.1168 11.693 5.17515 11.6503 5.2267 11.6002ZM13 13.6668C13.3682 13.6668 13.6667 13.3684 13.6667 13.0002C13.6667 12.632 13.3682 12.3335 13 12.3335H7.00003C6.63184 12.3335 6.33337 12.632 6.33337 13.0002C6.33337 13.3684 6.63184 13.6668 7.00003 13.6668H13ZM9.88003 5.06017L8.94003 4.12017L3.42003 9.66684L2.49337 11.5335L4.33337 10.5802L9.88003 5.06017ZM11.6434 1.666C11.8261 1.666 12.0008 1.74102 12.1267 1.8735C12.2566 2.00209 12.328 2.17838 12.3243 2.36113C12.3205 2.54389 12.2418 2.71707 12.1067 2.84017L10.82 4.12017L9.88003 3.18017L11.16 1.8735C11.2859 1.74102 11.4606 1.666 11.6434 1.666Z"
                  fill="white"
                />
              </svg>
              <span className=" hidden lg:flex sm:ml-2 ">Rediger ønske</span>
            </button>
          </>
        )}
      </tr>
    </>
  )
}

export default EditRowComponent
