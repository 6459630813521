import { useAccount, useMsal } from "@azure/msal-react"
import { navigate } from "gatsby"
import { useContext, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { useMutation, useQueryClient } from "react-query"
import { postApi } from "../../../../Common/WebApi-utils"
import { productUnit, year } from "../Maps"
import { GetProfile } from "../ReactQueries"
import { UserContext } from "../UserContext"
import { PenIcon, XButtonIcon } from "../../../../Graphics/IconsMyPage"
import NotificationAlert from "../../../../Graphics/NotificationAlert"

// A table row that shows the customer and the planWeek values from prev year and current year.

export type newPlan = {
  Producer: string
  Vendor: string
  Customer: string
  PlanProduct: string
  ProdPlanYearOption: number | undefined
  ProdPlanType: number
  ProdPlanStatus: number
}

const ReadRowComponent = ({ prop, i, removeClick, sumQuantity }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const queryClient = useQueryClient()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount] = useContext(UserContext)
  const [yearOption, setYearOption] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString()

  const toWeek = prop.planProduct.toWeek.toString().slice(-3)

  useEffect(() => {
    if (parseInt(toWeek) > 100) {
      setYearOption(thisYearString + "-" + nextYearString)
    } else {
      setYearOption(thisYearString)
    }
  }, [toWeek])

  const CreateNewPlan = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: (res) => {
        queryClient.invalidateQueries("myPlansData")
        navigate(
          "/minside/produksjonsonsker/planlegger?id=" +
            res +
            "&lastPlan=" +
            prop?.lastYearPlanId +
            "&unit=" +
            prop?.planProduct?.planProductUnit +
            "&customer=" +
            prop?.customer?.customerName +
            "&product=" +
            prop?.planProduct?.planProductName +
            "&sum=" +
            prop?.sumQuantity +
            "&totalSum=" +
            sumQuantity
        )
      },
    }
  )

  const handleClick = () => {
    if (prop.id === "00000000-0000-0000-0000-000000000000") {
      // If there are no active wishes for this year on the current plan, it needs to run a post. Else navigate to plan.
      const postNewPlan: newPlan = {
        Producer: activeAccount,
        Vendor: activeVendor,
        Customer: prop?.customer?.id,
        PlanProduct: prop?.planProduct?.id,
        ProdPlanYearOption: year.get(yearOption),
        ProdPlanType: 292460000,
        ProdPlanStatus: 292460000,
      }
      setLoadingDone(false)
      CreateNewPlan.mutate(postNewPlan)
    } else if (prop.lastYearPlanId === undefined) {
      navigate(
        "/minside/produksjonsonsker/planlegger?id=" +
          prop?.id +
          "&unit=" +
          prop?.planProduct?.planProductUnit +
          "&customer=" +
          prop?.customer?.customerName +
          "&product=" +
          prop?.planProduct?.planProductName +
          "&sum=" +
          prop?.sumQuantity +
          "&totalSum=" +
          sumQuantity
      )
    } else {
      navigate(
        "/minside/produksjonsonsker/planlegger?id=" +
          prop?.id +
          "&lastPlan=" +
          prop?.lastYearPlanId +
          "&unit=" +
          prop?.planProduct?.planProductUnit +
          "&customer=" +
          prop?.customer?.customerName +
          "&product=" +
          prop?.planProduct?.planProductName +
          "&sum=" +
          prop?.sumQuantity +
          "&totalSum=" +
          sumQuantity
      )
    }
  }

  return (
    <>
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      {prop.customer !== undefined ? (
        <tr key={i} className=" text-xs sm:text-base whitespace-nowrap ">
          <td className=" pl-2 sm:pl-10 py-4 border-b border-grey uppercase">{prop?.customer?.customerName}</td>
          <td className=" hidden min-[355px]:table-cell border-b border-grey text-center sm:text-right text-ghGreen-600 ">
            <NumericFormat displayType="text" value={prop?.lastYearPlan} thousandSeparator={" "} />{" "}
            {productUnit.get(prop?.planProduct?.planProductUnit)}
          </td>
          <td className=" hidden sm:table-cell text-center sm:text-right border-b border-grey text-ghGreen-600 "></td>
          <td className="  text-center sm:text-right border-b border-grey ">
            <NumericFormat displayType="text" value={prop?.sumQuantity} thousandSeparator={" "} />{" "}
            {productUnit.get(prop?.planProduct?.planProductUnit)}
          </td>
          {/* X button */}
          <td className="  text-center sm:text-right border-b border-grey  pr-2">
            <button
              className="border-2 border-grey hover:border-black active:bg-disabled rounded-lg p-1 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
              onClick={() => removeClick(prop.id)}
            >
              <XButtonIcon />
            </button>
          </td>
          {/* Edit button  */}
          <button
            onClick={handleClick}
            className=" mt-2 sm:mt-3 md:mt-3 md:py-0.5 px-1 ml-1 md:ml-4 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <PenIcon />
            <span className=" hidden lg:flex sm:ml-2 ">Rediger ønske</span>
          </button>
        </tr>
      ) : null}
    </>
  )
}

export default ReadRowComponent
