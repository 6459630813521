import { XButtonIcon } from "../../../../Graphics/IconsMyPage"
import { productUnit } from "../Maps"
import { NumericFormat } from "react-number-format"

// Table row component that shows each of the active products and the sum for all of its wishes.

const ProductComponent = ({ prop, i, sumQuantity, lastYearPlan }) => {
  return (
    <>
      <tr key={i} className=" bg-blue bg-opacity-20 mt-2 text-xs sm:text-base whitespace-nowrap ">
        <th className=" pl-1 sm:pl-5 py-4 text-left font-semibold border-b border-grey uppercase">
          {prop.planProductName}
        </th>
        <td className=" hidden min-[355px]:table-cell border-b border-grey text-center sm:text-right text-ghGreen-600 ">
          <NumericFormat displayType="text" value={lastYearPlan} thousandSeparator={" "} />{" "}
          {productUnit.get(prop.prodPlan[0].planProduct.planProductUnit)}
        </td>
        {/* <td className=" text-center sm:text-right border-b border-grey text-ghGreen-600 ">
          <NumericFormat displayType="text" value={lastYearDelivered} thousandSeparator={" "} />{" "}
          {productUnit.get(prop.prodPlan[0].planProduct.planProductUnit)}
        </td> */}
        <td className=" hidden sm:table-cell text-center sm:text-right border-b border-grey text-ghGreen-600 "></td>
        <td className="  text-center sm:text-right border-b border-grey ">
          <NumericFormat displayType="text" value={sumQuantity} thousandSeparator={" "} />{" "}
          {productUnit.get(prop.prodPlan[0].planProduct.planProductUnit)}
        </td>
        <td className=" text-center sm:text-right border-b border-grey pr-2 ">
          {/* <button
            className="border-2 border-grey hover:border-black active:bg-disabled rounded-lg p-1 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
            onClick={() => console.log("Remove product")}
          >
            <XButtonIcon />
          </button> */}
        </td>
        <td className="hidden sm:table-cell bg-white border-none"></td>
      </tr>
    </>
  )
}

export default ProductComponent
