import BamaPlansTableContent from "./BamaPlansTableContent"

const BamaPlansTable = ({ plans, activeVendor, weekNumber, setValues }) => {
  return (
    <>
      <table className=" table-auto w-auto">
        <thead className=" bg-ghGreen-100">
          <tr className="  text-xs sm:text-base ">
            <th className=" border-none"></th>
            {/* This year */}
            <td className=" text-center border-none py-4 ">Plan</td>
            {/* Amount */}
            <td className=" text-center border-none py-4 ">Innmeldt</td>
            {/* Empty space */}
            <td className=" text-center border-none py-4 "></td>
          </tr>
        </thead>
        <tbody className="">
          {plans?.data?.map((planProduct) => {
            return (
              <BamaPlansTableContent
                planProduct={planProduct}
                weekNumber={weekNumber}
                activeVendor={activeVendor}
                setValues={setValues}
              />
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default BamaPlansTable
