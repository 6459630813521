const SaveButton = ({ onClick, changedComment, changedValues }) => {
  return (
    <>
      {changedComment === true || changedValues === true ? (
        <button
          onClick={onClick}
          className="  px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0 disabled:bg-disbled disabled:border-disbled disabled:text-white focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500 "
        >
          Lagre ønske
        </button>
      ) : (
        <button
          onClick={onClick}
          disabled
          className="  px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 rounded-xl bg-disabled border-disabled text-white "
        >
          Lagre ønske
        </button>
      )}
    </>
  )
}

export default SaveButton
