// siste versjon
import { useState, useEffect } from "react"
import MyPageLayout from "../../Layout/MyPageLayout"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import InvoiceFilter from "../../CategoryFilters/InvoiceFilter"
import { useQuery } from "react-query"
import { fetchApi } from "../../Common/WebApi-utils"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { loginRequest } from "../../Security/authConfig"
import LoadingData from "../../Graphics/LoadingData"
import {
  SalesInvoiceFilterSection,
  VendorInvoiceFilterSection,
  SalesInvoiceFilterSectionTable,
  VenorInvoiceFilterSectionTable,
} from "../../Sections/InvoiceFilterSection"
import useWindowSize from "../../CustomHooks/useWindowSize"
import Notification from "../../Graphics/Notification"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const InvoiceContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const [sortType, setSortType] = useState("alt")
  const [sortOptionTypeName, setSortOptionTypeName] = useState("alle fakturaer")
  const [smallScreen, setSmallScreen] = useState(true)
  const windowsize = useWindowSize()

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  // For showing powerbi-reports made for small screens
  useEffect(() => {
    if (windowsize.width > 1024) {
      setSmallScreen(false)
    }
  }, [windowsize])

  const userInvoiceQuery = useQuery(
    "userInvoiceData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Invoices", account, inProgress, instance).then((res) => res.data),
    {
      // onSuccess: (data) => {},
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )

  return userInvoiceQuery.isLoading ? (
    // "Laster inn..."
    <LoadingData />
  ) : userInvoiceQuery.isError ? (
    userInvoiceQuery.error.message
  ) : (
    <>
      <div>
        <MyPageLayout title="Fakturaer">
          <div>{notification.showNotification && <Notification {...notification} />}</div>
          <div className="w-auto md:w-63 lg:w-84  xl:w-116 2xl:w-176 mb-20 ">
            <div className="max-w-7xl min-w-full md:w-auto mx-auto px-4  sm:px-6 lg:px-8">
              <div className="max-w-4xl  w-full  md:w-auto xl:max-w-6xl">
                <InvoiceFilter setSortType={setSortType} setOptionName={setSortOptionTypeName} />
                <div className=" text-gray-600">Viser {sortOptionTypeName.toLowerCase()}</div>
                <div className="mt-2 space-y-1  ">
                  {userInvoiceQuery.data.map((firm, index) => (
                    <Disclosure
                      as="div"
                      defaultOpen={index === 0}
                      key={firm.account.name}
                      className="pt-6 pb-6  border-t border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <div className="text-lg">
                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                              <span className="font-medium text-gray-900">{firm.account.name}</span>
                              <span className="ml-6 h-7 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </div>
                          <Disclosure.Panel as="dd" className="mt-2 ">
                            <div className=" ">
                              {smallScreen ? (
                                <div className="relative pb-12 sm:px-0 lg:pb-16 sm:max-w-md md:max-w-lg xl:max-w-4xl grid grid-cols-1 gap-2 xl:grid-cols-2 lg:gap-32 2xl:gap-48 lg:gap-y-8 xl:gap-y-8 2xl:gap-y-8 ">
                                  {sortType === "alt" && (
                                    <>
                                      {firm?.invoiceSalesHeadList.map((invoiceHead, jndex) => (
                                        <SalesInvoiceFilterSection
                                          isSales={true}
                                          invoiceNumber={invoiceHead?.invoiceNumber}
                                          vendorInvoiceNumber={""}
                                          amount={invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount}
                                          customerNumber={invoiceHead?.invoiceCustomerAccountNumber}
                                          invoiceDueDate={invoiceHead.dueDate}
                                          invoiceDate={invoiceHead.invoiceDate}
                                          ledgerVoucher={invoiceHead?.ledgerVoucher}
                                          key={jndex}
                                        />
                                      ))}

                                      {firm?.invoiceVendorHeadList?.map((invoiceHead, jndex) => (
                                        <VendorInvoiceFilterSection
                                          invoiceNumber={invoiceHead?.invoiceNumber}
                                          headerReference={invoiceHead?.headerReference}
                                          amount={invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount}
                                          customerNumber={invoiceHead?.vendorAccount}
                                          invoiceDueDate={invoiceHead.invoiceDueDate}
                                          key={jndex}
                                        />
                                      ))}
                                    </>
                                  )}
                                  {/* Kjøpsfakturaer - sett fra virksomheten - hva er kjøpt  */}
                                  {sortType === "kunde" && (
                                    <>
                                      {firm?.invoiceSalesHeadList
                                        ?.filter((invoice) => invoice.invoiceCustomerAccountNumber)
                                        .map((invoiceHead, jndex) => (
                                          <SalesInvoiceFilterSection
                                            invoiceNumber={invoiceHead?.invoiceNumber}
                                            amount={invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount}
                                            customerNumber={invoiceHead?.invoiceCustomerAccountNumber}
                                            invoiceDueDate={invoiceHead.dueDate}
                                            invoiceDate={invoiceHead.invoiceDate}
                                            ledgerVoucher={invoiceHead?.ledgerVoucher}
                                            key={jndex}
                                          />
                                        ))}
                                    </>
                                  )}
                                  {/* Salgsfakturaer  - sett fra virksomheten - hva er solgt */}
                                  {sortType === "leverandor" && (
                                    <>
                                      {firm?.invoiceVendorHeadList?.map((invoiceHead, jndex) => (
                                        <VendorInvoiceFilterSection
                                          invoiceNumber={invoiceHead?.invoiceNumber}
                                          headerReference={invoiceHead?.headerReference}
                                          amount={invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount}
                                          customerNumber={invoiceHead?.vendorAccount}
                                          invoiceDueDate={invoiceHead.invoiceDueDate}
                                          key={jndex}
                                        />
                                      ))}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div className="mt-8 lg:mr-12 lg:-ml-12 flex flex-col">
                                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                      <div className="overflow-hidden  md:rounded-lg">
                                        <table className="min-w-full">
                                          <thead className="bg-white">
                                            <tr>
                                              <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-right text-body18 font-medium text-gray-500 sm:pl-6"
                                              >
                                                Konto-id
                                              </th>
                                              <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-right text-body18 font-medium text-gray-500 sm:pl-6"
                                              >
                                                Fakturanummer
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-3 py-3.5 text-right text-body18 font-medium text-gray-500"
                                              >
                                                Forfall
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-3 py-3.5 text-right text-body18 font-medium text-gray-500"
                                              >
                                                Beløp
                                              </th>
                                              <th
                                                scope="col"
                                                className="px-3 py-3.5 text-right text-body18 font-medium text-gray-500"
                                              >
                                                Fakturakopi
                                              </th>
                                              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="bg-white">
                                            {sortType === "alt" && (
                                              <>
                                                {firm?.invoiceSalesHeadList.map((invoiceHead, jndex) => (
                                                  <SalesInvoiceFilterSectionTable
                                                    invoiceNumber={invoiceHead?.invoiceNumber}
                                                    amount={
                                                      invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount
                                                    }
                                                    customerNumber={invoiceHead?.invoiceCustomerAccountNumber}
                                                    invoiceDueDate={invoiceHead.dueDate}
                                                    key={jndex}
                                                    invoiceDate={invoiceHead?.invoiceDate}
                                                    ledgerVoucher={invoiceHead?.ledgerVoucher}
                                                  />
                                                ))}
                                                {firm?.invoiceVendorHeadList?.map((invoiceHead, jndex) => (
                                                  <VenorInvoiceFilterSectionTable
                                                    invoiceNumber={invoiceHead?.invoiceNumber}
                                                    headerReference={invoiceHead?.headerReference}
                                                    amount={
                                                      invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount
                                                    }
                                                    customerNumber={invoiceHead?.vendorAccount}
                                                    invoiceDueDate={invoiceHead.invoiceDueDate}
                                                    key={jndex}
                                                  />
                                                ))}
                                              </>
                                            )}
                                            {/* Kjøpsfakturaer - sett fra virksomheten - hva er kjøpt  */}
                                            {sortType === "kunde" && (
                                              <>
                                                {firm?.invoiceSalesHeadList
                                                  ?.filter((invoice) => invoice.invoiceCustomerAccountNumber)
                                                  .map((invoiceHead, jndex) => (
                                                    <SalesInvoiceFilterSectionTable
                                                      invoiceNumber={invoiceHead?.invoiceNumber}
                                                      amount={
                                                        invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount
                                                      }
                                                      customerNumber={invoiceHead?.invoiceCustomerAccountNumber}
                                                      invoiceDueDate={invoiceHead.dueDate}
                                                      key={jndex}
                                                      invoiceDate={invoiceHead?.invoiceDate}
                                                      ledgerVoucher={invoiceHead?.ledgerVoucher}
                                                    />
                                                  ))}
                                              </>
                                            )}

                                            {/* Salgsfakturaer  - sett fra virksomheten - hva er solgt */}
                                            {sortType === "leverandor" && (
                                              <>
                                                {firm?.invoiceVendorHeadList?.map((invoiceHead, jndex) => (
                                                  <VenorInvoiceFilterSectionTable
                                                    invoiceNumber={invoiceHead?.invoiceNumber}
                                                    headerReference={invoiceHead?.headerReference}
                                                    amount={
                                                      invoiceHead?.totalInvoiceAmount - invoiceHead?.totalTaxAmount
                                                    }
                                                    customerNumber={invoiceHead?.vendorAccount}
                                                    invoiceDueDate={invoiceHead.invoiceDueDate}
                                                    key={jndex}
                                                  />
                                                ))}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </MyPageLayout>
      </div>
    </>
  )
}

function Invoice() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <InvoiceContent />
    </MsalAuthenticationTemplate>
  )
}

export default Invoice
