import { InteractionType } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"

const MessagesContent = () => {
  return (
    <>
      <MyPageLayout title="Meldinger">
        <div>Messages</div>
      </MyPageLayout>
    </>
  )
}

function Messages() {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <MessagesContent />
    </MsalAuthenticationTemplate>
  )
}

export default Messages
