import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useQueryParam, StringParam, NumberParam } from "use-query-params"
import { useState } from "react"
import AcceptPlansComponent from "./Components/AcceptPlans/AcceptPlansComponent"
import { PlanLoading } from "./Components/PlanLoading"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const AppectPlanContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [saved, setSaved] = useState(false)
  const [prodPlanId, setProdPlanId] = useQueryParam("id", StringParam)
  const [customer, setCustomer] = useQueryParam("customer", StringParam)
  const [product, setProduct] = useQueryParam("product", StringParam)
  const [sum, setSum] = useQueryParam("sum", NumberParam)
  const [totalSum, setTotalSum] = useQueryParam("totalSum", NumberParam)
  const [unit, setUnit] = useQueryParam("unit", NumberParam)
  const [lastYearPlanId, setLastYearPlanId] = useQueryParam("lastPlan", StringParam)
  const [companyId, setCompanyId] = useQueryParam("c", StringParam)
  const [planProductId, setPlanProductId] = useQueryParam("pp", StringParam)
  const [hasTerms, setHasTerms] = useQueryParam("terms", StringParam)
  const [loadingDone, setLoadingDone] = useState(false)

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        <div className="flex flex-row font-sans mt-6 sm:mt-0 ">
          <Link to="/minside/produksjonsplaner" className=" flex flex-row ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            <p className="underline underline-offset-2 decoration-tomat-500">Tilbake til mine produksjonsplaner</p>
          </Link>
        </div>
        <div>
          <h1 className=" mt-8 mb-10 text-3xl">
            {product} til {customer}
          </h1>
        </div>
        {/* Component */}
        <AcceptPlansComponent
          prodPlanId={prodPlanId}
          lastYearPlanId={lastYearPlanId}
          setLoadingDone={setLoadingDone}
          product={product}
          customer={customer}
          sum={sum}
          totalSum={totalSum}
          unit={unit}
          companyId={companyId}
          planProductId={planProductId}
          hasTerms={hasTerms}
        />
      </MyPageLayout>
    </>
  )
}

function AcceptPlan() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <AppectPlanContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default AcceptPlan
