import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import moment from "moment"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { fetchApi } from "../../Common/WebApi-utils"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { PlanLoading } from "./Components/PlanLoading"
import TableComponent from "./Components/ProductPlanner/TableComponent"
import { GetPortalSettingsText, GetProfile } from "./Components/ReactQueries"
import { UserContext } from "./Components/UserContext"
import "moment/locale/nb"
import GhConditionModal from "./Modal/GhConditionModal"
import MyVendorComponentWithConditions from "./Components/ProductPlanner/MyVendorCompoentWithConditions"
import { navigate } from "gatsby"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const ProductionWishesContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [refreshPlans, setRefreshPlans] = useState(false)
  const [refreshVendor, setRefreshVendor] = useState(false)
  const [loadingDone, setLoadingDone] = useState(false)
  const currentDate = new Date(userProfile?.data?.today)
  const thisMonth = currentDate.getMonth()
  const thisYear = thisMonth >= 7 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString().slice(2)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [showButtons, setShowButtons] = useState(false)
  moment.locale("nb")

  console.log(userProfile?.data?.today)
  console.log(currentDate)
  console.log(thisMonth)
  console.log(thisYear)

  const getSettingKeys = useQuery(
    "settingKeys",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/SettingKeys/ProductionPlanner",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userProfile.isSuccess,
      onSuccess: (data) => {
        if (data?.isProductionPlanning === false) {
          navigate("/minside/")
        }
      },
      onError: (_error) => {
        navigate("/minside/")
      },
    }
  )

  const introText = portalSettingsText?.data?.find((x) => x.key === "ProdønskeIngress")
  const infoText = portalSettingsText?.data?.find((x) => x.key === "ProdønskeInfotekst")

  const getMyVendor = useQuery(
    "myVendorData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Vendor/MyVendors", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: userProfile.isSuccess,
      onSuccess: (data) => {
        setActiveVendor(data[0]?.vendorId)
        setActiveAccount(data[0]?.accountId)
        setGhConditionConfirmed(data[0]?.conditionConfirmed)
        setAccountName(data[0]?.accountName)
        setRefreshVendor(false)
        if (data.length > 0) {
          setShowButtons(true)
        }
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil med valg av virksomheter. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )
  const [activeVendor, setActiveVendor] = useState(getMyVendor?.data?.[0]?.vendorId)
  const [activeAccount, setActiveAccount] = useState(getMyVendor?.data?.[0]?.accountId)
  const [ghConditionConfirmed, setGhConditionConfirmed] = useState(true)
  const [accountName, setAccountName] = useState(getMyVendor?.data?.[0]?.accountName)

  const getMyWhishes = useQuery(
    "myPlansData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/ProductionPlan/MyWhishes?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: getMyVendor.isSuccess,
      onSuccess: () => {
        setRefreshPlans(false)
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Du kan ikke registrere produksjonsønske. Ta kontakt med produksjonsplanlegger.")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  useEffect(() => {
    if (refreshPlans === true) {
      getMyWhishes.refetch()
      setLoadingDone(false)
    }
  }, [refreshPlans])

  useEffect(() => {
    if (refreshVendor === true) {
      getMyVendor.refetch()
    }
  }, [refreshVendor])

  return (
    <MyPageLayout title={null}>
      {loadingDone ? null : <PlanLoading />}
      <>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        {ghConditionConfirmed ? null : (
          <GhConditionModal
            accountId={activeAccount}
            conditionConfirmed={ghConditionConfirmed}
            setRefresh={setRefreshVendor}
          />
        )}
        <div className="flex flex-row mb-8">
          <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
            Min side
          </Link>
          <p className=" font-semibold">/ Produksjonsplan / Registrere produksjonsønske</p>
        </div>
        <div>
          <h1 className=" mt-8 mb-10 text-3xl">Registrere produksjonsønske</h1>
        </div>
        <div className="flex flex-col font-sans mb-28 ">
          <div className="flex flex-col space-y-2">
            <p>{introText?.value}</p>
          </div>
          <div>
            {getMyVendor?.data?.length < 2 ? (
              <h2 className=" text-xl sm:text-2xl mt-16 mb-6">
                Produksjonsønske sesongen {thisYearString}/{nextYearString} for {getMyVendor?.data?.[0]?.vendorName}
              </h2>
            ) : (
              <div className=" mb-6 ">
                <div>
                  <h2 className=" text-xl sm:text-2xl mt-16 mb-6 ">
                    Produksjonsønske sesongen {thisYearString}/{nextYearString} for
                  </h2>
                </div>
                <div className=" flex flex-row flex-wrap">
                  {getMyVendor?.data?.map((prop, i) => (
                    <>
                      {activeVendor === prop.vendorId ? (
                        <MyVendorComponentWithConditions
                          vendor={prop}
                          i={i}
                          setActive={setActiveVendor}
                          setActiveAccount={setActiveAccount}
                          setGhConditionConfirmed={setGhConditionConfirmed}
                          setAccountName={setAccountName}
                          active={true}
                          refresh={setRefreshPlans}
                        />
                      ) : (
                        <MyVendorComponentWithConditions
                          vendor={prop}
                          i={i}
                          setActive={setActiveVendor}
                          setActiveAccount={setActiveAccount}
                          setGhConditionConfirmed={setGhConditionConfirmed}
                          setAccountName={setAccountName}
                          active={false}
                          refresh={setRefreshPlans}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
          <UserContext.Provider value={[loadingDone, setLoadingDone, activeVendor, setActiveVendor, activeAccount]}>
            <TableComponent ProductionPlan={getMyWhishes} showButtons={showButtons} />
          </UserContext.Provider>
          <div className=" font-sans flex flex-col space-y-8 ">
            <h1 className=" text-2xl ">Hva skjer videre?</h1>
            <p>{infoText?.value}</p>
          </div>
        </div>
      </>
    </MyPageLayout>
  )
}

function ProductionWishes() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <ProductionWishesContent />
    </MsalAuthenticationTemplate>
  )
}

export default ProductionWishes
