const AddNewButton = ({ onClick, disabled, buttonText }) => {
  return (
    <>
      {disabled ? (
        <button
          disabled
          className=" bg-disabled border-disabled border rounded-lg text-white items-center text-sm flex flex-row p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
          {buttonText}
        </button>
      ) : (
        <button
          onClick={() => onClick(true)}
          className=" items-center text-sm flex flex-row p-2 rounded-lg border border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
          {buttonText}
        </button>
      )}
    </>
  )
}

export default AddNewButton
