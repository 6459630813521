import { navigate } from "gatsby"
import moment from "moment"
import { applicationStatus, productUnit } from "../../ProductionPlan/Components/Maps"
import "moment/locale/nb"
import { NumericFormat } from "react-number-format"
import { MagnifyingGlassIcon } from "../../../Graphics/IconsMyPage"

const LeaveApplicationContent = ({ application, key }) => {
  moment.locale("nb")

  let typeDesign = " bg-none "

  if (applicationStatus.get(application?.applicationStatus) === "Under behandling") {
    typeDesign = " bg-behandles "
  } else if (applicationStatus.get(application?.applicationStatus) === "Godkjent") {
    typeDesign = " bg-godkjent "
  } else {
    typeDesign = " bg-avvist "
  }

  return (
    <>
      <div key={key} className=" grid grid-cols-5 sm:grid-cols-6 min-[900px]:grid-cols-7  h-16">
        <div className=" col-span-2 sm:col-span-1 flex flex-col justify-center sm:justify-start sm:mt-2 min-[900px]:pl-8 border-b-2 border-grey ">
          <div className=" text-sm min-[900px]:text-base  pl-2 uppercase font-semibold ">
            <p>{application?.planProduct?.planProductName}</p>
          </div>
          {/* Show to date if smaller than sm */}
          <div className=" text-sm sm:text-base flex sm:hidden justify-center text-ghGreen-600">
            <p>{moment(application?.dateTo).format("L")}</p>
          </div>
        </div>
        {/* Show amount if bigger than 900px */}
        <div className=" hidden min-[900px]:flex justify-end items-end pb-2 text-ghGreen-600 border-b-2 border-grey">
          <p>
            <NumericFormat displayType="text" value={application?.quantity} thousandSeparator={" "} />{" "}
            {productUnit.get(application?.planProduct?.planProductUnit)}
          </p>
        </div>
        {/* Show from date if bigger than sm */}
        <div className=" hidden sm:flex justify-end items-end pb-2 text-ghGreen-600 border-b-2 border-grey">
          <p>{moment(application?.dateFrom).format("L")}</p>
        </div>
        {/* Show to date here if bigger than sm */}
        <div className=" hidden sm:flex justify-end items-end pb-2 text-ghGreen-600 border-b-2 border-grey">
          <p>{moment(application?.dateTo).format("L")}</p>
        </div>
        {/* Application status */}
        <div className=" col-span-2 flex justify-center items-end pb-3 sm:pb-2 border-b-2 border-grey">
          <p className={" text-sm min-[900px]:text-base px-1 rounded-lg bg-opacity-60 bg-" + typeDesign}>
            {applicationStatus.get(application?.applicationStatus)}
          </p>
        </div>
        {/* Button */}
        <div className=" border-none flex items-center justify-center ">
          <button
            onClick={() => navigate("/minside/soknad-permisjon?id=" + application?.id)}
            className=" md:py-0.5 px-1 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <MagnifyingGlassIcon />
            <span className=" hidden lg:flex ">Se detaljer</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default LeaveApplicationContent
