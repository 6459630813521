import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useMutation, useQuery } from "react-query"
import "moment/locale/nb"
import { GetPortalSettingsText, GetProfile } from "./Components/ReactQueries"
import { PlanLoading } from "./Components/PlanLoading"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { fetchApi, postApi } from "../../Common/WebApi-utils"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import { productUnit } from "./Components/Maps"
import { useState } from "react"
import { NumericFormat } from "react-number-format"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const SkpPlansOVerview = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [loadingDone, setLoadingDone] = useState(false)
  const currentDate = new Date(userProfile?.data?.today)
  const seasonChange = new Date(currentDate.getFullYear().toString() + "-10-15")
  const thisYear = currentDate > seasonChange ? currentDate.getFullYear() + 1 : currentDate.getFullYear()
  const lastYear = thisYear - 1
  const thisYearString = thisYear.toString()
  const nextYearString = (thisYear + 1).toString().slice(2)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "SKPPlanInnspillIngress")

  const getPlanProductProcess = useQuery(
    "PlanProductProcess",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/PlanProductProcess/SKP", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <MyPageLayout title={null}>
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row mt-8 md:mt-0">
            <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
              Min side
            </Link>
            <p className=" font-semibold"> / SKP / Innspill produksjonsplaner</p>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-2 text-3xl">Innspill produksjonsplaner</h1>
            <p>{infoText?.value}</p>
          </div>
          <div>
            <h2 className=" text-xl sm:text-2xl mb-2">
              Produksjonsplaner sesongen {thisYearString}/{nextYearString}
            </h2>
          </div>
          <div>
            {/* Table header */}
            <div className=" grid grid-cols-5 ">
              <div className=" col-span-2 bg-ghGreen-100 h-16"></div>
              <div className=" hidden sm:flex col-span-1 pr-0 items-center justify-center text-ghGreen-600 bg-ghGreen-100 h-16 ">
                Plan {lastYear}
              </div>
              <div className="  flex col-span-1 pr-0 items-center justify-center text-ghGreen-600 bg-ghGreen-100 h-16 ">
                Plan {thisYear}
              </div>
            </div>
            {getPlanProductProcess?.data?.map((plans, key) => (
              <div key={key}>
                {plans?.planProductProcess?.map((planProductProcess, key) => (
                  <div key={key}>
                    {planProductProcess?.status === 292460000 || planProductProcess?.status === undefined ? null : (
                      <div key={key} className=" grid grid-cols-5 mt-2 ">
                        <div className=" text-sm min-[900px]:text-base flex items-center pl-2 sm:pl-4 border-b border-grey bg-blue bg-opacity-20 h-16 uppercase font-semibold col-span-2 ">
                          {planProductProcess?.planProductName}
                        </div>
                        <div className=" hidden text-xs min-[900px]:text-base sm:flex items-center justify-center border-b border-grey bg-blue bg-opacity-20 h-16 col-span-1 ">
                          <NumericFormat
                            displayType="text"
                            value={plans?.sumQuantityLastYear}
                            thousandSeparator={" "}
                          />
                          <span className=" ml-1 ">{productUnit.get(planProductProcess?.planProductUnit)}</span>
                        </div>
                        <div className=" text-xs min-[900px]:text-base flex items-center justify-center border-b border-grey bg-blue bg-opacity-20 h-16 col-span-1 ">
                          <NumericFormat displayType="text" value={plans?.sumQuantity} thousandSeparator={" "} />
                          <span className=" ml-1 ">{productUnit.get(planProductProcess?.planProductUnit)}</span>
                        </div>
                        <div className=" flex items-center pl-2">
                          <button
                            onClick={() =>
                              navigate(
                                "/minside/skp/innspill-produksjonsplaner/kommentar?id=" +
                                  planProductProcess?.id +
                                  "&product=" +
                                  planProductProcess?.planProductName +
                                  "&period=" +
                                  thisYearString +
                                  "/" +
                                  nextYearString +
                                  "&sum=" +
                                  plans?.sumQuantity +
                                  "&unit=" +
                                  productUnit.get(planProductProcess?.planProductUnit)
                              )
                            }
                            className=" text-xs sm:text-sm px-2 border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
                          >
                            {planProductProcess?.status === 292460001 ? "Send kommentar" : "Vis kommentar"}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function SkpPlans() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <SkpPlansOVerview />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default SkpPlans
