import { useState, useEffect } from "react"
import { models, Report, Embed, service, Page } from "powerbi-client"
import { PowerBIEmbed } from "powerbi-client-react"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { useQuery, useQueryClient } from "react-query"
import { fetchApi } from "../../Common/WebApi-utils"
import useWindowSize from "../../CustomHooks/useWindowSize"
import LoadingData from "../../Graphics/LoadingData"
import DebugLogger from "../../Common/Debug"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"

// TIlGANGSKONTROLLTRENGS TIL RAPPORTER I POWERBI: Aarsoppgaver.

const AnnualReportContent = () => {
  const queryClient = useQueryClient()
  const [report, setReport] = useState<Report>()
  const [accessToken, setAccessToken] = useState()
  const [reportUrl, setReportUrl] = useState()
  const [reportName, setReportName] = useState()
  const [smallScreen, setSmallScreen] = useState(true)
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  const userPwerBiQuery = useQuery(
    "userPowerBiData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PowerBi?reportId=" + process.env.REACT_APP_POWERBI_PRODUSENT_AARSOPPGAVE,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        setAccessToken(data.embedToken.token)
        setReportUrl(data.embedReports[0].embedUrl)
        setReportName(data.embedReports[0].reportName)
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )
  const windowsize = useWindowSize()

  useEffect(() => {
    if (windowsize.width > 1024) {
      setSmallScreen(false)
    }
  }, [windowsize])

  return userPwerBiQuery.isLoading ? (
    // "Laster inn..."
    <LoadingData />
  ) : userPwerBiQuery.isError ? (
    userPwerBiQuery.error.message
  ) : (
    <>
      <MyPageLayout title="">
        <div className=" mb-20 ">
          {smallScreen ? (
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: process.env.REACT_APP_POWERBI_PRODUSENT_AARSOPPGAVE, // reportid i powerbi
                embedUrl: reportUrl,
                viewMode: models.ViewMode.View,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                permissions: models.Permissions.All,
                settings: {
                  filterPaneEnabled: false,
                  navContentPaneEnabled: true,
                  background: models.BackgroundType.Transparent,
                  layoutType: models.LayoutType.MobilePortrait,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                      DebugLogger("Report", "Report loaded")
                    },
                  ],
                  [
                    "rendered",
                    function () {
                      DebugLogger("Report", "Report rendered")
                    },
                  ],
                  [
                    "error",
                    function (event) {
                      DebugLogger("Report", event.detail)
                    },
                  ],
                ])
              }
              cssClassName={"embed-container"}
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report)
              }}
            />
          ) : (
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: process.env.REACT_APP_POWERBI_PRODUSENT_AARSOPPGAVE, // reportid i powerbi
                embedUrl: reportUrl,
                viewMode: models.ViewMode.View,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                permissions: models.Permissions.All,
                settings: {
                  filterPaneEnabled: false,
                  navContentPaneEnabled: true,
                  background: models.BackgroundType.Transparent,
                  layoutType: models.LayoutType.Custom,
                  customLayout: {
                    displayOption: models.DisplayOption.FitToPage,
                  },
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    function () {
                      DebugLogger("Report", "Report loaded")
                    },
                  ],
                  [
                    "rendered",
                    function () {
                      DebugLogger("Report", "Report rendered")
                    },
                  ],
                  [
                    "error",
                    function (event) {
                      DebugLogger("Report", event.detail)
                    },
                  ],
                ])
              }
              cssClassName={"embed-container"}
              getEmbeddedComponent={(embedObject: Embed) => {
                setReport(embedObject as Report)
              }}
            />
          )}
        </div>
      </MyPageLayout>
    </>
  )
}

function AnnualReport() {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AnnualReportContent />
    </MsalAuthenticationTemplate>
  )
}

export default AnnualReport
