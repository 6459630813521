import "moment-timezone"
import { useState } from "react"
import { fetchApi } from "../../Common/WebApi-utils"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { loginRequest } from "../../Security/authConfig"
import { InteractionType } from "@azure/msal-browser"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import LoadingData from "../../Graphics/LoadingData"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const AccountContent = () => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    color: "green",
    delay: 3000,
    showNotification: false,
  })

  const accountQuery = useQuery(
    "accountData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Accounts/All", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (data) => {
        // No message when ok
      },
      onError: (_error) => {
        setNotification({
          title: "En feil oppsto",
          message: "Prøv igjen senere",
          // _error.message,
          color: "red",
          delay: 3000,
          showNotification: true,
        })
      },
    }
  )

  return accountQuery.isLoading ? (
    // "Laster inn..."
    <LoadingData />
  ) : accountQuery.isError ? (
    accountQuery.error.message
  ) : (
    <MyPageLayout title="Min virksomhet">
      {accountQuery.data.length > 0 ? (
        <div>
          {/* Chevron */}

          <div className="w-auto md:w-63 lg:w-84  xl:w-116 2xl:w-176  mb-60">
            <div className="max-w-7xl min-w-full md:w-auto mx-auto px-4  sm:px-6 lg:px-8">
              <div className="max-w-4xl  w-full  md:w-auto xl:max-w-6xl ">
                <div className="mt-6  ">
                  {accountQuery.data.map((firm, index) => (
                    <Disclosure
                      defaultOpen={index === 0}
                      as="div"
                      key={firm.name}
                      className="pt-6 pb-6 border-t border-gray-200"
                    >
                      {({ open }) => (
                        <>
                          <div className="text-lg">
                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                              <span className="font-medium text-gray-900">{firm.name}</span>
                              <span className="ml-6 h-7 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </div>
                          <Disclosure.Panel as="dd" className="mt-2">
                            <div className="">
                              <div className="relative pb-20 sm:px-0 lg:pb-28 sm:max-w-md xl:max-w-4xl grid grid-cols-1 gap-2 xl:grid-cols-2 md:gap-12 lg:gap-16 xl:gap-32 2xl:gap-48 ">
                                <div className="">
                                  <h2 className="text-body18 font-medium leading-normal mt-12 mb-8 text-gray-900">
                                    Virksomhetens opplysninger
                                  </h2>

                                  <div>
                                    <div>
                                      <div>
                                        <div className="mt-2 ">
                                          <dl className="divide-y divide-gray-200 xl:order-last">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Navn</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.name}
                                              </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500 whitespace-nowrap">
                                                E-post
                                              </dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.emailAddress}
                                              </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Tlf.nr</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.telephoneNumber}
                                              </dd>
                                            </div>

                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Adresse</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {!Object.keys(firm.visitingAddress).length ? (
                                                  <p></p>
                                                ) : (
                                                  <p>{firm.visitingAddress.line1}</p>
                                                )}
                                                {!firm.visitingAddress.postalCode ? (
                                                  <></>
                                                ) : (
                                                  <>{firm.visitingAddress.postalCode}</>
                                                )}

                                                {!firm.visitingAddress.city ? <></> : <> {firm.visitingAddress.city}</>}
                                                <p>{firm.visitingAddress.country}</p>
                                              </dd>
                                            </div>
                                            <div className="border-t border-gray-200"></div>
                                          </dl>

                                          <dl className="divide-y divide-gray-200 xl:order-first">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Org.nummer</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.vatNumber}
                                              </dd>
                                            </div>

                                            {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-body18 font-medium text-gray-500">Kontonr.</dt>
                                            <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                              **** ** 43424
                                            </dd>
                                          </div> */}
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Kontaktperson</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.primaryContact?.fullName}
                                              </dd>
                                            </div>

                                            <div className="mt-1"></div>
                                          </dl>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-body14 text-gray-500 mt-8">
                                      <a
                                        className="hover:text-tomat-500 underline hover:underline"
                                        href="mailto:post@gartnerhallen.no?subject=Endre virksomhetsopplysninger"
                                      >
                                        Kontakt Gartnerhallen for å endre opplysninger om virksomheten
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <h2 className="text-body18 font-medium leading-normal mt-12 mb-8 text-gray-900">
                                    Medlemskap
                                  </h2>

                                  <div>
                                    <div>
                                      <div>
                                        <div className="mt-2 ">
                                          <dl className="divide-y divide-gray-200 xl:order-last">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                              <dt className="text-body18 font-medium text-gray-500">Status</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {(() => {
                                                  if (firm.purchaserRebateGroup) return <p>Medlem</p>
                                                  if (firm.vendorStatus) return <p>Leverandør</p>
                                                  else return <p>Ikke medlem</p>
                                                })()}
                                              </dd>
                                            </div>

                                            {/* Skal med i senere release!
                                            
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-14">
                                              <dt className="text-body18 font-medium text-gray-500">Lev.nr</dt>
                                              <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                {firm.vendorNumber}
                                              </dd>
                                            </div> */}
                                            {firm.purchaserRebateGroup ? (
                                              <>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 xl:gap-24">
                                                  <dt className="text-body18 font-medium text-gray-500">
                                                    Fellesmedlem
                                                  </dt>
                                                  <dd className="mt-1 text-body18 text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {firm.sharedMembers.length === 0 ? (
                                                      <p>Ingen tilknyttet</p>
                                                    ) : (
                                                      firm.sharedMembers.map((member) => (
                                                        <p key={member.firstName}>
                                                          {member.firstName} {member.lastName}
                                                        </p>
                                                      ))
                                                    )}
                                                  </dd>
                                                </div>
                                                <div className="border-t border-gray-200"></div>
                                              </>
                                            ) : (
                                              <p></p>
                                            )}
                                          </dl>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Vi kan ikke se at du er tilknyttet noe firma.</div>
      )}
    </MyPageLayout>
  )
}

function Account() {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AccountContent />
    </MsalAuthenticationTemplate>
  )
}

export default Account
