import { useAccount, useMsal } from "@azure/msal-react"
import { useContext, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GetProfile } from "../../ProductionPlan/Components/ReactQueries"
import { UserContext } from "../../ProductionPlan/Components/UserContext"
import { fetchApi, postApi } from "../../../Common/WebApi-utils"
import { XButtonIcon } from "../../../Graphics/IconsMyPage"
import CustomerFilter from "../../ProductionPlan/Components/ProductPlanner/CustomerFilter"
import CensusProductFilter from "./CensusProductFilter"
import NotificationAlert from "../../../Graphics/NotificationAlert"
import { PlanLoading } from "../../ProductionPlan/Components/PlanLoading"

export type newCensus = {
  CensusDate: string
  CensusType: number
  Producer: string
  Vendor: string
  Customer: string
  PlanProduct: string
  Unit: number
  Quantity: number
  CensusStatus: number
}

const NewCensus = ({ cancelProduct, type, date, today }) => {
  const { instance, accounts, inProgress } = useMsal()
  const queryClient = useQueryClient()
  // const allProducts = GetProducts(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const [productId, setProductId] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [productFilterValue, setProductFilterValue] = useState("")
  const [customerFilterValue, setCustomerFilterValue] = useState("")
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [activeAccount, activeVendor, loadingDone, setLoadingDone, setRefresh] = useContext(UserContext)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  const [currentDate, setCurrentDate] = useState(new Date(today))
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  let year = 0

  if (currentMonth >= 0 && currentMonth <= 10) {
    year = currentYear
  } else {
    year = currentYear + 1
  }

  const getMyCensuses = useQuery(
    "myCensusesData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Census/MyCensuses?accountId=" +
          activeAccount +
          "&vendorId=" +
          activeVendor +
          "&censusStatuses=" +
          "readyToSend,sentIn",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      // refetchOnWindowFocus: false,
      onSuccess: () => {
        setRefresh(false)
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil med tellingene. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const getPlanProduct = useQuery(
    "PlanProductData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/PlanProduct/AvailableForCensuses?searchParam=" + productFilterValue,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
      },
    }
  )

  const getCustomers = useQuery(
    "CustomerData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Customer?searchParam=" + customerFilterValue + "&customerType=census",
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
      },
    }
  )

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (productFilterValue.length) {
      case 0:
        getPlanProduct.refetch()
        break
      case 2:
        getPlanProduct.refetch()
        break
      case 4:
        getPlanProduct.refetch()
        break
      case 6:
        getPlanProduct.refetch()
        break
      case 8:
        getPlanProduct.refetch()
        break
      case 10:
        getPlanProduct.refetch()
        break
    }
  }, [productFilterValue])

  // After how many characters does the refetch occur in the input field.
  useEffect(() => {
    switch (customerFilterValue.length) {
      case 0:
        getCustomers.refetch()
        break
      case 2:
        getCustomers.refetch()
        break
      case 4:
        getCustomers.refetch()
        break
      case 6:
        getCustomers.refetch()
        break
      case 8:
        getCustomers.refetch()
        break
      case 10:
        getCustomers.refetch()
        break
      case 12:
        getCustomers.refetch()
        break
    }
  }, [customerFilterValue])

  const AddCensus = useMutation(
    async (values) =>
      postApi(process.env.REACT_APP_API_URL_APIM + "/Census", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        queryClient.invalidateQueries("myCensusesData")
        cancelProduct(false)
      },
    }
  )

  // Submit the selected product to the active vendor.
  const onSubmit = () => {
    if (type === "Stock") {
      const stockObject: newCensus = {
        CensusDate: year + "-" + date,
        CensusType: 292460000,
        Producer: activeAccount,
        Vendor: activeVendor,
        Customer: customerId,
        PlanProduct: productId,
        Unit: 292460000,
        Quantity: 0,
        CensusStatus: 292460001,
      }
      setLoadingDone(false)
      AddCensus.mutate(stockObject)
    } else {
      const areaObject: newCensus = {
        CensusDate: year + "-" + date,
        CensusType: 292460001,
        Producer: activeAccount,
        Vendor: activeVendor,
        Customer: customerId,
        PlanProduct: productId,
        Unit: 292460001,
        Quantity: 0,
        CensusStatus: 292460001,
      }
      setLoadingDone(false)
      AddCensus.mutate(areaObject)
    }
  }

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      {alert ? (
        <NotificationAlert
          alert={alert}
          setAlert={setAlert}
          type={alertType}
          message={alertMessage}
          underlineMessage={alertUnderlineMessage}
        />
      ) : null}
      <div className=" bg-blue bg-opacity-20 grid grid-rows-2 grid-cols-4 sm:grid-cols-5 justify-center items-center mt-4 ">
        {/* Search for products */}
        <div className=" col-span-2 sm:col-span-3 pl-2 sm:pl-8 py-4 border-none relative">
          <div className="text-left relative ">
            <p className=" text-sm sm:text-base pb-2">Vare</p>
            <CensusProductFilter
              prop={getPlanProduct}
              setId={setProductId}
              filterValue={productFilterValue}
              setFilterValue={setProductFilterValue}
              placeholder={"Skriv for å søke etter varenavn"}
            />
          </div>
        </div>
        <div className=" row-start-2 col-span-2 sm:col-span-3 pl-2 sm:pl-8 py-4 border-none relative">
          <div className="text-left relative ">
            <p className=" text-sm sm:text-base pb-2">Kunde/pakkeri</p>
            <CustomerFilter
              prop={getCustomers}
              setId={setCustomerId}
              filterValue={customerFilterValue}
              setFilterValue={setCustomerFilterValue}
              placeholder={"Skriv for å søke etter kunde/pakkeri"}
            />
          </div>
        </div>
        {/* The "Legg til" button is only active if a value with an Id is selected */}
        <div className=" border-none row-start-2 ">
          {productId === "" || customerId === "" ? (
            <button
              disabled
              className=" mt-8 h-8 sm:h-12 float-right text-white bg-disabled border-disabled border rounded-lg items-center text-xs sm:text-sm flex flex-row py-2 px-1 "
            >
              Legg til
            </button>
          ) : (
            <button
              onClick={onSubmit}
              className=" mt-8 h-8 sm:h-12 float-right text-black border rounded-lg items-center text-xs sm:text-sm flex flex-row py-2 px-1 border-black bg-ghGreen-100 hover:bg-ghGreen-200 active:bg-ghGreen-300 disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
            >
              Legg til
            </button>
          )}
        </div>
        {/* The cancel button */}
        <div className=" pr-2 border-none text-right row-start-2 ">
          <button
            onClick={() => cancelProduct(false)}
            className=" mt-8 border-2 border-grey rounded-lg p-1 hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
          >
            <XButtonIcon />
          </button>
        </div>
        <div className=" border-none bg-white "></div>
      </div>
    </>
  )
}

export default NewCensus
