import { useAccount, useMsal } from "@azure/msal-react"
import { Dialog, Transition } from "@headlessui/react"
import { navigate } from "gatsby"
import { Fragment, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { fetchApi, fetchApiDownload, patchApi } from "../../../Common/WebApi-utils"
import { greenSaveButton, redButton } from "../../../Styling/ButtonStyles"
import { GetPortalSettingsText, GetProfile } from "../Components/ReactQueries"
import { UserContext } from "../Components/UserContext"

// ---------------------------------------------//
// ConditionsModal is used whenever the
// modal needs to show up when the user use the
// "Se plan" button
// --------------------------------------------//

const ConditionsModal = ({ prodPlan, totalSum, setConditionId }) => {
  const [open, setOpen] = useState(true)
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const [loadingDone, setLoadingDone, activeVendor, setActiveVendor] = useContext(UserContext)
  const account = useAccount(accounts[0] || {})
  const date = new Date()
  const [checkbox, setCheckbox] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [error, setError] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const conditionText = portalSettingsText?.data?.find((x) => x.key === "ÅrsbetingelserTekst")
  const checkboxText = portalSettingsText?.data?.find((x) => x.key === "ÅrsbetingelserAvhuking")

  const getYearTermsDocument = useQuery(
    "myYearTermsDocument",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM +
          "/Documents/YearTerms?planProductId=" +
          prodPlan?.planProduct?.id +
          "&accountId=" +
          prodPlan?.customer?.companyId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        if (data.downloadUrl === undefined) {
          if (prodPlan.lastYearPlanId === undefined) {
            navigate(
              "/minside/produksjonsplaner/plan?id=" +
                prodPlan?.id +
                "&unit=" +
                prodPlan?.planProduct?.planProductUnit +
                "&customer=" +
                prodPlan?.customer?.customerName +
                "&product=" +
                prodPlan?.planProduct?.planProductName +
                "&sum=" +
                prodPlan?.sumQuantity +
                "&totalSum=" +
                totalSum +
                "&c=" +
                prodPlan?.customer?.companyId +
                "&pp=" +
                prodPlan?.planProduct?.id +
                "&terms=" +
                false
            )
          } else {
            navigate(
              "/minside/produksjonsplaner/plan?id=" +
                prodPlan?.id +
                "&lastPlan=" +
                prodPlan?.lastYearPlanId +
                "&unit=" +
                prodPlan?.planProduct?.planProductUnit +
                "&customer=" +
                prodPlan?.customer?.customerName +
                "&product=" +
                prodPlan?.planProduct?.planProductName +
                "&sum=" +
                prodPlan?.sumQuantity +
                "&totalSum=" +
                totalSum +
                "&c=" +
                prodPlan?.customer?.companyId +
                "&pp=" +
                prodPlan?.planProduct?.id +
                "&terms=" +
                false
            )
          }
        } else {
          setShowTerms(true)
        }
      },
      onError: (_error) => {
        setError(true)
        setShowTerms(true)
      },
    }
  )

  const mutationDocument = useMutation(
    () =>
      fetchApiDownload(
        process.env.REACT_APP_API_URL_APIM + "/Documents/Download/" + getYearTermsDocument?.data?.blobId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      onSuccess: (blob: any) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", getYearTermsDocument?.data?.title)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        if (link.parentNode) {
          link.parentNode.removeChild(link)
        }

        setDownloading(false)
      },
      onError: (_error) => {
        setError(true)
      },
    }
  )

  const PatchCondition = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/ProductionPlan", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setError(true)
        setLoadingDone(true)
      },
      onSuccess: () => {
        if (prodPlan.lastYearPlanId === undefined) {
          navigate(
            "/minside/produksjonsplaner/plan?id=" +
              prodPlan?.id +
              "&unit=" +
              prodPlan?.planProduct?.planProductUnit +
              "&customer=" +
              prodPlan?.customer?.customerName +
              "&product=" +
              prodPlan?.planProduct?.planProductName +
              "&sum=" +
              prodPlan?.sumQuantity +
              "&totalSum=" +
              totalSum +
              "&c=" +
              prodPlan?.customer?.companyId +
              "&pp=" +
              prodPlan?.planProduct?.id +
              "&terms=" +
              true
          )
        } else {
          navigate(
            "/minside/produksjonsplaner/plan?id=" +
              prodPlan?.id +
              "&lastPlan=" +
              prodPlan?.lastYearPlanId +
              "&unit=" +
              prodPlan?.planProduct?.planProductUnit +
              "&customer=" +
              prodPlan?.customer?.customerName +
              "&product=" +
              prodPlan?.planProduct?.planProductName +
              "&sum=" +
              prodPlan?.sumQuantity +
              "&totalSum=" +
              totalSum +
              "&c=" +
              prodPlan?.customer?.companyId +
              "&pp=" +
              prodPlan?.planProduct?.id +
              "&terms=" +
              true
          )
        }
      },
    }
  )

  const confirmCondtion = () => {
    const patchObject = {
      Id: prodPlan?.id,
      YearTermsConfirmed: date.toISOString(),
    }
    PatchCondition.mutate(patchObject)
    setLoadingDone(false)
  }

  const downloadFile = () => {
    mutationDocument.mutate()
    setDownloading(true)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto p-18 font-sans "
        onClose={() => {
          setOpen(true)
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center backdrop-blur sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {showTerms ? (
              <>
                {error ? (
                  <div className=" h-auto inline-block align-bottom bg-white border-ghGreen-400 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      <div className="text-center">
                        <Dialog.Title as="h3" className="text-base font-medium ">
                          Det har skjedd en feil, prøv igjen senere
                        </Dialog.Title>
                      </div>
                      <div className="flex justify-evenly mt-12">
                        <div>
                          <button
                            className={redButton + " px-4 md:px-5 lg:px-7 py-2 md:py-3 lg:py-4 "}
                            onClick={() => {
                              setOpen(false)
                              setConditionId("")
                            }}
                          >
                            Lukk
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" h-auto inline-block align-bottom bg-white border-ghGreen-400 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div>
                      <div className="text-center">
                        <Dialog.Title as="h3" className="text-base font-medium ">
                          {conditionText?.value}
                        </Dialog.Title>
                      </div>
                      <div className="flex flex-col mb-10 mt-8">
                        <div className=" flex flex-row items-center justify-center mb-4 ">
                          {getYearTermsDocument.isSuccess ? (
                            <>
                              {downloading ? (
                                <p>Laster ned...</p>
                              ) : (
                                <button
                                  onClick={() => downloadFile()}
                                  className="underline underline-offset-1 hover:text-grey"
                                >
                                  Se betingelser
                                </button>
                              )}
                            </>
                          ) : null}
                        </div>
                        <div className=" flex flex-row items-center justify-center ">
                          <input type="checkbox" defaultChecked={checkbox} onClick={() => setCheckbox(!checkbox)} />
                          <label className=" ml-2 ">{checkboxText?.value}</label>
                        </div>
                      </div>
                      <div>
                        {checkbox === true ? (
                          <div className="flex justify-evenly items-center ">
                            <div>
                              <button className={greenSaveButton} onClick={confirmCondtion}>
                                Ja, aksepter
                              </button>
                            </div>
                            <div>
                              <button
                                className={redButton + " px-4 md:px-5 lg:px-7 py-2 md:py-3 lg:py-4 "}
                                onClick={() => {
                                  setOpen(false)
                                  setConditionId("")
                                }}
                              >
                                Avbryt
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConditionsModal
