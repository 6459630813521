import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useMutation, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi, patchApi } from "../../Common/WebApi-utils"
import { GetProfile } from "../ProductionPlan/Components/ReactQueries"
import moment from "moment"
import "moment/locale/nb"
import { navigate } from "gatsby"
import { StringParam, useQueryParam } from "use-query-params"
import { applicationType, productUnit } from "../ProductionPlan/Components/Maps"
import { useForm } from "react-hook-form"
import { NumericFormat } from "react-number-format"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

export type addComment = {
  Id: string | null | undefined
  RkpComment: string
  ApplicationStatus: number
}

const RKPEditApplicationsContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const { handleSubmit } = useForm({
    mode: "onChange",
  })
  const isAuthenticated = useIsAuthenticated()
  const [loadingDone, setLoadingDone] = useState(false)
  const [connectedFrom, setConnectedFrom] = useState("")
  const [applicationId, setApplicationId] = useQueryParam("id", StringParam)
  const [comment, setComment] = useState("")
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  moment.locale("nb")

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const userConnection = useQuery(
    "userConnectionData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      enabled: !!isAuthenticated,
      onSuccess: (data) => {
        data.map((connection, key) => {
          return connection.accessRole === 7 && connection.connectedFromEntity === "pp_committee"
            ? setConnectedFrom(connection.connectedFrom)
            : console.log("no")
        })
      },
      onError: (_error) => {
        // error,message
      },
    }
  )

  const getMyApplication = useQuery(
    "myApplicationData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Application?applicationId=" + applicationId,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userConnection.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  const rkpComment = useMutation(
    async (values) =>
      patchApi(process.env.REACT_APP_API_URL_APIM + "/Application", values, account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
      onSuccess: () => {
        setLoadingDone(true)
        navigate("/minside/regionsrad/se-soknader")
      },
    }
  )

  const editApplicationRKP = () => {
    const rkpObject: addComment = {
      Id: applicationId,
      RkpComment: comment,
      ApplicationStatus: 292460001,
    }
    rkpComment.mutate(rkpObject)
    setLoadingDone(false)
  }

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-8 ">
          <div className="flex flex-row font-sans mt-6 sm:mt-0 ">
            <Link to="/minside/regionsrad/se-soknader" className=" flex flex-row ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
              <p className="underline underline-offset-2 decoration-tomat-500">Tilbake til søknader</p>
            </Link>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">
              {getMyApplication?.data?.type === 292460000 ? "Søknad om dispensasjon" : "Søknad om permisjon"}
            </h1>
          </div>
          {/* Vendor */}
          <form name="applicationLeave" method="patch" onSubmit={handleSubmit(editApplicationRKP)}>
            <div className=" mb-8 flex flex-col">
              <div>
                <p className=" text-base mb-2 font-semibold ">Søknaden gjelder</p>
              </div>
              <p>{getMyApplication?.data?.vendor?.vendorName}</p>
            </div>
            {/* Product */}
            <div className=" mb-6 flex flex-col max-w-63">
              <p className=" font-semibold mb-2">Varenavn</p>
              <p>{getMyApplication?.data?.planProduct?.planProductName}</p>
            </div>
            {/* Amount */}
            <div className=" mb-6 flex flex-col">
              <p className=" font-semibold mb-2">Mengde</p>
              <div className=" flex flex-row items-center ">
                <p>
                  <NumericFormat displayType="text" value={getMyApplication?.data?.quantity} thousandSeparator={" "} />{" "}
                  {productUnit.get(getMyApplication?.data?.planProduct?.planProductUnit)}
                </p>
              </div>
            </div>
            {/* Customer */}
            {applicationType.get(getMyApplication?.data?.type) === "Dispensasjonssøknad" ? (
              <div className=" mb-6 flex flex-col max-w-63">
                <p className=" font-semibold mb-2">Kundenavn</p>
                <p>{getMyApplication?.data?.customer?.customerName}</p>
              </div>
            ) : null}
            {/* From date */}
            <div className=" mb-6 flex flex-col">
              <p className=" font-semibold mb-2">Fra dato</p>
              <p>{moment(getMyApplication?.data?.dateFrom).format("L")}</p>
            </div>
            {/* To date */}
            {applicationType.get(getMyApplication?.data?.type) === "Permisjonssøknad" ? (
              <div className=" mb-6 flex flex-col">
                <p className=" font-semibold mb-2">Til dato</p>
                <p>{moment(getMyApplication?.data?.dateTo).format("L")}</p>
              </div>
            ) : null}
            {/* Customer comment */}
            <div className=" mb-6 flex flex-col">
              <p className=" font-semibold mb-2">Begrunnelse</p>
              <p>{getMyApplication?.data?.producerComment}</p>
            </div>
            {/* Comment */}
            <div className=" mb-6 flex flex-col">
              <p className=" font-semibold mb-2">Innstilling fra regionen</p>
              <textarea
                required
                maxLength={200}
                onChange={handleComment}
                className=" max-w-63 border-2 border-grey rounded-2xl h-44 focus:ring-black focus:border-black "
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="px-9 md:px-10 lg:px-12 py-2 md:py-3 lg:py-4 border-2 border-ghGreen-400 rounded-xl hover:bg-ghGreen-400 hover:text-white active:bg-ghGreen-500 active:border-ghGreen-500 active:ring-0  focus:outline-none focus:ring-0 focus:border-4 focus:border-ghGreen-500"
              >
                Send til Gartnerhallen administrasjon
              </button>
            </div>
          </form>
        </div>
      </MyPageLayout>
    </>
  )
}

function RKPEditApplications() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <RKPEditApplicationsContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default RKPEditApplications
