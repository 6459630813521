import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import MyPageLayout from "../../Layout/MyPageLayout"
import { loginRequest } from "../../Security/authConfig"
import { ErrorComponent } from "../../Security/ErrorComponent"
import { Loading } from "../../Security/Loading"
import { useEffect, useState } from "react"
import { PlanLoading } from "../ProductionPlan/Components/PlanLoading"
import { fetchApi } from "../../Common/WebApi-utils"
import { GetPortalSettingsText, GetProfile } from "../ProductionPlan/Components/ReactQueries"
import moment from "moment"
import "moment/locale/nb"
import { navigate } from "gatsby"
import { NumericFormat } from "react-number-format"
import { applicationStatus } from "../ProductionPlan/Components/Maps"
import { PenIcon } from "../../Graphics/IconsMyPage"
import NotificationAlert from "../../Graphics/NotificationAlert"
import { Link } from "@reach/router"

const queryClient = new QueryClient()

const RKPApplicationsContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const portalSettingsText = GetPortalSettingsText(accounts, inProgress, instance)
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const [loadingDone, setLoadingDone] = useState(false)
  const [connectedFrom, setConnectedFrom] = useState("")
  const [activeFilter, setActiveFilter] = useState(292460000)
  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [alertUnderlineMessage, setAlertUnderlineMessage] = useState("")
  moment.locale("nb")

  const infoText = portalSettingsText?.data?.find((x) => x.key === "RegionrådSøknadInnspillIngress")

  const userConnection = useQuery(
    "userConnectionData",
    () =>
      fetchApi(process.env.REACT_APP_API_URL_APIM + "/Connections", account, inProgress, instance).then(
        (res) => res.data
      ),
    {
      onSuccess: (data) => {
        data.map((connection, key) => {
          return connection.accessRole === 7 && connection.connectedFromEntity === "pp_committee"
            ? setConnectedFrom(connection.connectedFrom)
            : console.log("no access")
        })
      },
      onError: (_error) => {
        navigate("/minside/")
      },
    }
  )

  const getMyApplication = useQuery(
    "myApplicationData",
    () =>
      fetchApi(
        process.env.REACT_APP_API_URL_APIM + "/Application/Region?committee=" + connectedFrom,
        account,
        inProgress,
        instance
      ).then((res) => res.data),
    {
      enabled: userConnection.isSuccess && connectedFrom !== "",
      onSuccess: (data) => {
        setLoadingDone(true)
      },
      onError: (_error) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage("Det har skjedd en feil. Prøv igjen senere")
        setAlertUnderlineMessage("")
        setLoadingDone(true)
      },
    }
  )

  return (
    <>
      {loadingDone ? null : <PlanLoading />}
      <MyPageLayout title={null}>
        {alert ? (
          <NotificationAlert
            alert={alert}
            setAlert={setAlert}
            type={alertType}
            message={alertMessage}
            underlineMessage={alertUnderlineMessage}
          />
        ) : null}
        <div className=" flex flex-col font-sans mb-32 space-y-14 ">
          <div className="flex flex-row mt-8 md:mt-0">
            <Link to="/minside/" className=" underline underline-offset-2 decoration-tomat-500 mr-2 ">
              Min side
            </Link>
            <p className=" font-semibold"> / Regionråd / Innstilling til dispensasjoner og permisjoner</p>
          </div>
          <div className=" flex flex-col ">
            <h1 className=" mb-10 text-3xl">Innstilling til dispensasjoner og permisjoner</h1>
            <p>{infoText?.value}</p>
          </div>
          {/* Dispensasjon or Permisjon filter buttons */}
          <div>
            <button
              onClick={() => setActiveFilter(292460000)}
              className={
                activeFilter === 292460000
                  ? " text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans bg-black text-white rounded-lg "
                  : " text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans text-black rounded-lg "
              }
            >
              Dispensasjon
            </button>
            <button
              onClick={() => setActiveFilter(292460001)}
              className={
                activeFilter === 292460001
                  ? " text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans bg-black text-white rounded-lg "
                  : " text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans text-black rounded-lg "
              }
            >
              Permisjon
            </button>
          </div>
          <div>
            {/* Table header */}
            <div className=" grid grid-cols-6 sm:grid-cols-7 min-[900px]:grid-cols-8 h-16 ">
              {/* Product name */}
              <div className=" text-xs sm:text-sm min-[900px]:text-base flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 ">
                Planvare
              </div>
              {/* Vendor */}
              <div className=" text-xs sm:text-sm min-[900px]:text-base flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 col-span-2 ">
                Leverandør
              </div>
              {/* Amount */}
              <div className=" hidden min-[900px]:flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 ">
                Mengde
              </div>
              {/* From date */}
              <div className=" text-sm min-[900px]:text-base hidden sm:flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 ">
                Gyldig fra
              </div>
              {/* To date */}
              <div className=" text-sm min-[900px]:text-base hidden min-[900px]:flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 ">
                Gyldig til
              </div>
              {/* Status */}
              <div className=" col-span-2 min-[900px]:col-span-1 text-xs sm:text-sm min-[900px]:text-base flex items-center justify-center  text-ghGreen-600 bg-ghGreen-100 ">
                Status søknad
              </div>
            </div>
            {/* Products content */}
            {getMyApplication?.data?.map((product, key) => (
              <>
                {/* Filter on the activeFilter value */}
                {product?.type === activeFilter ? (
                  <div key={key} className=" grid grid-cols-6 sm:grid-cols-7 min-[900px]:grid-cols-8 h-16 ">
                    {/* Product name */}
                    <div className=" text-xs sm:text-sm min-[900px]:text-base flex items-center justify-start pl-2 border-b-2 border-grey uppercase ">
                      <p>{product?.planProduct?.planProductName}</p>
                    </div>
                    {/* Vendor */}
                    <div className=" text-xs sm:text-sm min-[900px]:text-base flex items-center justify-center border-b-2 border-grey col-span-2 uppercase ">
                      <p>{product?.vendor?.vendorName}</p>
                    </div>
                    {/* Amount */}
                    <div className=" hidden min-[900px]:flex items-center justify-center border-b-2 border-grey text-ghGreen-600 ">
                      <p>
                        <NumericFormat displayType="text" value={product?.quantity} thousandSeparator={" "} />
                      </p>
                    </div>
                    {/* From date */}
                    <div className=" text-sm min-[900px]:text-base hidden sm:flex items-center justify-center border-b-2 border-grey text-ghGreen-600 ">
                      <p>{moment(product?.dateFrom).format("L")}</p>
                    </div>
                    {/* To date */}
                    {product?.dateTo === undefined ? (
                      <div className=" text-sm min-[900px]:text-base hidden min-[900px]:flex items-center justify-center border-b-2 border-grey text-ghGreen-600 ">
                        <p></p>
                      </div>
                    ) : (
                      <div className=" text-sm min-[900px]:text-base hidden min-[900px]:flex items-center justify-center border-b-2 border-grey ">
                        <p>{moment(product?.dateTo).format("L")}</p>
                      </div>
                    )}
                    {/* Status */}
                    <div className=" col-span-2 min-[900px]:col-span-1 text-xs sm:text-sm min-[900px]:text-base flex items-center justify-center border-b-2 border-grey ">
                      <p className={" text-sm min-[900px]:text-base px-1 rounded-lg bg-opacity-60 bg-behandles "}>
                        {applicationStatus.get(product?.applicationStatus)}
                      </p>
                    </div>
                    {/* Button */}
                    <div className=" border-none flex items-center justify-center ">
                      <button
                        onClick={() => navigate("/minside/regionsrad/endre-soknad?id=" + product.id)}
                        className=" md:py-0.5 px-1 md:flex md:flex-row md:justify-center md:items-center border-2 rounded-lg border-grey hover:border-black active:bg-disabled disabled:bg-disabled disabled:text-white focus:outline-none focus:ring-0 focus:border-3 focus:border-black "
                      >
                        <PenIcon />
                        <span className=" hidden min-[1100px]:flex ">Innstilling</span>
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>
        </div>
      </MyPageLayout>
    </>
  )
}

function RKPApplications() {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <QueryClientProvider client={queryClient}>
        <RKPApplicationsContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default RKPApplications
