import { graphql } from "gatsby"
import CommonCard from "../components/Cards/CommonCard"
import HeroTopicHeadline from "../components/Hero/HeroHeadline"
import { useIsAuthenticated } from "@azure/msal-react"

/**
 * @description Følge samme prinsipp som bondeportrett OVERSIKTS side. Overskrift, inngress, visning av kort.
 * Relaterte prosjekter, inntil tre, sortert på nyeste først.
 * Merk at alle prosjekter (oversikt og detaljer) skal legges bak minside pålogging.
 * @param param0
 */
const Projects = ({ location, data }) => {
  // const { user, setUser } = useContext(UserContext)
  const isAuthenticated = useIsAuthenticated()

  // useEffect(() => {}, [])

  const {
    allContentfulProjects: { nodes: projects },
    contentfulGeneric: generic,
  } = data

  projects.sort((a, b) => a.order - b.order)
  // TODO: Bytte tomme strings med rett data fra contentful. Så kopiere samme løsning til alle templates
  // const imageProps: ImageProps = {
  //   imageUrl: topic.heroImage,
  //   imageSecureUrl: topic.heroImage,
  //   imageHigth: "",
  //   imageWitdh: "",
  //   imageAlt: "Alt fra cloudinary",
  //   imageType: "",
  // }
  // const profile: Profile = {
  //   profileFirstName: "Ola",
  //   profileLastName: "Norman",
  // }
  // const articleProps: ArticleProps = {
  //   publishTime: "",
  //   modifiedTime: "",
  //   expirationTime: "",
  //   section: "",
  //   tags: ["", ""],
  //   authors: [profile],
  // }
  // const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  // const helmetProps: HelmetProps = {
  //   title: content.title || "Tittel mangler",
  //   slug: canonicalUrl,
  //   contentType: "article",
  //   locale: topic.node_locale,
  //   isSecret: true,
  //   articleMetaTags: articleProps,
  //   images: [imageProps],
  // }

  return (
    <>
      {/* <SEO {...helmetProps} /> */}

      {isAuthenticated ? (
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <HeroTopicHeadline {...generic} />
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {projects.map((project) => {
              const props = {
                data: project,
                url: "prosjekt",
              }
              return <CommonCard key={project.id} {...props} />
            })}
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  )
}

export const projectQuery = graphql`
  {
    allContentfulProjects(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        title
        node_locale
        metadataDescription
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
        }
        author {
          name
        }
        contentful_id
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulGeneric(slug: { eq: "prosjekter" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        childMdx {
          body
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        url
        id
        public_id
        secure_url
      }
      node_locale
      title
    }
  }
`

export default Projects
