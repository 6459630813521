const MyVendorComponent = ({ vendor, i, active, setActive, setActiveAccount, refresh }) => {
  return (
    <>
      {active ? (
        <button
          type="button"
          className=" text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans bg-black text-white rounded-lg "
          key={i}
          onClick={() => {
            setActive(vendor?.vendorId)
            setActiveAccount(vendor?.accountId)
          }}
        >
          {vendor?.vendorName}
        </button>
      ) : (
        <button
          type="button"
          className=" text-xs sm:text-sm md:text-base mr-2 mb-2 px-4 py-2 border border-black font-sans text-black rounded-lg "
          key={i}
          onClick={() => {
            setActive(vendor?.vendorId)
            setActiveAccount(vendor?.accountId)
            refresh(true)
          }}
        >
          {vendor?.vendorName}
        </button>
      )}
    </>
  )
}

export default MyVendorComponent
