import MyVendorComponent from "../../ProductionPlan/Components/ProductPlanner/MyVendorComponent"

const VendorComponent = ({ getMyVendor, activeVendor, setActiveVendor, setActiveAccount, refresh }) => {
  return (
    <>
      <div className=" flex flex-row flex-wrap">
        {getMyVendor?.data?.map((vendor, i) => (
          <div key={i}>
            {activeVendor === vendor.vendorId ? (
              <MyVendorComponent
                vendor={vendor}
                i={i}
                setActive={setActiveVendor}
                setActiveAccount={setActiveAccount}
                active={true}
                refresh={refresh}
              />
            ) : (
              <MyVendorComponent
                vendor={vendor}
                i={i}
                setActive={setActiveVendor}
                setActiveAccount={setActiveAccount}
                active={false}
                refresh={refresh}
              />
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default VendorComponent
